<template>
  <div>
    <h2 class="track_title" :class="{ s_active: get(getTrackById, 'active') }">
      <span v-if="get(getTrackById, 'active')">Actief:</span>
      {{ title }}
      <span v-if="formattedReadingLevels">({{ formattedReadingLevels }})</span>
      <tag v-if="get(getTrackById, 'active') === false" color="#aeaeae">
        Inactief
      </tag>
    </h2>
    <!-- <router-link
          class="link"
          :to="{ name: 'ResourceManagementStudentsStudentPaths' }"
        >
          <sb-icon
            icon-id="icon_arrow-left"
            style="transform: translateY(3px)"
          />
          Terug naar overzicht leerling
        </router-link> -->
    <router-view
      :extra-depth="true"
      :data="null"
      type="traject"
      @refetch="refetch"
    />
  </div>
</template>

<script>
import GetUserById from '@/graphql/queries/GetUserById.gql';
import gql from 'graphql-tag';

export default {
  components: {},

  props: {
    path: {
      type: String,
      default: null,
    },
  },

  provide() {
    const self = this;
    return {
      student: () => self.student,
    };
  },

  computed: {
    student() {
      return this.getUserById;
    },

    title() {
      return [
        // this.student?.fullName,
        this.getTrackById
          ? ['Traject', this.getTrackById.readingLevel]
              .filter(Boolean)
              .join(' - ')
          : undefined,
      ]
        .filter(Boolean)
        .join(' - ');
    },

    formattedReadingLevels() {
      return this.getTrackById?.readingLevels?.join(', ');
    },
  },

  watch: {
    title(newVal) {
      this.$emit('header-data', newVal);
    },
  },

  mounted() {
    this.overwriteNavButtonsActiveState();
  },

  updated() {
    this.overwriteNavButtonsActiveState();
  },

  beforeRouteLeave(_, __, next) {
    this.overwriteNavButtonsActiveState(true);
    next();
  },

  methods: {
    async overwriteNavButtonsActiveState(leave = false) {
      await this.$nextTick();
      const navQuery = '.sb_menu-tabs.v_menu-type-default';
      const navContainer = document.querySelector(navQuery);
      const navButtons = navContainer?.getElementsByTagName('button');

      if (!navButtons?.length) return;

      if (leave || this.getTrackById?.active === false) {
        return navButtons[0]?.classList.remove('s_active');
      }

      navButtons[0]?.classList.add('s_active');
    },

    refetch() {
      console.log('refetch');
    },
  },

  apollo: {
    getUserById: {
      query: GetUserById,
      variables() {
        return { id: this.$route.params.studentId };
      },
    },

    getTrackById: {
      query() {
        return gql`query GetTrackById {
          getTrackById(id: "${this.$route.params.path}") {
            id
            readingLevels
            active
          }
        }`;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.track_title {
  margin: 0px;
  padding: 1rem 2rem;
  font-size: 1.3rem;
}
.s_active {
  // margin: 8px 0px;
  color: $brand-env-coach;
  background: lighten($brand-env-coach, 30%);
  border-bottom: 1px solid #ccc;
  // border-radius: 4px;
}

.icon-arrow-left {
  transform: translateY(2px);
}

.slash {
  transform: translateY(1px);
  display: inline-block;
  font-size: 20px;
  color: $brand-gray;
  margin: 0 12px;
}
</style>
