<template>
  <div class="sb_journal-entry-content-list">
    <ul v-if="getContent()" class="sb_journal-entry-content-list_list">
      <li
        v-for="(item, index) in getContent()"
        :key="index"
        class="sb_journal-content-list_item"
      >
        <router-link v-if="item.to" :to="item.to">
          {{ item.text }}
        </router-link>

        <span v-else class="text" v-html="item.text" />

        <ul v-if="item.items" class="inner-list">
          <li
            v-for="(childListItem, childIndex) in item.items"
            :key="childIndex"
          >
            <router-link v-if="childListItem.to" :to="childListItem.to">
              {{ childListItem.text }}
            </router-link>
            <span v-else class="text">{{ childListItem.text }}</span>
          </li>
        </ul>
      </li>
    </ul>
    <!-- <footer>
      {{ get(journalEntry, 'createdBy', 'fullName') }}
    </footer> -->

    <div
      v-if="journalEntry.comments.length"
      class="comments-button"
      @click="$emit('comments-click', journalEntry.id)"
    >
      {{
        getCommentPreview(
          journalEntry.comments[0],
          journalEntry.comments.length,
        )
      }}
    </div>
  </div>
</template>

<script>
import { journalEventMixin } from '@/lib/journal-event';
import { zelfredzaamheidMixin } from '@/lib/zelfredzaamheid';
import { aviCardLevelMixin } from '@/lib/avi-card-level';
import { dmtCardTypeMixin } from '@/lib/dmt-card-type';
import { cognitiveMapColumnMixin } from '@/lib/cognitive-map-column';
import gql from 'graphql-tag';
import Vue from 'vue';

export default {
  name: 'SbJournalContentList',
  mixins: [
    journalEventMixin,
    zelfredzaamheidMixin,
    aviCardLevelMixin,
    dmtCardTypeMixin,
    cognitiveMapColumnMixin,
  ],

  props: {
    journalEntry: { type: Object, required: true },
  },

  data: () => ({
    resources: {},
  }),

  watch: {
    resources(value) {},

    journalEntry: {
      immediate: true,
      deep: true,
      handler({ event, changes }) {
        if (event === 'TRACK_PROBLEM_FOCUS' && !!changes.create?.length) {
          changes.create.map((entry) => {
            this.$apollo
              .query({
                query: gql`query SbJournalContentList_problem {
                  getProblemById(id: "${entry.problemId}") {
                    id
                    title
                  }
                }`,
              })
              .then(({ data: { getProblemById } }) => {
                if (!getProblemById) return;
                this[
                  this.getProblemQueryKey(getProblemById.id)
                ] = Vue.observable(getProblemById);
                this.$forceUpdate();
              });
          });
        }
      },
    },
  },

  mounted() {
    requestAnimationFrame(() => {
      const anchors = this.$el.querySelectorAll('a');
      anchors.forEach((anchor) => anchor.setAttribute('target', '_blank'));
    });
  },

  methods: {
    getCommentPreview(comment, commentsLength) {
      if (!comment) return;
      const { text } = comment;

      const commentText = commentsLength > 1 ? 'opmerkingen' : 'opmerking';
      return `(${commentsLength} ${commentText}) ${text}`;
    },

    getResourceData(name, id, queryOptions, resolve, callback) {
      console.warn(
        'Maybe the respective journal entry `changes` should be extended. Are you sure you want to use this method? ',
      );

      const key = [name, id].join(':');

      this.$apollo.query(queryOptions).then((result) => {
        const data = resolve(result);
        if (data) {
          const doForceUpdate = !this.resources[key];
          this.resources[key] = Vue.observable(data);
          if (doForceUpdate) this.$forceUpdate();
        }
      });

      return callback?.(this.resources[key]);
    },

    getContent() {
      const { event, changes } = this.journalEntry;

      switch (event) {
        case this.JournalEvent.SESSION_START:
          return createContentList(changes, (changes) => [
            { text: `Taak: ${changes.problem?.title ?? 'onbekend'}` },
            changes.technique
              ? {
                  text: `Techniek: ${changes.technique.number} ${changes.technique.title}`,
                }
              : undefined,
          ]);

        case this.JournalEvent.SESSION_APPLIED_TECHNIQUE:
          return createContentList(changes, (changes) => [
            changes.appliedTechnique
              ? {
                  text: `${changes.appliedTechnique.number} ${changes.appliedTechnique.title}`,
                }
              : undefined,
          ]);

        case this.JournalEvent.SESSION_APPLIED_TYPE:
          return createContentList(changes, (changes) => [
            { text: changes.appliedType },
          ]);

        case this.JournalEvent.COGNITION_MAP_ENTRY_ADDED:
        case this.JournalEvent.COGNITION_MAP_ENTRY_UPDATED:
        case this.JournalEvent.SESSION_COGNITION_MAP_ENTRY_ADDED:
        case this.JournalEvent.SESSION_COGNITION_MAP_ENTRY_UPDATED:
          if (!changes.column) return false;
          return createContentList(changes, (changes) => [
            {
              text: [
                this.getCognitiveMapColumnLabel(changes.column),
                `"${changes.text}"`,
              ].join(': '),
            },
          ]);

        case this.JournalEvent.CARD_EXERCISE_START:
          return createContentList(changes, (changes) => [
            {
              text: [
                'Kaart',
                [changes.card?.number, changes.card?.title]
                  .filter(Boolean)
                  .join(' - ') || 'Kaart onbekend',
              ].join(': '),
            },
            { text: ['Niveau', changes.readingLevel].join(': ') },
          ]);

        case this.JournalEvent.CARD_EXERCISE_REMARK:
          return createContentList(changes, (changes) => [
            {
              text: changes.remark ? `"${changes.remark}"` : 'Geen opmerkingen',
            },
          ]);

        case this.JournalEvent.CARD_EXERCISE_READING_COMPREHENSION_ANSWERS:
          return createContentList(changes, (changes) => {
            return (
              changes.upsert.map((e) => ({
                meta: { liStyle: { display: 'flex' } },
                text: `<div style="white-space: normal; word-break: normal;">${[
                  e.question,
                  `<p class="row-2"><i>~ "${e.answer}"</i></p>`,
                ].join('\n')}</div>`,
              })) ?? [{ text: 'nvt' }]
            );
          });

        case this.JournalEvent.CARD_EXERCISE_READING_LEVEL:
          return createContentList(changes, (changes) => [
            { text: `Niveau ${changes.readingLevel}` },
          ]);

        case this.JournalEvent.SESSION_END:
          return createContentList(changes, (changes) => [
            {
              items: [{ text: `"${changes.howDidItGo?.trim() ?? '-'}"` }],
              text: 'Hoe ging het?',
            },
            {
              items: [{ text: `"${changes.whatWentWell?.trim() ?? '-'}"` }],
              text: 'Wat ging er goed?',
            },
            {
              items: [{ text: `"${changes.whatWentWrong?.trim() ?? '-'}"` }],
              text: 'Wat ging er minder goed?',
            },
            {
              items: [
                { text: `"${changes.whatNeedsAttention?.trim() ?? '-'}"` },
              ],
              text: 'Waar moet je de volgende keer extra aan denken?',
            },
          ]);

        case this.JournalEvent.TRACK_INTAKE_CREATED:
          return createContentList(changes, (changes) => [
            { text: `Afnemer: ${changes.testSupervisor}` },
          ]);

        case this.JournalEvent.TRACK_AVI_RESULT:
          return createContentList(changes, (changes) =>
            changes.create.map((result) => ({
              text: this.getAviCardLevelLabel(result.level),
              items: [
                { text: `Aantal fout: ${result.mistakesMade}` },
                { text: `Norm fout: ${result.mistakesNorm}` },
                result.number && { text: `Variant: ${result.number}` },
              ].filter(Boolean),
            })),
          );

        case this.JournalEvent.TRACK_DMT_RESULT:
          return createContentList(changes, (changes) =>
            changes.create.map((result) => ({
              text: `${this.getDmtCardTypeLabel(result.type)} - ${
                result.amountOfWords
              } gelezen, ${result.amountOfErrors} fout`,
            })),
          );

        case this.JournalEvent.TRACK_PROBLEM_FOCUS:
          const createContent = (result, deleted = false) => {
            const title = result.problemTitle ?? 'Generiek probleem';
            const text = [
              `${deleted ? 'Verwijderd: ' : ''}${title}`,
              deleted ? '' : `${result.mistakeAmount ?? 0} fouten`,
            ]
              .filter(Boolean)
              .join(', ');
            return { text };
          };

          return createContentList(changes, (changes) =>
            changes.problems.create
              .map((e, index) => ({
                ...e,
                problemTitle: changes.problemTitles?.[index],
              }))
              .map((e) => createContent(e))
              .sort((a, b) => a.order - b.order)
              .concat(
                changes.problems.deleteMany
                  ?.map((e, index) => ({
                    ...e,
                    problemTitle: changes.problemTitles?.[index],
                  }))
                  .map((e) => createContent(e, true)),
              )
              .filter(Boolean),
          );

        case this.JournalEvent.TRACK_CREATED:
        case this.JournalEvent.SESSION_PROBLEM_FOCUS:
        case this.JournalEvent.CARD_EXERCISE_END:
        case this.JournalEvent.TRACK_INTAKE_CREATED:
        case this.JournalEvent.TRACK_MISC_RESULT:
        case this.JournalEvent.TRACK_READING_LEVEL:
        case this.JournalEvent.TRACK_REMOVED:
        case this.JournalEvent.COACH_COMMENT:
          return false;
        default:
          return false;
      }
    },
    getProblemQueryKey(problemId) {
      return `problem_${problemId}`;
    },
  },
};

function createContentList(changes, creator, evaluate = true, debugLabel) {
  try {
    return Boolean(evaluate) ? creator(changes).filter(Boolean) : false;
  } catch (error) {
    console.warn({ debugLabel, changes, creator, evaluate });
    console.warn('Could not create content list:', error);
    return false;
  }
}
</script>

<style lang="scss" scoped>
.sb_journal-entry-content-list {
  footer {
    font-size: 0.9rem;
    margin-top: 0.6rem;
  }
}

.sb_journal-content-list_item {
  color: $brand-black;
  margin-left: 10px;
}

.text {
  white-space: pre-line;
  word-break: break-word;
}

.inner-list {
  margin-left: 10px;
}

.comments-button {
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical;
  cursor: pointer;
  &:hover {
    color: $brand-black;
  }
}
</style>
