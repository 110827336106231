<template>
  <sb-gql-table :config="tableConfig" :query-options="queryOptions" data-path="sessions" @change="sessions = $event"
    @cell-click-created-at="goToSessionData">
    <template v-if="withStartButton" slot="header-area">
      <sb-start-session-button :track-id="$route.params.path" />
    </template>
    <template slot="column-finished-at" slot-scope="{ content, row }">
      <template v-if="content"> {{ content }}</template>
      <div v-else class="table-button-container">
        <button @click="continueSession(row.id)">
          <sb-button size="small"> Verder gaan </sb-button>
        </button>
      </div>
    </template>
  </sb-gql-table>
</template>

<script>
import SbGqlTable from '@/components/SbTable2/SbGqlTable.vue';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import gql from 'graphql-tag';
import SbStartSessionButton from '@/components/SbStartSessionButton.vue';

export default {
  name: 'SbSessionsTable',
  components: { SbGqlTable, SbStartSessionButton },

  mixins: [
    nonReactiveMembersMixin(() => ({
      tableConfig: new TableConfig({
        columns: [
          {
            key: 'createdAt',
            header: 'Gestart',
            sortable: true,
            sort: 'descending',
            action: true,
            data: (row) => {
              const date = new Date(row.createdAt);
              return [
                date.toLocaleDateString(),
                date.toLocaleTimeString(),
              ].join(' ');
            },
          },
          {
            key: 'createdBy',
            header: 'Gestart door',
            width: 200,
            data: (row) => row.createdBy?.fullName ?? '-',
          },
          {
            key: 'problemFocus',
            header: 'Problematiek-focus',
            data: (row) => row.problemFocus?.title,
          },
          {
            key: 'cards',
            header: 'Kaart(en)',
            width: 200,
            data: (row) =>
              row.cards
                ?.map(({ card, cardId }) => card?.title || 'n.v.t.')
                .join(', '),
          },
          {
            key: 'finishedAt',
            header: 'Voltooid',
            data: (row) => {
              if (!row.finishedAt) return;
              const date = new Date(row.finishedAt);
              return [
                date.toLocaleDateString(),
                date.toLocaleTimeString(),
              ].join(' ');
            },
          },
          {
            key: 'finishedBy',
            header: 'Voltooid door',
            width: 200,
            data: (row) => row.finishedBy?.fullName ?? '-',
          }
        ],
      }),
    })),
  ],

  props: { withStartButton: { type: Boolean, default: false } },

  data: () => ({ sessions: undefined }),

  methods: {
    goToSessionData({ id: sessionId }) {
      this.$router.push({
        name: 'ResourceManagementStudentsStudentPathTrainingsTraining',
        params: { sessionId },
      });
    },

    startSession() {
      this.$router.push({
        name: 'Session',
        query: { studentIds: this.$route.params.studentId },
      });
    },

    continueSession(id) {
      const session = this.sessions?.find((session) => session.id === id);
      const exerciseIds = session?.cards.map((exercise) => exercise.id);

      if (!session || !!session.finishedAt) return;

      const { problemFocus, cards, appliedType } = session;
      const unfinishedCard = cards.find((card) => !card?.finishedAt);
      const query = {
        step: 0,
        studentIds: this.$route.params.studentId,
        sessionIds: session?.id,
        exerciseIds: exerciseIds.join(','),
        trackIds: this.$route.params.path,
      };

      if (problemFocus) {
        query.step = 1;
        query.problemIds = problemFocus.id;

        if (!cards.length) {
          query.step = 1;
        } else if (unfinishedCard) {
          query.step = 2;
          query.cardId = unfinishedCard.cardId;
        } else {
          query.step = 4;

          if (appliedType) {
            query.step = 5;
          }
        }
      }

      this.$router.push({
        name: 'Session',
        query,
      });
    },

    queryOptions({
      first,
      last,
      after,
      before,
      filter,
      filters,
      orderBy,
      pagination,
    }) {
      return {
        query: gql`
          query Sessions(
            $first: Int
            $last: Int
            $after: String
            $before: String
            $filter: SessionsFilter
            $orderBy: SessionsOrderBy
          ) {
            sessions(
              first: $first
              last: $last
              after: $after
              before: $before
              filter: $filter
              orderBy: $orderBy
            ) {
              ${pagination}
              edges {
                node {
                  id
                  createdAt
                  finishedBy {
                    id
                    fullName
                  }
                  createdBy {
                    id
                    fullName
                  }
                  finishedAt
                  appliedType
                  problemFocus {
                    id
                    title
                  }
                  cards {
                    id
                    cardId
                    createdAt
                    remark
                    finishedAt
                    card {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          first,
          last,
          after,
          before,
          filter: {
            ...filter,
            trackId: { equals: this.$route.params.path },
          },
          orderBy,
        },
      };
    },
  },
};
</script>
