<template>
  <div class="sb_journal_block-container">
    <div
      class="sb_journal_block"
      :class="{ 's_is-open': toggled, 's_is-toggleable': true }"
      :type="type"
    >
      <sb-journal-comments-modal
        v-model="showComments"
        :journal-entry-id="commentsModalJournalId"
        @comment-created="() => $emit('on-comment-created')"
      />

      <button
        class="sb_journal_block_header"
        :style="{
          gridTemplateColumns: showTimestamps
            ? 'auto auto 1fr auto auto 20px'
            : 'auto 1fr auto auto 20px',
        }"
        @click="toggleSession"
      >
        <div class="sb_journal_block_header_date">
          {{ timestamp }}
        </div>
        <div v-if="showTimestamps" class="sb_journal_block_header_time">
          {{ blockTime }}
        </div>
        <div class="sb_journal_block_header_title">
          {{ title }}
        </div>

        <sb-icon-badge icon-id="icon_user" :label="creator" />
        <sb-icon-badge
          icon-id="icon_clock"
          :label="Format.minutesToHourMinutes(group.categoryGroupTime)"
        />

        <div class="sb_journal_block_header_caret">
          <sb-icon icon-id="icon_caret-down" />
        </div>
      </button>

      <div v-if="sortedItems.length" class="sb_journal_block_items">
        <div
          v-for="item in sortedItems"
          :key="`${item.id}`"
          class="sb_journal_block_item"
          :style="{
            gridTemplateColumns: showTimestamps ? '80px 1fr' : '1fr',
          }"
          :type="getItemType(item)"
        >
          <div v-if="showTimestamps" class="sb_journal_block_item_time">
            {{ getTime(item.createdAt) }}
          </div>

          <div class="sb_journal_block_item_content">
            <div class="sb_journal_block_item_content_inner">
              <div class="sb_journal_block_item_content_title">
                <!-- only link items for coaches -->
                <template v-if="$user.role === 'COACH'">
                  <span
                    :class="{ link: getItemLocation(item) }"
                    @click="
                      getItemLocation(item)
                        ? $router.push(getItemLocation(item))
                        : undefined
                    "
                  >
                    {{ getItemTitle(item) }}
                  </span>
                </template>
                <template v-else>
                  <span>
                    {{ getItemTitle(item) }}
                  </span>
                </template>
              </div>
              <sb-journal-content-list
                :journal-entry="item"
                @comments-click="showCommentsForId($event)"
              />
            </div>

            <div class="sb_journal_block_item_comment-button-container">
              <div @click="showCommentsForId(item.id)">
                <sb-icon icon-id="icon_messages" scale=".8" />
              </div>
              <div class="text-red" @click="confirmDeleteItem(item.id)">
                <sb-icon icon-id="icon_bin" scale=".8" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { journalEventMixin } from '@/lib/journal-event';
import SbJournalContentList from './SbJournalContentList.vue';
import SbIcon from '@/components/global/SbIcon.vue';
import SbIconBadge from './SbIconBadge.vue';
import SbJournalCommentsModal from '@/components/SbJournalCommentsModal.vue';
import MutationDeleteJournalEntry from '@/graphql/mutations/DeleteJournalEntry.gql';
import { Format } from '@/lib/utils/format';

export default {
  components: {
    SbJournalContentList,
    SbIcon,
    SbJournalCommentsModal,
    SbIconBadge,
  },
  mixins: [journalEventMixin],

  props: {
    group: { type: Object, required: true },
    type: { type: String, required: true },
    timestamp: { type: String, required: true },
    defaultToggled: { type: Boolean, default: true },
    trackData: { type: Object, default: undefined },
    showTimestamps: { type: Boolean, default: true },
  },

  data() {
    return {
      toggled: this.defaultToggled,
      showComments: false,
      commentsModalJournalId: undefined,
      Format,
    };
  },

  computed: {
    items() {
      return this.group.entries;
    },
    processedItems() {
      // squish consequent reading comprehension answers to the same question
      const qaMap = new Map();

      const result = this.items.reduce((acc, item, index) => {
        const isReadingComprehensionAnswers =
          item.event ===
          this.JournalEvent.CARD_EXERCISE_READING_COMPREHENSION_ANSWERS;

        if (!isReadingComprehensionAnswers) {
          acc.push(item);
          return acc;
        }

        const qaItem = qaMap.has(item.targetResourceId)
          ? qaMap.get(item.targetResourceId)
          : qaMap.set(item.targetResourceId, item).get(item.targetResourceId);

        if (item.createdAt > qaItem.createdAt) {
          qaMap.set(item.targetResourceId, item);
        }

        return acc;
      }, []);

      return result.concat(Array.from(qaMap.values()));
    },

    sortedItems() {
      return this.processedItems
        ?.slice()
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    },

    blockTime() {
      const createdAt = this.items[0]?.createdAt;
      return createdAt ? this.getTime(createdAt) : '';
    },

    title() {
      switch (this.type) {
        case 'session':
          return 'Sessie';

        case 'diagnosis':
          return 'Diagnose';

        case 'cognition':
          return 'Cognitie';

        default:
          throw new Error(`Unknown type ${this.type}`);
      }
    },

    creator() {
      return this.items?.[0]?.createdBy?.fullName;
    },
  },

  methods: {
    getItemTitle(item) {
      return this.getJournalEventLabel(item.event);
    },

    getItemLocation({ trackId, event, targetResourceId }) {
      switch (event) {
        case this.JournalEvent.TRACK_CREATED:
          return {
            name: 'ResourceManagementStudentsStudentPathOverview',
            params: { ...this.$route.params, path: trackId },
          };

        case this.JournalEvent.TRACK_DMT_RESULT:
        case this.JournalEvent.TRACK_AVI_RESULT:
        case this.JournalEvent.TRACK_MISC_RESULT:
          return {
            name: 'ResourceManagementStudentsStudentPathTestResults',
            params: { ...this.$route.params, path: trackId },
            query: { active: targetResourceId },
          };

        case this.JournalEvent.TRACK_PROBLEM_FOCUS:
          return {
            name: 'ResourceManagementStudentsStudentPathProblemTypes',
            params: { ...this.$route.params, path: trackId },
          };

        case this.JournalEvent.COGNITION_MAP_ENTRY_ADDED:
        case this.JournalEvent.COGNITION_MAP_ENTRY_UPDATED:
        case this.JournalEvent.SESSION_COGNITION_MAP_ENTRY_ADDED:
        case this.JournalEvent.SESSION_COGNITION_MAP_ENTRY_UPDATED:
          return {
            name: 'ResourceManagementStudentsStudentPathCognition',
            params: { ...this.$route.params, path: trackId },
          };

        default:
          return undefined;
      }
    },

    getItemContentRenderMode(item) {
      const hasContent =
        ['COGNITION_ENTRY_ADDED', 'TRACK_INTAKE_CREATED'].includes(
          item.event,
        ) && Object.values(item.changes).length > 0;

      return hasContent;
    },

    getItemType({ event }) {
      switch (event) {
        case this.JournalEvent.SESSION_APPLIED_TYPE:
          return 'apply';

        case this.JournalEvent.SESSION_END:
        case this.JournalEvent.SESSION_START:
          return 'session';

        case this.JournalEvent.SESSION_PROBLEM_FOCUS:
        case this.JournalEvent.TRACK_DMT_RESULT:
        case this.JournalEvent.TRACK_AVI_RESULT:
        case this.JournalEvent.TRACK_CREATED:
        case this.JournalEvent.TRACK_INTAKE_CREATED:
        case this.JournalEvent.TRACK_MISC_RESULT:
        case this.JournalEvent.TRACK_PROBLEM_FOCUS:
        case this.JournalEvent.TRACK_READING_LEVEL:
        case this.JournalEvent.TRACK_REMOVED:
        case this.JournalEvent.TRACK_FINISHED:
          return 'diagnose';

        case this.JournalEvent.CARD_EXERCISE_END:
        case this.JournalEvent.CARD_EXERCISE_READING_COMPREHENSION_ANSWERS:
        case this.JournalEvent.CARD_EXERCISE_READING_LEVEL:
        case this.JournalEvent.CARD_EXERCISE_REMARK:
        case this.JournalEvent.CARD_EXERCISE_START:
          return 'practise';

        case this.JournalEvent.COGNITION_MAP_ENTRY_ADDED:
        case this.JournalEvent.COGNITION_MAP_ENTRY_UPDATED:
        case this.JournalEvent.SESSION_COGNITION_MAP_ENTRY_ADDED:
        case this.JournalEvent.SESSION_COGNITION_MAP_ENTRY_UPDATED:
          return 'cognition';

        case this.JournalEvent.COACH_COMMENT:
        case this.JournalEvent.SESSION_APPLIED_TECHNIQUE:
          return 'record';

        default:
          throw new Error(`Unknown type ${event}`);
      }
    },

    getTime(date) {
      return new Date(date)
        .toLocaleTimeString()
        .split(':')
        .slice(0, 2)
        .join(':');
    },

    toggleSession() {
      this.toggled = !this.toggled;
    },

    showCommentsForId(id) {
      this.commentsModalJournalId = id;
      this.showComments = true;
    },

    confirmDeleteItem(itemId) {
      this.$Modal.confirm({
        title: 'Weet je het zeker?',
        content: `Weet je zeker dat je dit journaal item wil verwijderen? Dit kan niet ongedaan gemaakt worden.`,
        onOk: async () => {
          const { data } = await this.$apollo.mutate({
            mutation: MutationDeleteJournalEntry,
            variables: {
              id: itemId,
            },
          });

          const success = !!data?.deleteJournalEntry;
          if (!success) {
            this.$Message.error({
              content: `Er is iets misgegaan bij het verwijderen van het item`,
            });
            return;
          } else {
            this.$Message.info({
              content: `Item is succesvol verwijderd`,
            });
            this.$emit('on-delete-item', itemId);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_journal_block-container {
  @media print {
    display: table-row;
    break-inside: avoid;
  }
}

.sb_journal_block {
  position: relative;
  margin: 0 0 0.6rem 0;
  background: $brand-lightest-gray;
  border-radius: 18px;

  &.s_is-toggleable {
    //
    // FAUX DOT
    //
    &::before {
      content: '';
      position: absolute;
      z-index: 3;
      top: 1.35rem;
      left: -2.25rem;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: currentColor;
    }
    //
    // FAUX LINE
    //
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 1.6rem;
      left: -2rem;
      width: 2rem;
      height: 2px;
      background: $brand-light-gray;
    }
  }

  //
  //  HEADER
  //
  &_header {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto 1fr 100px;
    gap: 10px;
    border-radius: 18px;
    padding: 1rem;
    user-select: none;
    font-weight: $semibold;
    font-size: 1.2rem;

    //
    //  HEADER - TIME
    //
    &_time {
      opacity: 0.5;
    }
    //
    //  HEADER - TITLE
    //
    &_title {
      padding-left: 30px;
      color: $brand-gray;
    }
    //
    //  HEADER - CARET
    //
    &_caret {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 1.6rem;

      @media print {
        display: none;
      }

      svg {
        transform-origin: center;
        transition: transform 0.3s ease;
      }
    }
  }

  //
  //  ITEMS
  //
  &_items {
    padding: 0;
    display: none;
  }

  //
  //  ITEM
  //
  &_item {
    display: grid;
    grid-template-columns: 80px 1fr;
    margin: 0 0 0.3rem 0;

    @media print {
      page-break-inside: avoid;
    }

    &_comment-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      padding: 0.5rem;
      color: $brand-primary;
      cursor: pointer;
      opacity: 0;
      transition: 0.3s opacity;

      #icon_messages {
        margin-right: 0.5rem;
      }

      #icon_cross {
        color: $brand-black;
        margin-left: 0.5rem;
      }
    }

    //  ITEM - TIME
    &_time {
      padding: 0.5rem 0 0 0;
      font-size: 1.2rem;
    }

    //  ITEM - CONTENT
    &_content {
      border-left: solid 5px currentColor;
      background: $brand-white;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      display: grid;
      grid-template-columns: 1fr auto;
      padding-left: 1rem;

      @media (hover: none) {
        .sb_journal_block_item_comment-button-container {
          opacity: 1;
          transition-duration: 0s;
        }
      }

      &:hover {
        .sb_journal_block_item_comment-button-container {
          opacity: 1;
          transition-duration: 0s;
        }
      }

      &_inner {
        padding-bottom: 0.6rem;
      }

      //  ITEM - CONTENT - TITLE
      &_title {
        font-weight: $semibold;
        padding: 0.6rem 0;
        color: $brand-black;

        .link {
          color: $brand-primary;
          cursor: pointer;
          transition: 0.3s color;

          &:hover {
            color: $brand-black;
            transition-duration: 0s;
          }
        }
      }

      ul {
        margin: 0 0 0 1rem;
        list-style: none;
      }
      > ul {
        margin: 0 0 1rem 1rem;
      }
      li {
        &::before {
          content: '\2022';
          color: $brand-black;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
    //
    // ITEM TASK COLORS
    //
    &[type='session'] {
      color: $brand-primary;
      li {
        &::before {
          color: $brand-black;
        }
      }
    }
    &[type='diagnose'] {
      color: $brand-task-diagnose;
      li {
        &::before {
          color: $brand-task-diagnose;
        }
      }
    }
    &[type='cognition'] {
      color: $brand-task-cognition;
      li {
        &::before {
          color: $brand-task-cognition;
        }
      }
    }
    &[type='practise'] {
      color: $brand-task-practise;
      li {
        &::before {
          color: $brand-task-practise;
        }
      }
    }
    &[type='apply'] {
      color: $brand-task-apply;
      li {
        &::before {
          color: $brand-task-apply;
        }
      }
    }
    &[type='record'] {
      color: $brand-task-record;
      li {
        &::before {
          color: $brand-task-record;
        }
      }
    }
  }

  //
  //  SESSION TASK COLORS
  //
  &[type='session'] {
    color: $brand-dark-gray;
    background: $brand-lightest-gray;
    button.sb_journal_block_header {
      color: $brand-dark-gray;
      background: $brand-lightest-gray;
      &:hover {
        background: darken($brand-lightest-gray, 5%);
      }
    }
  }
  &[type='diagnose'] {
    color: $brand-task-diagnose;
    background: rgba($brand-task-diagnose, 0.06);

    button.sb_journal_block_header {
      color: $brand-task-diagnose;
      &:hover {
        background: rgba($brand-task-diagnose, 0.08);
      }
    }
  }
  &[type='cognition'] {
    color: $brand-task-cognition;
    background: rgba($brand-task-cognition, 0.06);
    button.sb_journal_block_header {
      color: $brand-task-cognition;
      &:hover {
        background: rgba($brand-task-cognition, 0.08);
      }
    }
  }
  &[type='practise'] {
    color: $brand-task-practise;
    background: rgba($brand-task-practise, 0.06);
    button.sb_journal_block_header {
      color: $brand-task-practise;
      &:hover {
        background: rgba($brand-task-practise, 0.08);
      }
    }
  }
  &[type='apply'] {
    color: $brand-task-apply;
    background: rgba($brand-task-apply, 0.06);
    button.sb_journal_block_header {
      color: $brand-task-apply;
      &:hover {
        background: rgba($brand-task-apply, 0.08);
      }
    }
  }
  &[type='record'] {
    color: $brand-task-record;
    background: rgba($brand-task-record, 0.06);
    button.sb_journal_block_header {
      color: $brand-task-record;
      &:hover {
        background: rgba($brand-task-record, 0.08);
      }
    }
  }

  //
  // STATE - IS OPEN
  //
  &.s_is-open {
    .sb_journal_block_items {
      display: block;
      padding: 0 1rem 1rem 1rem;
    }
    .sb_journal_block_header_caret {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
