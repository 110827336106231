<template>
  <div class="container">
    <sb-journal-filters v-model="journalFilters" @print="handlePrint" />

    <sb-call-out
      v-if="
        studentTrackIds &&
        !studentTrackIds.length &&
        !$apollo.queries.studentTrackIds.loading &&
        !$apollo.queries.trackJournalLookup.loading
      "
    >
      <template slot="left"> ℹ️ </template>
      <template slot="right">
        In de geselecteerde periode is er geen journaal om weer te geven.
      </template>
    </sb-call-out>

    <p class="from-to">
      Studentjournaal <b>{{ get(student(), 'fullName') }}</b> van
      {{ journalFilters.fromDate.toLocaleDateString() }} tot
      {{ journalFilters.toDate.toLocaleDateString() }}
    </p>

    <tabs
      v-if="activeTrackIds.length || passiveTrackIds.length"
      v-model="tab"
      :animated="false"
      class="journal-tabs"
    >
      <tab-pane label="Begeleiding" name="track-dependent">
        <template v-for="(ids, index) of [activeTrackIds, passiveTrackIds]">
          <div
            v-if="index === 0 && activeTrackIds.length"
            :key="index"
            class="active-indicator"
          >
            Actief traject
          </div>

          <div
            v-if="index === 1 && passiveTrackIds.length"
            :key="index"
            class="active-indicator"
          >
            Non-actieve trajecten
          </div>

          <div v-for="id of ids" :key="id" class="track-journal-block">
            <sb-divider
              v-if="printMode ? !!journalByTrackId(id) : true"
              class="block"
              top-space="10px"
              top-padding="20px"
              :title="
                [
                  'Traject',
                  !!trackLookup[id].readingLevel &&
                    `- ${trackLookup[id].readingLevel}`,
                  !!trackLookup[id].startDate &&
                    journalFilters.doShowTimestamps &&
                    `- startdatum ${new Date(
                      trackLookup[id].startDate,
                    ).toLocaleDateString()}`,
                ]
                  .filter(Boolean)
                  .join(' ')
              "
              @click.native="
                toggledTrackIds.includes(id)
                  ? (toggledTrackIds = [])
                  : (toggledTrackIds = [id]);
                !selectedTrackIds.includes(id) && selectedTrackIds.push(id);
              "
            >
              <template #left>
                <sb-icon
                  class="icon"
                  :icon-id="
                    toggledTrackIds.includes(id)
                      ? 'icon_caret-up'
                      : 'icon_caret-down'
                  "
                />
              </template>

              <template #right>
                <div class="right-container">
                  <sb-icon-badge
                    v-if="trackLookup[id].active"
                    icon-id="icon_clock"
                    :label="Format.minutesToHourMinutes(getTotalTrackTime(id))"
                  />

                  <span
                    v-else
                    class="sb-track-status-table-label"
                    :class="{
                      s_active: trackLookup[id].active,
                      s_inactive: !trackLookup[id].active,
                      s_closed:
                        trackLookup[id].active && !!trackLookup[id].finishedAt,
                    }"
                  >
                    <span v-if="!trackLookup[id].finishedAt">
                      {{ trackLookup[id].active ? 'Actief' : 'Inactief' }}
                    </span>
                    <span v-else> Voltooid </span>
                  </span>
                </div>
              </template>
            </sb-divider>

            <div
              :style="{
                position: 'relative',
                overflow: 'hidden',
                paddingLeft: '5px',
                minHeight:
                  toggledTrackIds.includes(id) &&
                  $apollo.queries.trackJournalLookup.loading
                    ? '100px'
                    : undefined,
              }"
            >
              <template>
                <!-- View switch -->
                <journal-table
                  v-if="journalFilters.doShowSimplified"
                  :items="journalByTrackId(id)"
                />

                <sb-journal-accumulator
                  v-else
                  v-show="toggledTrackIds.includes(id)"
                  :show-timestamps="journalFilters.doShowTimestamps"
                  :data="journalByTrackId(id)"
                  @on-delete-item="refresh"
                  @on-comment-created="refresh"
                />
              </template>

              <sb-loading
                v-if="$apollo.queries.trackJournalLookup.loading"
                position="absolute"
                width="100%"
                height="100%"
                :delay="50"
              />
            </div>
          </div>
        </template>
      </tab-pane>

      <tab-pane label="Zelfstandig geoefend" name="track-independent">
        <div style="padding-left: 5px; position: relative">
          <template>
            <!-- View switch -->
            <journal-table
              v-if="journalFilters.doShowSimplified"
              :items="independentJournal"
            />
            <sb-journal-accumulator
              v-else
              :data="independentJournal"
              @on-delete-item="refresh"
            />
          </template>
        </div>
      </tab-pane>
    </tabs>

    <sb-spacer height="60" />

    <div v-if="printMode" class="print-overlay">
      <i-button
        type="primary"
        size="large"
        :disabled="loadingPrintContent"
        @click="print"
      >
        {{ loadingPrintContent ? 'Voorbereiden...' : 'Journaal afdrukken' }}
      </i-button>
    </div>

    <sb-loading
      v-if="
        $apollo.queries.studentTrackIds.loading ||
        $apollo.queries.trackJournalLookup.loading ||
        $apollo.queries.independentJournal.loading
      "
      width="100%"
      height="100%"
      position="absolute"
    />
  </div>
</template>

<script>
import JournalTable from '@/components/Journal/JournalTable.vue';
import SbCallOut from '@/components/SbCallOut.vue';
import SbDivider from '@/components/SbDivider.vue';
import SbJournalAccumulator from '@/components/SbJournalAccumulator.vue';
import SbJournalFilters from '@/components/SbJournalFilters.vue';
import SbLoading from '@/components/SbLoading.vue';
import SbIconBadge from '@/components/SbIconBadge.vue';
import { ChainDate } from '@/lib/date-chain';
import { GraphQL } from '@/lib/graphql';
import { trackJournalMixin } from '@/mixins/trackJournalMixin';
import gql from 'graphql-tag';
import { Format } from '@/lib/utils/format';

export default {
  name: 'StudentJournal',
  components: {
    SbJournalAccumulator,
    SbDivider,
    SbLoading,
    SbJournalFilters,
    SbCallOut,
    SbIconBadge,
    JournalTable,
  },

  mixins: [trackJournalMixin],

  inject: ['student'],

  data() {
    return {
      tab: 'track-dependent',
      toggledTrackIds: [],
      trackIds: [],
      selectedTrackIds: [],
      activeTrackIds: [],
      passiveTrackIds: [],
      trackLookup: {},
      loadingPrintContent: 'print' in this.$route.query,
      Format,
      journalFilters:
        'print' in this.$route.query
          ? {
              fromDate: new Date(this.$route.query.fromDate),
              toDate: new Date(this.$route.query.toDate),
              doShowTimestamps:
                this.$route.query.doShowTimestamps === 'true' ? true : false,
              doShowSimplified:
                this.$route.query.doShowSimplified === 'true' ? true : false,
              search: this.$route.query.search,
            }
          : {
              // Create range for current year
              fromDate: new ChainDate(new Date()).setMonth(
                new Date().getMonth() - 12,
              ),
              toDate: new ChainDate(new Date()),

              doShowTimestamps: true,
              search: '',
              doShowSimplified: false,
            },
    };
  },

  computed: {
    journalStudentId() {
      return this.$user.role === 'MANAGER'
        ? this.$route.params.studentId
        : undefined;
    },

    printMode() {
      return 'print' in this.$route.query;
    },
  },

  watch: {
    selectedTrackIds(value) {
      this.getJournalByTrackIds(...value);
    },
  },

  created() {
    if (this.printMode) {
      this.autoPrint();
    }
  },

  methods: {
    getDatesForCurrentYear() {
      const year = new Date().getFullYear();
      const fromDate = new Date(year, 0, 1);
      const toDate = new Date(year, 11, 31);
      return { fromDate, toDate };
    },

    handlePrint() {
      window.open(
        this.$router.resolve({
          name: this.$route.name,
          query: {
            print: true,
            fromDate: this.journalFilters.fromDate.toISOString(),
            toDate: this.journalFilters.toDate.toISOString(),
            doShowTimestamps: String(this.journalFilters.doShowTimestamps),
            doShowSimplified: String(this.journalFilters.doShowSimplified),
            search: this.journalFilters.search,
          },
        }).href,
        '_blank',
      );
    },

    autoPrint() {
      const toHide = [
        document.querySelector('.sb_environment-border'),
        document.querySelector('.sb_menu-main'),
        document.querySelector('.sb_app_side-menu'),
        document.querySelector('.sb_page-header'),
        document.querySelector('.sb_menu-tabs'),
        ...Array.from(document.querySelectorAll('.ivu-tabs-bar')),
      ].filter(Boolean);

      toHide.forEach((el) => el.classList.add('hide-when-printing'));

      this.$watch('studentTrackIds', (ids) => (this.selectedTrackIds = ids));

      this.$watch('trackJournalLookup', (trackJournalLookup) => {
        this.loadingPrintContent = false;
        this.toggledTrackIds = Object.keys(trackJournalLookup);
        this.$nextTick(() => this.print());
      });
    },

    print() {
      window.print();
    },

    refresh() {
      this.$apollo.queries.studentTrackIds.refetch();
      this.$apollo.queries.trackJournalLookup.refetch();
      this.$apollo.queries.independentJournal.refetch();
    },
  },

  apollo: {
    independentJournal: {
      fetchPolicy: 'cache-and-network',
      debounce: 250,

      skip() {
        return this.tab !== 'track-independent';
      },

      update(data) {
        const entries = data.journalEntries.edges.map(({ node }) => node);
        const fragments = this.createLogFragments(['_'], () => entries)['_'];
        return fragments;
      },

      variables() {
        return {
          orderBy: { createdAt: 'DESC' },
          filter: {
            search: this.journalFilters.search,
            trackId: { equals: null },
            createdById: { in: [this.$route.params.studentId] },
            createdAt: {
              gte: new ChainDate(this.journalFilters.fromDate)
                .setHours(0)
                .setMinutes(0)
                .setSeconds(0)
                .native.toISOString(),
              lte: new ChainDate(this.journalFilters.toDate)
                .setHours(23)
                .setMinutes(59)
                .setSeconds(59)
                .native.toISOString(),
            },
          },
          first: GraphQL.MAX_SAFE_INTEGER,
        };
      },

      query: gql`
        query StudentJournal_IndependentJournalEntries(
          $orderBy: JournalEntriesOrderBy
          $filter: JournalEntriesFilter
          $first: Int
        ) {
          journalEntries(orderBy: $orderBy, filter: $filter, first: $first) {
            edges {
              node {
                id
                createdById
                createdAt
                event
                trackId
                changes
                comments {
                  totalCount
                }
              }
            }
          }
        }
      `,
    },

    studentTrackIds: {
      skip() {
        return !this.$route.params.studentId;
      },

      variables() {
        return {
          orderBy: { createdAt: 'DESC' },
          filter: {
            createdById: { in: [this.$route.params.studentId] },
            createdAt: {
              gte: new ChainDate(this.journalFilters.fromDate)
                .setHours(0)
                .setMinutes(0)
                .setSeconds(0)
                .native.toISOString(),
              lte: new ChainDate(this.journalFilters.toDate)
                .setHours(23)
                .setMinutes(59)
                .setSeconds(59)
                .native.toISOString(),
            },
          },
          first: GraphQL.MAX_SAFE_INTEGER,
        };
      },

      query() {
        return gql`
          query StudentJournal_studentTrackIds(
            $first: Int
            $filter: JournalEntriesFilter
            $orderBy: JournalEntriesOrderBy
          ) {
            journalEntries(first: $first, filter: $filter, orderBy: $orderBy) {
              edges {
                node {
                  id
                  track {
                    id
                    active
                    readingLevels
                    startDate
                    finishedAt
                  }
                }
              }
            }
          }
        `;
      },

      update(data) {
        const activeTrackIds = [];
        const passiveTrackIds = [];
        this.trackLookup = {};

        const allTrackIds = data.journalEntries.edges
          .map(({ node }) => {
            const track = node.track;

            if (!track) return;

            const trackId = track.id;

            if (!(trackId in this.trackLookup)) {
              this.trackLookup[trackId] = track;
            }

            if (track.active) activeTrackIds.push(trackId);
            if (!track.active) passiveTrackIds.push(trackId);
            return trackId;
          })
          .filter(Boolean);

        const uniqueTrackIds = Array.from(new Set(allTrackIds));

        this.activeTrackIds = Array.from(new Set(activeTrackIds));
        this.passiveTrackIds = Array.from(new Set(passiveTrackIds));
        this.trackIds = uniqueTrackIds.slice();
        this.selectedTrackIds = [this.activeTrackIds[0]];
        this.toggledTrackIds = [this.activeTrackIds[0]];
        return uniqueTrackIds;
      },
    },
  },
};
</script>

<style lang="scss">
.ivu-tabs-bar {
  @media print {
    display: none !important;
  }
}

.hide-when-printing {
  display: none !important;
}

.from-to {
  display: none;
  @media print {
    display: block;
  }
}

.track-journal-block {
  @media print {
    page-break-after: always;
  }

  .icon {
    @media print {
      display: none;
    }
  }
}

/** Comment out this overlay for debugging when you want to see the
actual content */
.print-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.container {
  position: relative;
  @media print {
    @page {
      size: portrait;
    }
  }
}

.options {
  margin: 40px 0;
  padding: 0 40px;
  display: flex;
}

.time-switch {
  margin: 20px 0;
  span {
    margin-right: 10px;
  }
}

.active-indicator {
  font-size: 1.5rem;
  margin: 40px 0 20px 0;

  &:first-child {
    margin-top: 20px;
  }
}

.block {
  transition: 0.3s color;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
    transition-duration: 0s;
  }
}

.icon {
  font-size: 26px;
}

.right-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 10px;
}

.sb-track-status-table-label {
  padding: 4px 10px 2px;
  border-radius: 15px;
  display: inline-block;

  &.s_active {
    color: $brand-green;
    background: rgba($brand-green, 0.1);
  }
  &.s_inactive {
    color: $brand-primary;
    background: rgba($brand-primary, 0.1);
  }
  &.s_closed {
    color: $brand-red;
    background: rgba($brand-red, 0.1);
  }
}
</style>
