<template>
  <div>
    <modal
      v-model="problemInfoModal"
      footer-hide
      :title="get(activeProblemInfo, 'title')"
      :width="800"
      @close="activeProblemInfo = undefined"
    >
      <div v-html="get(activeProblemInfo, 'description')" />
    </modal>

    <sb-loading v-if="$apollo.queries.problems.loading" />

    <div
      v-for="(problems, type, index) in problemsByType"
      :key="type"
      :data-amount="problems.length"
      :style="{
        position: 'relative',
        zIndex: Object.keys(problemsByType).length - index,
        ...(readonly &&
          !getToggledProblemEntriesByCategory(type).length && {
            opacity: 0.4,
          }),
      }"
    >
      <sb-spacer height="40" />
      <sb-list-head style="grid-template-columns: 40px auto 150px 30px 30%">
        <sb-list-head-col style="grid-column: 1 / span 2">
          {{ getProblemTypeLabel(type, true) }}
        </sb-list-head-col>
        <sb-list-head-col style="grid-column: 3 / span 2">
          Aantal fouten
        </sb-list-head-col>
        <sb-list-head-col> Technieken </sb-list-head-col>
      </sb-list-head>

      <template v-for="(problem, problemIndex) in problems">
        <sb-list-row
          v-if="
            !hideDisabled || !!get(value, getProblemEntryIndexById(problem.id))
          "
          :key="problem.id"
          class="sb_problem-row"
          :style="{
            zIndex: problems.length - problemIndex,
            gridTemplateColumns: readonly
              ? '50px auto 150px 30px 30%'
              : '50px 46px auto 150px 30px 30%',
          }"
        >
          <sb-list-row-col
            style="height: 100%; padding: 0"
            @click.native.stop="showProblemInfoModal(problem)"
          >
            <div class="info">
              <sb-icon icon-id="icon_info" />
            </div>
          </sb-list-row-col>

          <sb-list-row-col v-if="!readonly">
            <checkbox
              :value="!!get(value, getProblemEntryIndexById(problem.id))"
              @click.native.stop="!readonly && toggleProblemEntry(problem.id)"
            />
          </sb-list-row-col>

          <sb-list-row-col>
            {{ problem.title }}
          </sb-list-row-col>

          <sb-list-row-col :style="readonly ? undefined : { paddingLeft: 0 }">
            <!-- :readonly="getProblemEntryIndexById(problem.id) < 0" -->
            <input-number
              v-if="!readonly"
              style="width: 100%"
              :min="0"
              :step="1"
              :value="
                get(
                  value,
                  getProblemEntryIndexById(problem.id),
                  'mistakeAmount',
                ) || 0
              "
              @input="handleMistakesAmountInput(problem.id, $event)"
              @click.native.stop
            />

            <template v-else>
              {{
                get(
                  value,
                  getProblemEntryIndexById(problem.id),
                  'mistakeAmount',
                ) || 0
              }}
            </template>
          </sb-list-row-col>

          <sb-list-row-col />

          <sb-list-row-col :style="readonly ? undefined : { paddingLeft: 0 }">
            <sb-problems-input-techniques
              :items="sortLinkedTechniques(problem.linkedTechniques)"
            />
          </sb-list-row-col>
        </sb-list-row>
      </template>
    </div>

    <sb-spacer height="140" />
  </div>
</template>

<script>
import SbListHead from '@/components/SbListHead.vue';
import SbListHeadCol from '@/components/SbListHeadCol.vue';
import SbListRow from '@/components/SbListRow.vue';
import SbListRowCol from '@/components/SbListRowCol.vue';
import SbLoading from '@/components/SbLoading.vue';
import SbProblemsInputTechniques from '@/components/SbProblemsInputTechniques.vue';
import { problemsMixin } from '@/mixins/problemsMixin';
import { groupProblemsByType } from '@/lib/group-problems-by-type';

export default {
  components: {
    SbListHead,
    SbListHeadCol,
    SbListRow,
    SbListRowCol,
    SbLoading,
    SbProblemsInputTechniques,
  },

  mixins: [problemsMixin],

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    hideDisabled: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: undefined,
    },
  },

  data() {
    return {
      techniqueModalData: null,
      showModal: false,
      activeProblemInfo: undefined,
      problemInfoModal: false,
      techniqueTouchEnabled: undefined,
    };
  },

  computed: {
    problemsByType() {
      return groupProblemsByType(this.problems);
    },
  },

  methods: {
    handleMistakesAmountInput(problemId, event) {
      const entry = this.value?.[this.getProblemEntryIndexById(problemId)];
      if (!entry) {
        this.toggleProblemEntry(problemId);
        this.$nextTick(() => this.handleMistakesAmountInput(problemId, event));
        return;
      }

      entry.mistakeAmount = event;
    },

    showProblemInfoModal(problem) {
      this.activeProblemInfo = problem;
      this.problemInfoModal = true;
    },

    sortLinkedTechniques(techniques) {
      const result = techniques.slice();

      result.sort((a, b) => {
        const numA = Number(a.number.split('.')[1] ?? 0);
        const numB = Number(b.number.split('.')[1] ?? 0);
        if (numA > numB) return 1;
        if (numA < numB) return -1;
        return 0;
      });

      result.sort((a, b) => {
        const numA = Number(a.number.split('.')[0] ?? 0);
        const numB = Number(b.number.split('.')[0] ?? 0);
        if (numA > numB) return 1;
        if (numA < numB) return -1;
        return 0;
      });

      return result;
    },

    openModal(data) {
      this.showModal = true;
      this.techniqueModalData = data;
    },

    closeModal() {
      this.showModal = false;
      this.techniqueModalData = null;
    },

    getToggledProblemEntriesByCategory(type) {
      const problemIds = this.problemsByType[type]?.map((e) => e.id);
      return (
        this.value?.filter((entry) => problemIds?.includes(entry.problemId)) ??
        []
      );
    },

    getProblemEntryIndexById(id) {
      return this.value.findIndex((entry) => {
        if (!entry.problemId) {
          console.error('`problemId` is not defined on problem.`');
        }
        return entry.problemId === id;
      });
    },

    toggleProblemEntry(id) {
      const exists = this.value.find((entry) => entry.problemId === id);

      if (exists) {
        this.$emit(
          'input',
          this.value.filter((entry) => entry.problemId !== id),
        );
      } else {
        this.$emit(
          'input',
          this.value.concat({
            problemId: id,
            mistakeAmount: 0,
            order: this.value.length,
          }),
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_problem-row {
  margin-top: 10px;

  &:last-child {
    border: none;
  }
}

.info {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 1.2rem;

  svg {
    margin: 0 !important;
  }

  @media (hover: none) {
    color: $brand-primary;
  }

  &:hover {
    cursor: pointer;
    color: $brand-primary;
    transition-duration: 0s;

    svg {
      color: currentColor;
    }
  }

  svg {
    margin: 0 0.5rem;
    transform: translateY(-2px);
    color: $brand-gray;
  }
}
</style>

<style lang="scss">
.technique-modal {
  ul,
  ol {
    padding-left: 20px !important;
  }
}
</style>
