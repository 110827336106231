<template>
  <div class="sb_buttons-bar">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SbButtonsBar'
};
</script>

<style lang="scss">
  .sb_buttons-bar{
    margin: 0 auto;

    @media screen and (min-width: 860px) {
      display: flex;
    }

    .ivu-btn {
      margin: 0 15px 15px 0 !important;
    }
}

</style>
