var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"student-paths"},[(
      _vm.$apollo.queries.trackManagerData.loading ||
      _vm.$apollo.queries.getUserById.loading
    )?_c('sb-loading',{attrs:{"position":"absolute","width":"100%","height":"100%"}}):_vm._e(),(_vm.confirmCreateTrack)?_c('modal',{attrs:{"title":"Nieuw traject"},on:{"on-ok":_vm.confirmCreateTrack},model:{value:(_vm.showCreateTrackModal),callback:function ($$v) {_vm.showCreateTrackModal=$$v},expression:"showCreateTrackModal"}},[_c('sb-create-track-form',{model:{value:(_vm.createTrackForm),callback:function ($$v) {_vm.createTrackForm=$$v},expression:"createTrackForm"}})],1):_vm._e(),(!_vm.get(_vm.tracks, 'length') && !_vm.loading)?[_c('sb-divider',{attrs:{"height":"30","title":"Nog geen traject"}}),_c('sb-content-indent',[_c('p',[_vm._v("Je hebt nog geen lopend traject. Wil je er een aanmaken?")]),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.handleCreateTrack.apply(null, arguments)}}},[_c('sb-button',{attrs:{"id":"NewTrackButton","icon-animation-direction":"right"}},[_vm._v(" Traject aanmaken "),_c('sb-icon',{attrs:{"icon-id":"icon_arrow-right"}})],1)],1)]),_c('sb-spacer',{attrs:{"height":"100"}})]:_vm._e(),(_vm.get(_vm.tracks, 'length'))?_c('sb-divider',{attrs:{"title":("Alle trajecten van " + (_vm.queryResponse.fullName))}}):_vm._e(),_c('sb-gql-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.get(_vm.tracks, 'length')),expression:"get(tracks, 'length')"}],ref:"table",attrs:{"config":_vm.tracksTableConfig,"query-options":_vm.getTrackTableQueryOptions(_vm.studentId),"data-path":"tracks"},on:{"row-action-delete":function($event){return _vm.handleDeleteTrack($event.id)},"row-action-activate":function($event){return _vm.handleActivateTrack($event.id, _vm.studentId)},"row-action-deactivate":function($event){return _vm.handleDeactivateTrack($event.id)},"row-action-finish":function($event){return _vm.handleFinishTrack($event.id)},"change":_vm.handleTableDataChange,"loading":function($event){_vm.loading = $event}},scopedSlots:_vm._u([{key:"column-reading-level",fn:function(ref){
    var row = ref.row;
    var content = ref.content;
return [_c('div',{staticClass:"sb_table-cell sb_student-path_action-cell clickable",on:{"click":function($event){return _vm.goToTrack(row.id)}}},[_vm._v(" "+_vm._s(content)+" ")])]}},{key:"column-active",fn:function(ref){
    var row = ref.row;
return [_c('span',{staticClass:"sb-track-status-table-label",class:{
          s_active: row.active,
          s_inactive: !row.active,
          s_closed: !row.active && row.finishedAt,
        }},[(!row.finishedAt)?_c('span',[_vm._v(" "+_vm._s(row.active ? 'Actief' : 'Inactief')+" ")]):_c('span',[_vm._v(" Voltooid ")])])]}},{key:"column-users",fn:function(ref){
        var row = ref.row;
        var content = ref.content;
return [_c('b',[_vm._v(" "+_vm._s(content)+" ")])]}}])},[_c('template',{slot:"header-area"},[_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.handleCreateTrack.apply(null, arguments)}}},[_c('sb-button',{attrs:{"id":"NewTrackButton","button-type":"light","icon-animation-direction":"right"}},[_vm._v(" Nieuw traject "),_c('sb-icon',{attrs:{"icon-id":"icon_plus"}})],1)],1)])],2),_c('sb-spacer',{attrs:{"height":"30"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }