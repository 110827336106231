<template>
  <sb-base-form-layout
    :model="formData"
    :label-width="200"
    :with-bottom-bar="editable"
    :promise="promise"
    @submit="handleSubmit"
  >
    <form-item
      v-for="exercise in formData.cards"
      :key="exercise.id"
      :label="`Hoe ging de oefening? Kaart: ${exercise.card.title}`"
    >
      <i-input
        v-model="exercise.remark"
        type="textarea"
        placeholder="Hoe ging de oefening?"
      />
    </form-item>

    <sb-divider
      v-if="(get(formData, 'cards', 'length') || 0) > 1"
      title="Sessie"
      height="20"
    />

    <form-item label="Toepassen">
      <i-select
        v-model="formData.preAppliedType"
        @input="handleAppliedTypeSelectInput"
      >
        <i-option v-for="item in AppliedType" :key="item" :value="item">
          {{ item }}
        </i-option>
        <i-option value="OTHER">Anders, namelijk:</i-option>
      </i-select>
    </form-item>

    <form-item v-if="formData.preAppliedType === 'OTHER'">
      <i-input v-model="formData.appliedType" type="textarea" />
    </form-item>

    <form-item label="Hoe ging het?">
      <i-input v-model="formData.howDidItGo" type="textarea" />
    </form-item>
    <form-item label="Wat ging er goed?">
      <i-input v-model="formData.whatWentWell" type="textarea" />
    </form-item>
    <form-item label="Wat ging er minder goed?">
      <i-input v-model="formData.whatWentWrong" type="textarea" />
    </form-item>
    <form-item label="Waar moet je de volgende keer extra aan denken?">
      <i-input v-model="formData.whatNeedsAttention" type="textarea" />
    </form-item>

    <sb-spacer height="200" />
  </sb-base-form-layout>
</template>

<script>
import SbBaseFormLayout from '@/components/SbBaseFormLayout.vue';
import { formDataMixin } from '@/mixins/formDataMixin';
import { appliedTypeMixin } from '@/lib/applied-type';
import { DateTime } from 'klokwerk';
import gql from 'graphql-tag';
import { objectOmit } from '@/lib/object-omit';

export default {
  name: 'SbSessionForm',
  components: { SbBaseFormLayout },
  mixins: [
    appliedTypeMixin,
    formDataMixin({
      cards: [],
      preAppliedType: undefined,
      appliedType: undefined,
      // transferAwareness: undefined,
      // appliedExplanation: undefined,
      howDidItGo: undefined,
      whatWentWell: undefined,
      whatWentWrong: undefined,
      whatNeedsAttention: undefined,
    }),
  ],

  props: { defaultData: { type: Object, default: undefined } },

  data: () => ({ promise: undefined }),

  computed: {
    editable() {
      if (!this.formData.createdAt) return false;
      return (
        new Date(this.formData.createdAt).getTime() >
        new DateTime(new Date())
          .setDate((current) => current.date - 1)
          .native.getTime()
      );
    },
  },

  watch: {
    formData(value) {
      if (!value) return;
      if (!this.AppliedType.includes(value.appliedType)) {
        this.formData.preAppliedType = 'OTHER';
      } else {
        this.formData.preAppliedType = value.appliedType;
      }
    },
  },

  methods: {
    handleAppliedTypeSelectInput(event) {
      if (event === 'OTHER') {
        return;
      }
      this.formData.appliedType = event;
    },

    async handleSubmit() {
      const sessionId = this.formData.id;
      const updateSessionData = objectOmit(
        this.formData,
        '__typename',
        'cards',
        'createdAt',
        'id',
        'preAppliedType',
      );

      const exercises = this.formData.cards.map((exercise) =>
        objectOmit(exercise, '__typename', 'card'),
      );
      const exerciseIds = exercises.map((exercise) => exercise.id);
      const updateExerciseData = exercises.map((exercise) =>
        objectOmit(exercise, 'id'),
      );
      const exerciseMutationParams = exerciseIds
        .map((id) => `$exerciseInput_${id}: FinishCardExerciseInput!`)
        .join(', ');
      const exerciseMutations = exerciseIds.map((id) => {
        return `finishCardExercise_${id}: finishCardExercise(input: $exerciseInput_${id}) {
          id
          remark
          finishedAt
        }`;
      });

      try {
        const { data, errors } = await (this.promise = this.$apollo.mutate({
          variables: {
            ...exerciseIds.reduce((acc, id, index) => {
              acc[`exerciseInput_${id}`] = {
                id,
                data: updateExerciseData[index],
              };
              return acc;
            }, {}),
            sessionInput: {
              id: sessionId,
              data: updateSessionData,
            },
          },

          mutation: gql`
          mutation SbSessionForm_UpdateSession(
            $sessionInput: UpdateSessionInput!,
            ${exerciseMutationParams}
          ) {
            ${exerciseMutations}
            updateSession(input: $sessionInput) {
              id
              createdAt
              appliedType
              howDidItGo
              whatWentWell
              whatWentWrong
              whatNeedsAttention
              cards {
                id
                remark
                card {
                  id
                  title
                }
              }
            }
          }
        `,
        }));

        if (errors) {
          throw new Error(errors.map((e) => e.message).join('\n'));
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
