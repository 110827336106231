<template>
  <sb-student-form
    :default-data="data"
    :organisation-id="$route.params.resourceId"
    :role="Role.STUDENT"
    :default-mode="'edit' in $route.query ? 'edit' : 'readonly'"
  />
</template>

<script>
import SbStudentForm from '@/components/SbStudentForm/SbStudentForm.vue';
import { roleMixin } from '@/lib/role';

export default {
  name: 'StudentData',
  components: { SbStudentForm },
  mixins: [roleMixin],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
