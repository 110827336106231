<template>
  <div ref="container" class="sb_cognition">
    <!-- columns -->

    <div ref="inner" class="sb_cognition_grid" @scroll="handleScroll">
      <div
        v-for="type in CognitiveMapColumn"
        ref="columns"
        :key="type"
        :data-column="type"
        class="sb_cognition_column"
        :style="readonly ? { pointerEvents: 'none' } : undefined"
      >
        <div class="sb_cognition_column_image-container">
          <img
            :data-type="type"
            :src="getCognitiveMapColumnImageSource(type)"
            class="sb_cognition_column_image"
          />
        </div>

        <div class="sb_cognition_column_title">
          {{ getCognitiveMapColumnLabel(type) }}
        </div>

        <!-- entries -->
        <div
          v-if="activeStudent"
          ref="entryContainers"
          class="sb_cognition_column_entries"
        >
          <div
            v-if="!entries[activeStudent.id][type].length"
            class="drop-target"
            style="height: 100%; position: absolute"
          />

          <template v-for="(entry, index) in entries[activeStudent.id][type]">
            <div
              v-if="index === 0"
              :key="`pre-drop-target-${getEntryKey(type, index)}`"
              class="drop-target"
            />

            <div
              :key="getEntryKey(type, index)"
              :data-column="type"
              :data-index="index"
              class="sb_cognition_column_entries_entry"
              :class="{
                'drag-target': activeEntry !== getEntryKey(type, index),
              }"
            >
              <template v-if="activeEntry === getEntryKey(type, index)">
                <i-input
                  v-model="entry.text"
                  :data-key="getEntryKey(type, index)"
                  type="textarea"
                />

                <div class="sb_cognition_column_entries_entry_form-buttons">
                  <i-button
                    size="small"
                    @click="cancelEntryEditMode(type, index, false)"
                  >
                    Annuleren
                  </i-button>
                  <i-button
                    :disabled="!entry.text"
                    size="small"
                    type="primary"
                    @click="updateEntry(type, index)"
                  >
                    Opslaan
                  </i-button>
                </div>
              </template>

              <div v-else class="sb_cognition_column_entries_entry_text">
                {{ entries[activeStudent.id][type][index].text }}
              </div>

              <poptip
                v-if="!activeEntry"
                ref="poptips"
                trigger="hover"
                placement="bottom-end"
                class="sb_cognition_column_entries_entry_options"
                :data-key="getEntryKey(type, index)"
              >
                <sb-icon icon-id="icon_more" />

                <!-- <poptip placement="bottom-end"> -->
                <template slot="content">
                  <div
                    class="sb_cognition_column_entries_entry_option"
                    @click="toggleEntryEditMode($event, type, index)"
                  >
                    Aanpassen
                  </div>
                  <div
                    class="sb_cognition_column_entries_entry_option"
                    @click="removeEntry(type, index)"
                  >
                    Verwijderen
                  </div>
                </template>
                <!-- </poptip> -->
              </poptip>
            </div>

            <div
              :key="`drop-target-${getEntryKey(type, index)}`"
              class="drop-target"
            />
          </template>

          <!-- new entry button -->
          <div
            class="sb_cognition_column_entries_new sb_cognition_column_entries_entry non-target"
            :style="
              !entries[activeStudent.id][type].length && 'margin-top: 20px'
            "
            @click="prepareNewEntry(type)"
          >
            <sb-icon icon-id="icon_plus" />
          </div>
        </div>
      </div>
    </div>

    <!-- user wheel -->
    <div v-if="students && students.length > 1" class="sb_cognition_user-wheel">
      <div class="sb_cognition_user-wheel_background" />
      <div class="sb_cognition_user-wheel_users">
        <div
          v-for="(student, index) in students"
          :key="student.id"
          class="sb_cognition_user-wheel_user"
          :class="{ s_invisible: student !== activeStudent }"
        >
          <button
            class="sb_cognition_user-wheel_icon-container"
            :class="{ s_disabled: students.indexOf(student) === 0 }"
            :disabled="students.indexOf(student) === 0"
            @click="activeStudent = students[index - 1]"
          >
            <sb-icon icon-id="icon_caret-left" />
          </button>

          <div class="sb_cognition_user-wheel_user-name">
            {{ student.fullName }}
          </div>

          <button
            class="sb_cognition_user-wheel_icon-container"
            :class="{
              s_disabled: students.indexOf(student) === students.length - 1,
            }"
            :disabled="students.indexOf(student) === students.length - 1"
            @click="activeStudent = students[index + 1]"
          >
            <sb-icon icon-id="icon_caret-right" />
          </button>
        </div>
      </div>

      <div class="sb_cognition_user-wheel_indicator-container">
        <div
          v-for="student in students"
          :key="student.id"
          class="sb_cognition_user-wheel_indicator"
          :class="{ s_active: student === activeStudent }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cognitiveMapColumnMixin } from '@/lib/cognitive-map-column';
import SbIcon from '@/components/global/SbIcon.vue';
import gql from 'graphql-tag';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { createDraggables } from './create-draggables';

export default {
  name: 'SbCardsSessionCognition',

  components: { SbIcon },

  mixins: [
    cognitiveMapColumnMixin,
    nonReactiveMembersMixin(() => ({ draggables: undefined })),
  ],

  props: {
    studentIds: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    trackIds: { type: Array, default: () => [] },
    sessionIds: { type: Array, default: () => [] },
  },

  data() {
    return {
      activeStudent: undefined,
      activeEntry: undefined,
      entries: {},
      isDragging: false,
    };
  },

  computed: {
    dragEnabled() {
      return !this.activeEntry;
    },

    students() {
      return this.getUserById?.coachingStudents.edges.map(({ node }) => node);
    },

    studentCognitiveMapLookup() {
      return this.students.reduce((acc, student) => {
        if (!student.tracks.edges.length) return acc;
        acc[student.id] = student.tracks.edges[0].node.cognitiveMapId;
        return acc;
      }, {});
    },

    totalEntriesAmount() {
      let total = 0;

      Object.values(this.entries).forEach((studentEntries) => {
        Object.values(studentEntries).forEach((entries) => {
          total += entries.length;
        });
      });

      return total;
    },
  },

  watch: {
    activeEntry(value) {
      if (!value) {
        return void (this.originalEntryText = undefined);
      }

      const [column, studentId, index] = value.split('-');
      const entry = this.entries[studentId][column][index];
      this.originalEntryText = entry.text;
    },

    dragEnabled(value) {
      if (!value) this.destroyDraggables();
    },

    students: {
      handler(value) {
        if (!value) return;

        this.students.forEach((student) => {
          this.$set(this.entries, student.id, {});

          Object.values(this.CognitiveMapColumn).forEach((type) => {
            const entries =
              student.tracks?.edges[0]?.node?.cognitiveMap?.entries?.filter(
                (entry) => entry.column === type,
              ) || [];

            entries.sort((a, b) => a.order - b.order);

            this.$set(this.entries[student.id], type, entries);
          });
        });

        this.activeStudent = this.students[0];

        this.$nextTick(() => {
          this.destroyDraggables();
          this.createDraggables();
        });
      },
      immediate: true,
    },
  },

  created() {
    this.resizeObserver = undefined;
  },

  mouned() {
    this.createDraggables();
  },

  beforeDestroy() {
    this.resizeObserver?.disconnect();
    this.destroyDraggables();
  },

  methods: {
    createDraggables,

    updateDraggables() {
      if (!this.readonly && this.dragEnabled) {
        // this.$nextTick();
        // this.createDraggables();
        // this.$nextTick();
        this.createDraggables(true);
      }
    },

    destroyDraggables() {
      while (this.draggables?.length) {
        this.draggables.pop().kill();
      }
    },

    async toggleEntryEditMode(event, type, index) {
      const draggable = this.draggables.find(
        (d) =>
          !!d.target &&
          d.target ===
            event
              .composedPath()
              .find((el) =>
                el.classList.contains('sb_cognition_column_entries_entry'),
              ),
      );

      draggable?.disable();

      this.activeEntry = this.getEntryKey(type, index);

      this.$nextTick(() => {
        draggable?.target?.getElementsByTagName('textarea')[0]?.focus();
      });
    },

    handleScroll() {
      if (this.animationFrame != undefined) {
        cancelAnimationFrame(this.animationFrame);
      }

      this.animationFrame = requestAnimationFrame(() => {
        this.createDraggables(true);
      });
    },

    prepareNewEntry(type) {
      this.activeEntry = this.getEntryKey(
        type,
        this.entries[this.activeStudent.id][type].push({ text: '' }) - 1,
      );

      this.$forceUpdate();

      requestAnimationFrame(() => {
        this.$refs.container
          .querySelector(`[data-key="${this.activeEntry}"]`)
          .getElementsByTagName('textarea')[0]
          .focus();
      });
    },

    removeEntry(type, index, notify = true) {
      const entry = this.entries[this.activeStudent.id][type][index];

      if (entry.id) {
        this.$apollo
          .mutate({
            mutation: gql`mutation DeleteCognitiveMapEntry {
              deleteCognitiveMapEntry(id: "${entry.id}")
            }`,
          })
          .then(() => {
            this.$refs.container
              .querySelector(
                `.ivu-poptip[data-key="${this.getEntryKey(type, index)}"]`,
              )
              ?.dispatchEvent(new Event('mouseleave'));
            this.entries[this.activeStudent.id][type].splice(index, 1);
            if (notify) this.$Message.success({ content: 'Verwijderd' });
          });
      }

      this.$forceUpdate();
    },

    cancelEntryEditMode(type, index) {
      const entry = this.entries[this.activeStudent.id][type][index];
      const preserve = !!entry?.id;

      if (preserve) {
        entry.text = this.originalEntryText;
        this.originalEntryText = undefined;
        this.activeEntry = undefined;
      } else {
        this.entries[this.activeStudent.id][type].splice(index, 1);
      }

      this.$nextTick(() => {
        this.destroyDraggables();
        this.createDraggables();
      });
    },

    async updateEntry(type, index, notify = true, noCache = false) {
      const entry = this.entries[this.activeStudent.id][type][index];

      const sessionId = this.sessionIds[
        this.studentIds.indexOf(this.activeStudent.id)
      ];

      const cognitiveMapId = this.studentCognitiveMapLookup[
        this.activeStudent.id
      ];

      if (entry.id) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateCognitiveMapEntry(
                $input: UpdateCognitiveMapEntryInput!
              ) {
                updateCognitiveMapEntry(input: $input) {
                  id
                }
              }
            `,
            ...(noCache && { fetchPolicy: 'no-cache' }),
            variables: {
              input: {
                id: entry.id,
                data: {
                  text: entry.text,
                  order: entry.order,
                  column: entry.column,
                  sessionId,
                },
              },
            },
          })
          .then(() => {
            notify && this.$Message.success({ content: 'Aangepast' });
            this.$nextTick(() => {
              this.destroyDraggables();
              this.createDraggables();
            });
          });
      }

      if (!entry.id) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateCognitiveMapEntry(
                $input: CreateCognitiveMapEntryInput!
              ) {
                createCognitiveMapEntry(input: $input) {
                  id
                  text
                }
              }
            `,
            variables: {
              input: {
                cognitiveMapId,
                text: entry.text,
                column: type,
                order: index,
                sessionId,
              },
            },
          })
          .then((result) => {
            entry.id = result.data.createCognitiveMapEntry.id;
            entry.text = result.data.createCognitiveMapEntry.text;
            if (notify) this.$Message.success({ content: 'Toegevoegd!' });
            this.$nextTick(() => {
              this.destroyDraggables();
              this.createDraggables();
            });
          });
      }

      this.activeEntry = undefined;
    },

    getEntryKey(type, index) {
      return [type, this.activeStudent.id, index].join('-');
    },

    async submitEntries() {
      return;
    },
  },

  apollo: {
    getUserById: {
      fetchPolicy: 'cache-and-network',

      variables() {
        return {
          coachId: this.$user.id,
          studentsAmount: this.studentIds.length,
          studentsFilter: { id: { in: this.studentIds } },
          tracksFilter: {
            ...(this.trackIds?.length && { id: { in: this.trackIds } }),
            active: true,
          },
        };
      },

      query: gql`
        query CognitionBoard_GetUserById(
          $coachId: ID!
          $studentsAmount: Int
          $studentsFilter: UsersFilter
          $tracksFilter: TracksFilter
        ) {
          getUserById(id: $coachId) {
            id
            coachingStudents(first: $studentsAmount, filter: $studentsFilter) {
              edges {
                node {
                  id
                  fullName
                  tracks(filter: $tracksFilter) {
                    edges {
                      node {
                        id
                        cognitiveMapId
                        cognitiveMap {
                          entries {
                            id
                            order
                            text
                            column
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_cognition {
  position: relative;
  overflow: hidden;

  &_grid {
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    background: $brand-light-gray;
    position: relative;
    height: 100%;
    overflow-y: auto;
  }

  &_column {
    min-width: 0;
    padding: 20px 20px 120px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      180deg,
      rgb(250, 240, 243) 0%,
      rgb(250, 240, 243) 137px,
      $brand-white 137px
    );
    min-height: 200%;

    .drop-target {
      &.filler {
        flex: 1;
        &.dragging {
          // outline: 1px dashed $brand-light-gray;
          // outline-offset: -3px;
        }
      }
    }

    &:hover {
      .sb_cognition_column_image {
        filter: none;
      }

      .sb_cognition_column_entries_new {
        opacity: 1;
        transition-duration: 0s;
        pointer-events: all;
      }
    }

    &_title {
      font-size: 21px;
      font-weight: 700;
      margin-top: 10px;
      padding-bottom: 20px;
      border-bottom: 2px solid $brand-task-cognition;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &:hover {
        // no ellipsis on hover
        text-overflow: initial;
        white-space: initial;
      }
    }

    &_image-container {
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &_image {
      display: block;
      margin: 0 auto;
      object-fit: contain;
      filter: grayscale(100%);

      &[data-type='READING_NOW'] {
        width: 128px;
        height: 169px;
        transform: translateY(20px);
      }

      &[data-type='TARGET'] {
        width: 114px;
        height: 106px;
        transform: translateY(-25px);
      }

      &[data-type='DOING'] {
        width: 134px;
        height: 137px;
        transform: translateY(-28px);
      }

      &[data-type='READING_AFTER'] {
        width: 133px;
        height: 137px;
        transform: translateY(-28px);
      }
    }

    &_entries {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: visible;
      position: relative;

      .drop-target {
        width: 100%;
        height: 20px;

        &.hovering {
          background: $brand-gray;
          height: 50px;
        }
      }

      &_entry {
        padding: 20px;
        min-height: 50px;
        background: $brand-lightest-gray;
        border-radius: $default-border-radius;
        position: relative;

        &.dragging {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        &:hover {
          .sb_cognition_column_entries_entry_options {
            opacity: 1;
            transition-duration: 0s;
          }
        }

        &_form-buttons {
          display: flex;
          margin: 10px 0 0 0;
          justify-content: flex-end;
          gap: 10px;
          flex-wrap: wrap;
        }

        &_text {
          font-size: rem-calc(18);
          overflow: auto;
          word-break: break-word;
          max-height: 200px;
        }

        &_options {
          position: absolute;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 0;
          top: 5px;
          color: $brand-primary;
          cursor: pointer;
          opacity: 0;
          transition: 0.3s opacity;
        }

        &_option {
          cursor: pointer;
          color: $brand-black;
          padding: 5px 0;
          transition: 0.3s color;

          &:hover {
            color: $brand-primary;
            transition-duration: 0s;
          }
        }
      }

      &_new {
        opacity: 0;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($brand-task-cognition, 0.06);
        border: 1px solid transparent;
        width: 100%;
        height: 80px;
        border-radius: $default-border-radius;
        font-size: 22px;
        cursor: pointer;
        transition: 0.3s color, 0.3s background-color, 0.3s opacity;

        &.dragging {
          display: none;
        }

        &:hover {
          color: $brand-primary;
          transition-duration: 0s;
        }
      }
    }
  }

  &_user-wheel {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    color: $brand-white;

    &_background {
      position: absolute;
      left: 50%;
      top: -15px;
      width: 4px;
      height: 100%;
      transform: translateX(-50%);
      background: $brand-white;
    }

    &_users {
      position: relative;
      height: 40px;
      width: 100%;
    }

    &_user {
      display: flex;
      width: max-content;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.3s opacity ease-in-out;

      &.s_invisible {
        opacity: 0;
        pointer-events: none;
      }
    }

    &_user-name {
      height: 40px;
      border-radius: 20px;
      background: $brand-primary;
      margin: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      font-weight: 600;
    }

    &_icon-container {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $brand-primary;
      font-size: 30px;
      border-radius: 50%;
      color: inherit;

      &.s_disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &_indicator-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px 0 20px 0;
    }

    &_indicator {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: $brand-white;
      border: 1px solid $brand-primary;
      transition: 0.2s background-color;

      &.s_active {
        background: rgba($brand-primary, 0.3);
      }
    }
  }
}
</style>
