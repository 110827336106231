const getMinutesToHourMinutesFormatter = (_options) => {
  return {
    format: (value) => {
      const hours = Math.floor(value / 60);
      const minutesLeft = value % 60;
      if (hours === 0) return `${minutesLeft}m`;

      return `${hours}u ${minutesLeft}m`;
    },
  };
};

export const Format = {
  formatIfNotNull: (value, formatter, options) => {
    return value !== null && value !== undefined && !isNaN(value)
      ? formatter(value, options)
      : '-';
  },

  minutesToHourMinutes: (value, options) =>
    Format.formatIfNotNull(
      value,
      (val, opts) =>
        getMinutesToHourMinutesFormatter(
          opts ?? { maximumFractionDigits: 2 },
        ).format(val),
      options,
    ),
};
