<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Leerling Verhuizen</h1>
      </template>
      <template v-slot:right> </template>
    </sb-page-header>
    <i-form ref="school" :rules="validationRules">
      <form-item label="School" prop="migrate-school">
        <sb-info>
          <p>
            Selecteer een school waarnaar de gebruiker verhuisd moet worden. Let
            op, actieve licenties en cursusgroep worden ontkoppeld.
          </p>
        </sb-info>
        <i-select
          v-model="selectedSchool"
          style="max-width: 300px"
          :placeholder="`Kies een school`"
        >
          <i-option
            v-for="school in schools"
            :key="school.id"
            :value="school.id"
          >
            {{ school.name }}
          </i-option>
        </i-select>
      </form-item>

      <i-button :disabled="!selectedSchool" type="primary" @click="confirmMove">
        Verhuizen
      </i-button>
    </i-form>
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbInfo from '@/components/SbInfo.vue';

export default {
  components: {
    SbPageHeader,
    SbInfo,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedSchool: null,
      validationRules: {},
    };
  },
  methods: {
    confirmMove() {
      const schoolToMigrateTo = this.schools.find(
        (school) => school.id === this.selectedSchool,
      );
      this.$Modal.confirm({
        title: 'Weet je het zeker?',
        content: `Wil je de Leerling verhuizen naar "${schoolToMigrateTo.name}"? Let op de licentie zal worden ontkoppeld!`,
        onOk: async () => {
          const { data } = await this.$apollo.mutate({
            // NEEDS MUTATION
            mutation: false,
            variables: {
              userId: this.data.id,
              schoolId: this.selectedSchool,
            },
          });
          if (data) {
            setTimeout(() => {
              this.$Message.info({
                content: `De Leerling is verhuisd naar "${schoolToMigrateTo.name}".`,
              });
            }, 100);
          }
          console.log('migratie', data);
          this.$router.push({
            name: 'ResourceManagementStudentsStudent',
            params: {
              resourceId: schoolToMigrateTo.id,
              studentId: this.data.id,
            },
          });
        },
      });
    },
  },
  apollo: {
    schools: {
      skip() {
        return this.$user.role !== 'ADMIN';
      },
      query: false,
      update(data) {
        console.log({
          data,
        });
        return data.schoolsSearch.edges
          .map((edge) => edge.node)
          .filter((school) => school.id !== this.data.schools[0].id);
      },
    },
  },
};
</script>

<style></style>
