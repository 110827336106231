import { problemTypeMixin } from '@/lib/problem-type';
import gql from 'graphql-tag';

export const problemsMixin = {
  mixins: [problemTypeMixin],

  methods: {
    getProblemById(id) {
      return this.problems?.find((node) => node.id === id);
    },

    getLinkedTechniquesbyProblemId(id) {
      return this.getProblemById(id)?.linkedTechniques;
    },
  },

  apollo: {
    problems: {
      fetchPolicy: 'cache-and-network',
      update(response) {
        const parsed = (response.problems || []).reduce(
          (problemsAccumulator, node) => {
            const { id, title, types, description, linkedTechniques } = node;

            return problemsAccumulator.concat({
              id,
              title,
              description,
              types,
              linkedTechniques,
            });
          },
          [],
        );

        return parsed;
      },

      query: gql`
        query Problems {
          problems {
            id
            title
            description
            types

            linkedTechniques {
              id
              title
              number
              content
            }
          }
        }
      `,
    },
  },
};
