<template>
  <sb-base-form-layout
    ref="formLayout"
    v-bind="$attrs"
    :auto-validate="false"
    :model="formData"
    :rules="_validators"
    :save-button-text="$route.query.origin ? 'Opslaan' : 'Volgende'"
    :with-bottom-bar-indent="false"
    @submit="handleSubmit"
    @cancel="$emit('cancel')"
  >
    <template #buttons-right>
      <slot name="skip" />
    </template>

    <template v-if="intakeType === 'dmt'">
      <sb-divider title="Algemeen" top-space="20px" />

      <form-item label="Versie" prop="version">
        <i-select v-model="formData.version">
          <i-option value="Regulier">Regulier</i-option>
          <i-option value="Redzaamheidsvariant">Redzaamheidsvariant</i-option>
        </i-select>
      </form-item>

      <form-item label="Soort lezer" prop="zelfredzaamheid">
        <sb-info>
          <p>
            Soort lezer. Redzaamheidslezer: Een lezer die eerst een focus heeft
            op nauwkeurig en vloeiend lezen in plaats van snel lezen.
            Tempolezer: Een lezer die een focus heeft op snel leren lezen.
          </p>
        </sb-info>
        <sb-zelfredzaamheid-select v-model="formData.zelfredzaamheid" />
      </form-item>

      <form-item label="Niveau" prop="level" :required="false">
        <sb-reading-level-select v-model="formData.levels" />
      </form-item>

      <sb-divider title="DMT Toetsresultaten" />

      <form-item prop="results" :label-width="0" class="ToetsResults">
        <sb-dmt-test-result-input
          ref="dmtTestResultInput"
          v-model="formData.results"
        />
      </form-item>
    </template>

    <template v-if="intakeType === 'avi'">
      <sb-divider title="Oefenniveau" top-space="20px" />

      <form-item label="Niveau" prop="level" :required="false">
        <sb-reading-level-select v-model="formData.levels" />
      </form-item>

      <sb-divider title="AVI Toetsresultaten" />
      <sb-avi-test-result-input
        ref="aviTestResultInput"
        v-model="formData.results"
        class="ToetsResults"
        :form="get($refs, 'formLayout', 'form')"
        :validate="!!formData.results"
      />
    </template>

    <template v-if="intakeType === 'misc'">
      <sb-divider title="Toets" top-space="20px" />

      <form-item class="ToetsResults" label="Resultaten" prop="type">
        <sb-misc-test-result-input v-model="formData.type" />
      </form-item>
    </template>

    <sb-divider title="Afname toets" />

    <form-item label="Datum" prop="testDate">
      <date-picker
        format="dd-MM-yyyy"
        placeholder="dd-mm-jjjj"
        :value="testDate"
        :options="datePickerOptions"
        @input="
          formData.testDate =
            $event instanceof Date ? $event.toISOString() : $event
        "
      />
    </form-item>

    <form-item label="Afgenomen door" prop="testSupervisor">
      <i-input v-model="formData.testSupervisor" />
    </form-item>

    <sb-divider title="Beoordelingen &amp; opmerkingen" />

    <form-item label="Opmerkingen" prop="remark">
      <i-input
        v-model="formData.remark"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 10 }"
      />
    </form-item>

    <sb-spacer height="120" />
  </sb-base-form-layout>
</template>

<script>
import { formDataMixin } from '@/mixins/formDataMixin';
import SbDivider from '../SbDivider.vue';
import SbReadingLevelSelect from '../SbReadingLevelSelect.vue';
import SbZelfredzaamheidSelect from '../SbZelfredzaamheidSelect.vue';
import SbDmtTestResultInput from '../SbDmtTestResultInput.vue';
import SbAviTestResultInput from '../SbAviTestResultInput.vue';
import SbMiscTestResultInput from '../SbMiscTestResultInput.vue';
import { dmtValidators, aviValidators, miscValidators } from './validators';
import SbBaseFormLayout from '../SbBaseFormLayout.vue';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { isFutureDate } from '@/lib/is-future-date';
import gql from 'graphql-tag';

export default {
  name: 'SbStudentIntakeForm',

  components: {
    SbDivider,
    SbZelfredzaamheidSelect,
    SbReadingLevelSelect,
    SbDmtTestResultInput,
    SbAviTestResultInput,
    SbMiscTestResultInput,
    SbBaseFormLayout,
  },

  mixins: [
    nonReactiveMembersMixin(() => ({
      datePickerOptions: {
        disabledDate: (date) => isFutureDate(date),
      },
    })),

    formDataMixin(
      {
        version: undefined,
        zelfredzaamheid: undefined,
        levels: undefined,
        testDate: undefined,
        testSupervisor: undefined,
        remark: undefined,
        type: undefined,
        results: undefined,
      },
      'form',
    ),
  ],

  props: {
    intakeType: {
      type: String,
      required: true,
      validator: (value) => ['dmt', 'avi', 'misc'].includes(value),
    },

    cancelButtonText: {
      type: String,
      default: 'Annuleren',
    },

    saveButtonText: {
      type: String,
      default: 'Opslaan',
    },

    trackId: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  computed: {
    _validators() {
      switch (this.intakeType) {
        case 'dmt':
          return { ...dmtValidators, ...this.validator };
        case 'avi':
          return { ...aviValidators, ...this.validator };
        case 'misc':
          return { ...miscValidators, ...this.validator };
        default:
          return this.validator;
      }
    },

    testDate() {
      return this.formData.testDate
        ? new Date(this.formData.testDate)
        : new Date();
    },

    form() {
      return this.$refs.formLayout.form;
    },

    coach() {
      return this.getTrackById?.users.find(
        (user) => user.role === 'TRACK_COACH' && user.userId === this.$user.id,
      )?.user;
    },

    student() {
      return this.getTrackById?.users.find(
        (user) => user.userId === this.$route.params.studentId,
      )?.user;
    },
  },

  methods: {
    reset() {
      this.resetFormData();
      this.$refs.formLayout.form.resetFields();
      this.$refs.dmtTestResultInput?.reset();
    },

    handleSubmit() {
      this.$emit('submit', {
        intakeType: this.intakeType,
        data: this.formData,
      });
    },
  },

  apollo: {
    getTrackById: {
      skip() {
        return !this.trackId;
      },

      query() {
        return gql`query GetTrackById {
          getTrackById(id: "${this.trackId}") {
            id
            readingLevels
            users {
              userId
              role
              user {
                id
                fullName
                zelfredzaamheid
              }
            }
          }
        }`;
      },

      result({ data }) {
        if (['avi', 'dmt'].includes(this.intakeType)) {
          this.formData.levels =
            this.formData.levels ?? data.getTrackById.readingLevel;
        }

        if (this.intakeType === 'dmt') {
          this.formData.zelfredzaamheid =
            this.formData.zelfredzaamheid ?? this.student?.zelfredzaamheid;
        }

        this.formData.testSupervisor =
          this.formData.testSupervisor ?? this.coach?.fullName;

        this.$emit('input', this.formData);
      },
    },
  },
};
</script>
