<template>
  <div>
    <sb-loading v-if="!!$apollo.queries.user.loading" />

    <sb-loading v-if="!!archiver.loading" />

    <div v-if="user">
      <sb-page-header>
        <template v-slot:left>
          <h1>{{ fullName }}</h1>
          <tag v-if="redzaamheid" :color="redzamheidColor">
            {{ redzaamheid }}
          </tag>
        </template>

        <template v-slot:right>
          <dropdown placement="bottom-end">
            <i-button type="primary" ghost>
              Acties
              <icon type="md-more" />
            </i-button>

            <dropdown-menu slot="list" style="width: 220px">
              <router-link
                :to="{ name: 'ResourceManagementStudentsStudentData' }"
              >
                <sb-dropdown-button> Gegevens </sb-dropdown-button>
              </router-link>

              <router-link
                :to="{ name: 'ResourceManagementStudentsStudentEditPassword' }"
              >
                <sb-dropdown-button> Wachtwoord wijzigen </sb-dropdown-button>
              </router-link>

              <router-link
                v-if="['ADMIN'].includes($user.role)"
                :to="{ name: 'ResourceManagementStudentsStudentMove' }"
              >
                <sb-dropdown-button> Leerling verhuizen </sb-dropdown-button>
              </router-link>

              <sb-dropdown-button
                v-if="['ADMIN', 'MANAGER'].includes($user.role)"
                style="color: red"
                @click.native="archiveUser"
              >
                Leerling archiveren
              </sb-dropdown-button>
            </dropdown-menu>
          </dropdown>
        </template>
      </sb-page-header>

      <sb-tabbed-page
        v-if="user"
        :data="user"
        type="student"
        @refetch="refetch"
      />
    </div>

    <sb-add-students-to-course-group-modal
      v-model="modalAddToCourseGroup"
      :student-ids="studentsToAddToCourseGroup"
      @success="refetch"
    />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbPageHeader from '@/components/SbPageHeader';
import SbTabbedPage from '@/components/SbTabbedPage';
import SbAddStudentsToCourseGroupModal from '@/components/SbAddStudentsToCourseGroupModal';
import GetUserById from '@/graphql/queries/GetUserById.gql';
import { UserArchiver } from '@/lib/user-archiver';
import { TourStarter } from '@/shepherd/TourStarter';
import { mapActions } from 'vuex';

export default {
  name: 'OnlineStudent',

  components: {
    SbPageHeader,
    SbTabbedPage,
    SbLoading,
    SbAddStudentsToCourseGroupModal,
  },

  props: {
    studentId: {
      type: String,
      required: true,
    },
  },

  provide() {
    const self = this;
    return {
      student: () => self.user,
    };
  },

  data() {
    return {
      user: null,
      modalAddToCourseGroup: false,
      studentsToAddToCourseGroup: null,
      hasActiveTrack: null,
    };
  },

  computed: {
    fullName() {
      if (!this.user) return '';
      return `${this.user.firstName} ${this.user.prefix || ''} ${
        this.user.lastName
      }`;
    },

    redzaamheid() {
      return {
        REDZAAMHEIDSLEZER: 'Redzaamheidslezer',
        REGULIER: 'Tempolezer',
      }[this.user.zelfredzaamheid];
    },

    redzamheidColor() {
      return { REDZAAMHEIDSLEZER: 'orange', REGULIER: 'green' }[
        this.user.zelfredzaamheid
      ];
    },
  },

  watch: {
    hasActiveTrack: {
      handler(has) {
        if (has) {
          console.log('has active track');
          const currentTourKey = TourStarter.getTourKeyForRole(
            this.$user.role,
            ['MANAGER_STUDENT', 'COACH_CURRENT_TRACK'],
          );
          this.enqueueTour(currentTourKey);
        } else {
          console.log('has no active track');
          const currentTourKey = TourStarter.getTourKeyForRole(
            this.$user.role,
            ['MANAGER_STUDENT', 'COACH_STUDENT'],
          );
          this.enqueueTour(currentTourKey);
        }
      },
      immediate: true,
    },
  },

  created() {
    this.archiver = new UserArchiver(this)
      .setUserTypeLabel('leerling')
      .on('success', this.$router.navigateBack);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
    async archiveUser() {
      await this.archiver.archiveUser(this.user.id);
    },

    addStudentToCourseGroup() {
      this.modalAddToCourseGroup = true;
      this.studentsToAddToCourseGroup = [this.user.id];
    },

    refetch() {
      this.$apollo.queries.user.refetch();
    },
  },
  apollo: {
    user: {
      query: GetUserById,
      variables() {
        return {
          id: this.studentId,
        };
      },
      update(data) {
        console.log('update', data.getUserById);
        this.hasActiveTrack =
          !!data?.getUserById.tracks.edges
            .map((edge) => edge.node)
            .filter((node) => node.active)?.length > 0;

        if (!data.getUserById) {
          this.$Modal.warning({
            title: 'Melding',
            content: 'Deze gebruiker is niet gevonden',
            onOk: () => {
              this.$router.navigateBack();
            },
          });
        }
        return data.getUserById;
      },
    },
  },
};
</script>

<style lang="scss"></style>
