<template>
  <modal
    :value="value"
    footer-hide
    :title="title"
    :width="800"
    class="technique-modal"
    @input="handleInput"
    @on-close="log($event)"
  >
    <div class="sb_technique-modal_body" v-html="get(technique, 'content')" />
  </modal>
</template>

<script>
export default {
  name: 'SbTechniqueModal',

  props: {
    value: { type: Boolean },
    technique: { type: Object, default: undefined },
  },

  computed: {
    title() {
      return [this.technique?.number, this.technique?.title]
        .filter(Boolean)
        .join(' ');
    },
  },

  methods: {
    handleInput(event) {
      this.$emit('input', event);
      if (!event) this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.sb_technique-modal_body {
  white-space: normal;
}
</style>
