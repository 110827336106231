var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sb_journal_block-container"},[_c('div',{staticClass:"sb_journal_block",class:{ 's_is-open': _vm.toggled, 's_is-toggleable': true },attrs:{"type":_vm.type}},[_c('sb-journal-comments-modal',{attrs:{"journal-entry-id":_vm.commentsModalJournalId},on:{"comment-created":function () { return _vm.$emit('on-comment-created'); }},model:{value:(_vm.showComments),callback:function ($$v) {_vm.showComments=$$v},expression:"showComments"}}),_c('button',{staticClass:"sb_journal_block_header",style:({
        gridTemplateColumns: _vm.showTimestamps
          ? 'auto auto 1fr auto auto 20px'
          : 'auto 1fr auto auto 20px',
      }),on:{"click":_vm.toggleSession}},[_c('div',{staticClass:"sb_journal_block_header_date"},[_vm._v(" "+_vm._s(_vm.timestamp)+" ")]),(_vm.showTimestamps)?_c('div',{staticClass:"sb_journal_block_header_time"},[_vm._v(" "+_vm._s(_vm.blockTime)+" ")]):_vm._e(),_c('div',{staticClass:"sb_journal_block_header_title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('sb-icon-badge',{attrs:{"icon-id":"icon_user","label":_vm.creator}}),_c('sb-icon-badge',{attrs:{"icon-id":"icon_clock","label":_vm.Format.minutesToHourMinutes(_vm.group.categoryGroupTime)}}),_c('div',{staticClass:"sb_journal_block_header_caret"},[_c('sb-icon',{attrs:{"icon-id":"icon_caret-down"}})],1)],1),(_vm.sortedItems.length)?_c('div',{staticClass:"sb_journal_block_items"},_vm._l((_vm.sortedItems),function(item){return _c('div',{key:("" + (item.id)),staticClass:"sb_journal_block_item",style:({
          gridTemplateColumns: _vm.showTimestamps ? '80px 1fr' : '1fr',
        }),attrs:{"type":_vm.getItemType(item)}},[(_vm.showTimestamps)?_c('div',{staticClass:"sb_journal_block_item_time"},[_vm._v(" "+_vm._s(_vm.getTime(item.createdAt))+" ")]):_vm._e(),_c('div',{staticClass:"sb_journal_block_item_content"},[_c('div',{staticClass:"sb_journal_block_item_content_inner"},[_c('div',{staticClass:"sb_journal_block_item_content_title"},[(_vm.$user.role === 'COACH')?[_c('span',{class:{ link: _vm.getItemLocation(item) },on:{"click":function($event){_vm.getItemLocation(item)
                      ? _vm.$router.push(_vm.getItemLocation(item))
                      : undefined}}},[_vm._v(" "+_vm._s(_vm.getItemTitle(item))+" ")])]:[_c('span',[_vm._v(" "+_vm._s(_vm.getItemTitle(item))+" ")])]],2),_c('sb-journal-content-list',{attrs:{"journal-entry":item},on:{"comments-click":function($event){return _vm.showCommentsForId($event)}}})],1),_c('div',{staticClass:"sb_journal_block_item_comment-button-container"},[_c('div',{on:{"click":function($event){return _vm.showCommentsForId(item.id)}}},[_c('sb-icon',{attrs:{"icon-id":"icon_messages","scale":".8"}})],1),_c('div',{staticClass:"text-red",on:{"click":function($event){return _vm.confirmDeleteItem(item.id)}}},[_c('sb-icon',{attrs:{"icon-id":"icon_bin","scale":".8"}})],1)])])])}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }