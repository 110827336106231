export const CognitiveMapColumn = Object.freeze({
  READING_NOW: 'READING_NOW',
  TARGET: 'TARGET',
  DOING: 'DOING',
  READING_AFTER: 'READING_AFTER',
});

/**
 * Get a human readable label for the corresponding `CognitiveMapColumn` member.
 * @param {string} type
 * @returns {string} card label
 */
export function getCognitiveMapColumnLabel(type) {
  if (!CognitiveMapColumn[type]) {
    throw new Error(`Got unknown cognitive map column type ${type}`);
  }

  switch (type) {
    case CognitiveMapColumn.READING_NOW:
      return 'Zo lees ik';

    case CognitiveMapColumn.TARGET:
      return 'Dit proberen we te bereiken';

    case CognitiveMapColumn.DOING:
      return 'Dit gaan we doen';

    case CognitiveMapColumn.READING_AFTER:
      return 'Zo lees ik nu';
  }
}

/**
 * Get the related image's uri for the corresponding `CognitiveMapColumn` member.
 * @param {string} type
 * @returns {string} image url
 */
export function getCognitiveMapColumnImageSource(type) {
  if (!CognitiveMapColumn[type]) {
    throw new Error(`Got unknown cognitive map column type ${type}`);
  }

  switch (type) {
    case CognitiveMapColumn.READING_NOW:
      return '/static/img/cognition/book.png';

    case CognitiveMapColumn.TARGET:
      return '/static/img/cognition/target.png';

    case CognitiveMapColumn.DOING:
      return '/static/img/cognition/direction.png';

    case CognitiveMapColumn.READING_AFTER:
      return '/static/img/cognition/goal.png';
  }
}

export const cognitiveMapColumnMixin = {
  data: () => ({ CognitiveMapColumn }),
  methods: { getCognitiveMapColumnLabel, getCognitiveMapColumnImageSource },
};
