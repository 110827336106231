<template>
  <div>
    <sb-divider title="Score voortgang" />

    <sb-student-progress-graph :student-id="$route.params.studentId" />

    <sb-divider title="Sessies &amp; Kaarten" />

    <sb-student-cards-progress-graph
      v-if="trackIds"
      :track-ids="trackIds"
      :student-id="$route.params.studentId"
    />

    <sb-spacer height="400" />

    <!-- <sb-divider title="Mijn stickers" /> -->
  </div>
</template>

<script>
import SbStudentProgressGraph from '@/components/SbStudentProgressGraph.vue';
import SbStudentCardsProgressGraph from '@/components/SbStudentCardsProgressGraph.vue';
import gql from 'graphql-tag';
import { GraphQL } from '@/lib/graphql';

export default {
  components: { SbStudentProgressGraph, SbStudentCardsProgressGraph },

  apollo: {
    trackIds: {
      variables: { first: GraphQL.MAX_SAFE_INTEGER },

      update(data) {
        return data.getUserById.tracks.edges.map(({ node }) => node.id);
      },

      query() {
        return gql`query StudentProgress_GetTrackIds($first: Int) {
          getUserById(id: "${this.$route.params.studentId}") {
            id
            tracks(first: $first) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }`;
      },
    },
  },
};
</script>

<style></style>
