<template>
  <span class="sb-icon-badge" :style="`font-size: ${fontSize}`">
    <sb-icon
      class="icon"
      :icon-id="iconId"
      :style="`font-size: ${iconFontSize}; margin-right: 5px`"
    />
    {{ label }}
  </span>
</template>
<script>
export default {
  name: 'SbIconBadge',
  props: {
    iconId: { type: String, default: 'icon_clock' },
    label: { type: String, default: '' },
    fontSize: { type: String, default: '15px' },
    iconFontSize: { type: String, default: '15px' },
  },
};
</script>

<style lang="scss" scoped>
.sb-icon-badge {
  padding: 4px 10px 2px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid $brand-gray;
  font-size: larger;
  color: $brand-gray;
  width: fit-content;
}
</style>
