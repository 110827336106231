var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('sb-journal-filters',{on:{"print":_vm.handlePrint},model:{value:(_vm.journalFilters),callback:function ($$v) {_vm.journalFilters=$$v},expression:"journalFilters"}}),(
      _vm.studentTrackIds &&
      !_vm.studentTrackIds.length &&
      !_vm.$apollo.queries.studentTrackIds.loading &&
      !_vm.$apollo.queries.trackJournalLookup.loading
    )?_c('sb-call-out',[_c('template',{slot:"left"},[_vm._v(" ℹ️ ")]),_c('template',{slot:"right"},[_vm._v(" In de geselecteerde periode is er geen journaal om weer te geven. ")])],2):_vm._e(),_c('p',{staticClass:"from-to"},[_vm._v(" Studentjournaal "),_c('b',[_vm._v(_vm._s(_vm.get(_vm.student(), 'fullName')))]),_vm._v(" van "+_vm._s(_vm.journalFilters.fromDate.toLocaleDateString())+" tot "+_vm._s(_vm.journalFilters.toDate.toLocaleDateString())+" ")]),(_vm.activeTrackIds.length || _vm.passiveTrackIds.length)?_c('tabs',{staticClass:"journal-tabs",attrs:{"animated":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('tab-pane',{attrs:{"label":"Begeleiding","name":"track-dependent"}},[_vm._l(([_vm.activeTrackIds, _vm.passiveTrackIds]),function(ids,index){return [(index === 0 && _vm.activeTrackIds.length)?_c('div',{key:index,staticClass:"active-indicator"},[_vm._v(" Actief traject ")]):_vm._e(),(index === 1 && _vm.passiveTrackIds.length)?_c('div',{key:index,staticClass:"active-indicator"},[_vm._v(" Non-actieve trajecten ")]):_vm._e(),_vm._l((ids),function(id){return _c('div',{key:id,staticClass:"track-journal-block"},[(_vm.printMode ? !!_vm.journalByTrackId(id) : true)?_c('sb-divider',{staticClass:"block",attrs:{"top-space":"10px","top-padding":"20px","title":[
                'Traject',
                !!_vm.trackLookup[id].readingLevel &&
                  ("- " + (_vm.trackLookup[id].readingLevel)),
                !!_vm.trackLookup[id].startDate &&
                  _vm.journalFilters.doShowTimestamps &&
                  ("- startdatum " + (new Date(
                    _vm.trackLookup[id].startDate
                  ).toLocaleDateString())) ]
                .filter(Boolean)
                .join(' ')},nativeOn:{"click":function($event){_vm.toggledTrackIds.includes(id)
                ? (_vm.toggledTrackIds = [])
                : (_vm.toggledTrackIds = [id]);
              !_vm.selectedTrackIds.includes(id) && _vm.selectedTrackIds.push(id);}},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('sb-icon',{staticClass:"icon",attrs:{"icon-id":_vm.toggledTrackIds.includes(id)
                    ? 'icon_caret-up'
                    : 'icon_caret-down'}})]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"right-container"},[(_vm.trackLookup[id].active)?_c('sb-icon-badge',{attrs:{"icon-id":"icon_clock","label":_vm.Format.minutesToHourMinutes(_vm.getTotalTrackTime(id))}}):_c('span',{staticClass:"sb-track-status-table-label",class:{
                    s_active: _vm.trackLookup[id].active,
                    s_inactive: !_vm.trackLookup[id].active,
                    s_closed:
                      _vm.trackLookup[id].active && !!_vm.trackLookup[id].finishedAt,
                  }},[(!_vm.trackLookup[id].finishedAt)?_c('span',[_vm._v(" "+_vm._s(_vm.trackLookup[id].active ? 'Actief' : 'Inactief')+" ")]):_c('span',[_vm._v(" Voltooid ")])])],1)]},proxy:true}],null,true)}):_vm._e(),_c('div',{style:({
              position: 'relative',
              overflow: 'hidden',
              paddingLeft: '5px',
              minHeight:
                _vm.toggledTrackIds.includes(id) &&
                _vm.$apollo.queries.trackJournalLookup.loading
                  ? '100px'
                  : undefined,
            })},[[(_vm.journalFilters.doShowSimplified)?_c('journal-table',{attrs:{"items":_vm.journalByTrackId(id)}}):_c('sb-journal-accumulator',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggledTrackIds.includes(id)),expression:"toggledTrackIds.includes(id)"}],attrs:{"show-timestamps":_vm.journalFilters.doShowTimestamps,"data":_vm.journalByTrackId(id)},on:{"on-delete-item":_vm.refresh,"on-comment-created":_vm.refresh}})],(_vm.$apollo.queries.trackJournalLookup.loading)?_c('sb-loading',{attrs:{"position":"absolute","width":"100%","height":"100%","delay":50}}):_vm._e()],2)],1)})]})],2),_c('tab-pane',{attrs:{"label":"Zelfstandig geoefend","name":"track-independent"}},[_c('div',{staticStyle:{"padding-left":"5px","position":"relative"}},[[(_vm.journalFilters.doShowSimplified)?_c('journal-table',{attrs:{"items":_vm.independentJournal}}):_c('sb-journal-accumulator',{attrs:{"data":_vm.independentJournal},on:{"on-delete-item":_vm.refresh}})]],2)])],1):_vm._e(),_c('sb-spacer',{attrs:{"height":"60"}}),(_vm.printMode)?_c('div',{staticClass:"print-overlay"},[_c('i-button',{attrs:{"type":"primary","size":"large","disabled":_vm.loadingPrintContent},on:{"click":_vm.print}},[_vm._v(" "+_vm._s(_vm.loadingPrintContent ? 'Voorbereiden...' : 'Journaal afdrukken')+" ")])],1):_vm._e(),(
      _vm.$apollo.queries.studentTrackIds.loading ||
      _vm.$apollo.queries.trackJournalLookup.loading ||
      _vm.$apollo.queries.independentJournal.loading
    )?_c('sb-loading',{attrs:{"width":"100%","height":"100%","position":"absolute"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }