<template>
  <div>
    <i-select
      style="width: 300px"
      :value="customInput ? OTHER : value"
      @input="handleSelectInput"
    >
      <i-option
        v-for="item in MISC_INTAKE_TYPES"
        :key="item.label"
        :value="item.value"
      >
        {{ item.label }}
      </i-option>
    </i-select>

    <template v-if="customInput">
      <sb-spacer height="20" />
      <i-input
        :value="value"
        placeholder="Welke toets is afgenomen?"
        @input="$emit('input', $event)"
      />
    </template>
  </div>
</template>

<script>
import SbSpacer from './global/SbSpacer.vue';
const OTHER = 'OTHER';
const MISC_INTAKE_TYPES = [
  {
    value: 'IEP toetsen',
    label: 'IEP toetsen',
  },
  {
    value: 'DIA taal',
    label: 'DIA taal',
  },
  {
    value: 'Boom',
    label: 'Boom',
  },
  {
    value: OTHER,
    label: 'Anders, namelijk:',
  },
];

export default {
  components: { SbSpacer },
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return { MISC_INTAKE_TYPES, OTHER, customInput: this.value === OTHER };
  },

  beforeCreate() {
    console.log(this.$options.propsData);
  },

  methods: {
    handleSelectInput(value) {
      if (value === OTHER) {
        this.customInput = true;
        this.$emit('input', '');
      } else if (this.MISC_INTAKE_TYPES.find((type) => type.value === value)) {
        this.customInput = false;
        this.$emit('input', value);
      }
    },
  },
};
</script>
