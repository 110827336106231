<template>
  <div class="outer">
    <div
      ref="container"
      class="container"
      :class="{ toggled: toggled && items.length > 4 }"
      @mouseenter="toggled = true"
      @mouseleave="toggled = false"
    >
      <sb-technique-modal v-model="showModal" :technique="modalData" />

      <button
        v-for="technique in _items"
        :key="technique.id"
        :style="toggled ? undefined : { pointerEvents: 'none' }"
        type="button"
        @click.stop="handleButtonClick(technique)"
      >
        <sb-button size="small">{{ technique.number }}</sb-button>
      </button>
      <button
        v-if="!toggled && items.length > 4"
        :style="{ pointerEvents: 'none' }"
        type="button"
      >
        <sb-button button-type="light" size="small">
          +{{ items.length - 3 }}
        </sb-button>
      </button>
    </div>
  </div>
</template>

<script>
import SbTechniqueModal from '@/components/SbTechniqueModal.vue';
import { sortTechniquesMixin } from '@/lib/sort-techniques';

export default {
  name: 'SbProblemsInputTechniques',

  components: { SbTechniqueModal },

  mixins: [sortTechniquesMixin],

  props: {
    items: { type: Array, default: () => [] },
  },

  data() {
    return {
      modalData: undefined,
      showModal: false,
      toggled: false,
    };
  },

  computed: {
    _items() {
      return this.sortTechniques(
        this.toggled
          ? [
              ...this.items,
              // ...this.items.map((i) => ({ ...i, id: i.id.repeat(2) })),
            ]
          : this.items.slice(0, this.items.length <= 4 ? 4 : 3),
      );
    },
  },

  mounted() {
    window.addEventListener('click', this.clickAway);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.clickAway);
  },

  methods: {
    handleButtonClick(technique) {
      this.$emit('click-technique', technique);
      if (!this.toggled) return;
      this.modalData = technique;
      this.showModal = true;
      this.toggled = false;
    },

    clickAway(event) {
      if (!this.toggled) return;
      if (!event.composedPath().includes(this.$refs.container)) {
        this.toggled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outer {
  position: relative;
  width: 100%;
  height: 58px;
}

.container {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0.6rem 0.6rem;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: background-color 0.3s, border-color 0.3s;

  &.toggled {
    z-index: 2;
    border: 1px solid #dcdee2;
    background: $brand-white;
    height: auto;
    width: 229px;
    overflow: auto;
    max-height: 186px;
    padding-bottom: 0.3rem;
  }
}
</style>
