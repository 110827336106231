import { getCognitiveMapColumnLabel } from '@/lib/cognitive-map-column';
import { getDmtCardTypeLabel } from '@/lib/dmt-card-type';
import { JournalEvent } from '@/lib/journal-event';
import { getAviCardLevelLabel } from '@/lib/avi-card-level';

export function createContentList(
  changes,
  creator,
  evaluate = true,
  debugLabel,
) {
  try {
    return Boolean(evaluate) ? creator(changes).filter(Boolean) : false;
  } catch (error) {
    console.warn({ debugLabel, changes, creator, evaluate });
    console.warn('Could not create content list:', error);
    return false;
  }
}

export function getContent(event, changes) {
  switch (event) {
    case JournalEvent.SESSION_START:
      return createContentList(changes, (changes) => [
        { text: `Taak: ${changes.problem?.title ?? 'onbekend'}` },
        changes.technique
          ? {
              text: `Techniek: ${changes.technique.number} ${changes.technique.title}`,
            }
          : undefined,
      ]);

    case JournalEvent.SESSION_APPLIED_TECHNIQUE:
      return createContentList(changes, (changes) => [
        changes.appliedTechnique
          ? {
              text: `${changes.appliedTechnique.number} ${changes.appliedTechnique.title}`,
            }
          : undefined,
      ]);

    case JournalEvent.SESSION_APPLIED_TYPE:
      return createContentList(changes, (changes) => [
        { text: changes.appliedType },
      ]);

    case JournalEvent.COGNITION_MAP_ENTRY_ADDED:
    case JournalEvent.COGNITION_MAP_ENTRY_UPDATED:
    case JournalEvent.SESSION_COGNITION_MAP_ENTRY_ADDED:
    case JournalEvent.SESSION_COGNITION_MAP_ENTRY_UPDATED:
      if (!changes.column) return false;
      return createContentList(changes, (changes) => [
        {
          text: [
            getCognitiveMapColumnLabel(changes.column),
            `"${changes.text}"`,
          ].join(': '),
        },
      ]);

    case JournalEvent.CARD_EXERCISE_START:
      return createContentList(changes, (changes) => [
        {
          text: [
            'Kaart',
            [changes.card?.number, changes.card?.title]
              .filter(Boolean)
              .join(' - ') || 'Kaart onbekend',
          ].join(': '),
        },
        { text: ['Niveau', changes.readingLevel].join(': ') },
      ]);

    case JournalEvent.CARD_EXERCISE_REMARK:
      return createContentList(changes, (changes) => [
        {
          text: changes.remark ? `"${changes.remark}"` : 'Geen opmerkingen',
        },
      ]);

    case JournalEvent.CARD_EXERCISE_READING_COMPREHENSION_ANSWERS:
      return createContentList(changes, (changes) => {
        return (
          changes.upsert.map((e) => ({
            meta: { liStyle: { display: 'flex' } },
            text: `<div style="white-space: normal; word-break: normal;">${[
              e.question,
              `<p class="row-2"><i>~ "${e.answer}"</i></p>`,
            ].join('\n')}</div>`,
          })) ?? [{ text: 'nvt' }]
        );
      });

    case JournalEvent.CARD_EXERCISE_READING_LEVEL:
      return createContentList(changes, (changes) => [
        { text: `Niveau ${changes.readingLevel}` },
      ]);

    case JournalEvent.SESSION_END:
      return createContentList(changes, (changes) => [
        {
          items: [{ text: `"${changes.howDidItGo?.trim() ?? '-'}"` }],
          text: 'Hoe ging het?',
        },
        {
          items: [{ text: `"${changes.whatWentWell?.trim() ?? '-'}"` }],
          text: 'Wat ging er goed?',
        },
        {
          items: [{ text: `"${changes.whatWentWrong?.trim() ?? '-'}"` }],
          text: 'Wat ging er minder goed?',
        },
        {
          items: [{ text: `"${changes.whatNeedsAttention?.trim() ?? '-'}"` }],
          text: 'Waar moet je de volgende keer extra aan denken?',
        },
      ]);

    case JournalEvent.TRACK_INTAKE_CREATED:
      return createContentList(changes, (changes) => [
        { text: `Afnemer: ${changes.testSupervisor}` },
      ]);

    case JournalEvent.TRACK_AVI_RESULT:
      return createContentList(changes, (changes) =>
        changes.create.map((result) => ({
          text: getAviCardLevelLabel(result.level),
          items: [
            { text: `Aantal fout: ${result.mistakesMade}` },
            { text: `Norm fout: ${result.mistakesNorm}` },
            result.number && { text: `Variant: ${result.number}` },
          ].filter(Boolean),
        })),
      );

    case JournalEvent.TRACK_DMT_RESULT:
      return createContentList(changes, (changes) =>
        changes.create.map((result) => ({
          text: `${getDmtCardTypeLabel(result.type)} - ${
            result.amountOfWords
          } gelezen, ${result.amountOfErrors} fout`,
        })),
      );

    case JournalEvent.TRACK_PROBLEM_FOCUS:
      const createContent = (result, deleted = false) => {
        const title = result.problemTitle ?? 'Generiek probleem';
        const text = [
          `${deleted ? 'Verwijderd: ' : ''}${title}`,
          deleted ? '' : `${result.mistakeAmount ?? 0} fouten`,
        ]
          .filter(Boolean)
          .join(', ');
        return { text };
      };

      return createContentList(changes, (changes) =>
        changes.problems.create
          .map((e, index) => ({
            ...e,
            problemTitle: changes.problemTitles?.[index],
          }))
          .map((e) => createContent(e))
          .sort((a, b) => a.order - b.order)
          .concat(
            changes.problems.deleteMany
              ?.map((e, index) => ({
                ...e,
                problemTitle: changes.problemTitles?.[index],
              }))
              .map((e) => createContent(e, true)),
          )
          .filter(Boolean),
      );

    case JournalEvent.TRACK_CREATED:
    case JournalEvent.SESSION_PROBLEM_FOCUS:
    case JournalEvent.CARD_EXERCISE_END:
    case JournalEvent.TRACK_INTAKE_CREATED:
    case JournalEvent.TRACK_MISC_RESULT:
    case JournalEvent.TRACK_READING_LEVEL:
    case JournalEvent.TRACK_REMOVED:
    case JournalEvent.COACH_COMMENT:
      return false;
    default:
      return false;
  }
}
