<template>
  <div>
    <sb-student-settings-form
      v-model="studentSettings"
      :default-data="studentSettings"
      :promise="promise"
      @submit="submit"
    />
    <sb-spacer height="100" />
  </div>
</template>

<script>
import SbStudentSettingsForm from '@/components/SbStudentSettingsForm/SbStudentSettingsForm.vue';
import gql from 'graphql-tag';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import { objectOmit } from '@/lib/object-omit';

export default {
  name: 'StudentSettings',

  components: {
    SbStudentSettingsForm,
  },

  mixins: [changeLoggerMixin(['studentSettings'])],

  data: () => ({
    studentSettings: undefined,
    promise: undefined,
  }),

  methods: {
    async submit() {
      const studentId = this.$route.params.studentId;
      try {
        await (this.promise = this.$apollo.mutate({
          mutation: gql`
            mutation StudentSettings_UpdateUser($input: UpdateUserInput!) {
              ${
                this.$user.role === 'MANAGER'
                  ? `updateStudentAccessToSessions(value: ${this.studentSettings.hasAccessToSessions}, studentId: "${studentId}") {
                      id
                    }`
                  : ''
              }
              updateUser(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              id: studentId,
              data: {
                cardSuggestionsEnabled: this.studentSettings
                  .cardSuggestionsEnabled,
                cardSettings: objectOmit(
                  this.studentSettings.cardSettings,
                  '__typename',
                ),
              },
            },
          },
        }));
      } catch (error) {
        console.error(error);
        this.$Modal.error({
          title: 'Er ging iets mis',
          content: 'Probeer het later nog eens.',
        });
      }
    },
  },

  apollo: {
    getUserById: {
      query: gql`
        query getUserById($id: ID!) {
          getUserById(id: $id) {
            id
            cardSuggestionsEnabled
            hasAccessToSessions
            cardSettings {
              fontType
              fontSize
              letterSpacing
              lineHeight
              wordSpacing
              cursorSpeed
              focusMode
              showIllustrations
              lineNumbers
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.studentId };
      },
      update(data) {
        const {
          hasAccessToSessions,
          cardSettings,
          cardSuggestionsEnabled,
        } = data.getUserById;

        this.studentSettings = {
          hasAccessToSessions,
          cardSettings,
          cardSuggestionsEnabled,
        };
      },
    },
  },
};
</script>

<style></style>
