<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Leerling toevoegen</h1>
      </template>
    </sb-page-header>

    <sb-student-form
      ref="form"
      create
      default-mode="edit"
      :role="Role.STUDENT"
      :organisation-id="parentId"
    />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import { getResourceId } from '@/lib/user-context-manager';
import SbStudentForm from '@/components/SbStudentForm/SbStudentForm.vue';
import { roleMixin } from '@/lib/role';
import { mapActions } from 'vuex';

export default {
  components: {
    SbStudentForm,
    SbPageHeader,
    SbStudentForm,
  },

  mixins: [roleMixin],

  props: {
    organisationId: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
  },

  computed: {
    editMode() {
      return !!this.data;
    },

    parentId() {
      return this.organisationId || this.resourceId || getResourceId();
    },
  },

  mounted() {
    const currentTourKey = 'MANAGER_ADD_STUDENT';
    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
  },
};
</script>

<style lang="scss"></style>
