<template>
  <div>
    <sb-max-width max-width="600">
      <p>
        De volgende punten zijn op urgentie gesorteerd voor extra aandacht. Pas
        de volgorde naar eigen inzicht aan.
      </p>
    </sb-max-width>

    <sb-spacer height="40" />

    <sb-title text="Sorteer focuspunten" size="small" />

    <sb-spacer height="20" />

    <p v-if="value.length === 0">Geen problematieken toegevoegd.</p>

    <sb-list-head
      v-if="value.length"
      style="grid-template-columns: 60px auto 150px 30%"
    >
      <sb-list-head-col style="grid-column: 1 / span 2">
        Problematiek
      </sb-list-head-col>
      <sb-list-head-col style="text-align: center">
        Aantal fouten
      </sb-list-head-col>
      <sb-list-head-col> Technieken </sb-list-head-col>
    </sb-list-head>

    <sb-list-row
      v-for="(problemEntry, index) in value"
      :key="problemEntry.problemId"
      :style="{
        gridTemplateColumns: '40px 60px auto 150px 30%',
        zIndex: value.length - index,
        position: 'relative',
      }"
    >
      <sb-list-row-col>
        <button
          :v-if="!readonly"
          type="button"
          @click="swapProblemEntryPositions(index, index - 1)"
        >
          <sb-button
            :class="{ 'sort-arrow-disabled': index === 0 }"
            size="small"
            naked
          >
            <sb-icon icon-id="icon_arrow-up" />
          </sb-button>
        </button>
      </sb-list-row-col>

      <sb-list-row-col>
        <button
          :v-if="!readonly"
          type="button"
          @click="swapProblemEntryPositions(index, index + 1)"
        >
          <sb-button
            :class="{
              'sort-arrow-disabled': index === value.length - 1,
            }"
            size="small"
            naked
          >
            <sb-icon icon-id="icon_arrow-down" />
          </sb-button>
        </button>
      </sb-list-row-col>

      <sb-list-row-col>
        {{ get(getProblemById(problemEntry.problemId), 'title') }}
      </sb-list-row-col>

      <sb-list-row-col style="text-align: center">
        {{ problemEntry.mistakeAmount }}
      </sb-list-row-col>

      <sb-list-row-col>
        <sb-problems-input-techniques
          :items="getLinkedTechniquesbyProblemId(problemEntry.problemId)"
        />
      </sb-list-row-col>
    </sb-list-row>
  </div>
</template>

<script>
import { problemsMixin } from '@/mixins/problemsMixin';
import SbListRowCol from '@/components/SbListRowCol.vue';
import SbListRow from '@/components/SbListRow.vue';
import SbListHead from '@/components/SbListHead.vue';
import SbListHeadCol from '@/components/SbListHeadCol.vue';
import SbProblemsInputTechniques from '@/components/SbProblemsInputTechniques.vue';

export default {
  components: {
    SbListRowCol,
    SbListRow,
    SbListHead,
    SbListHeadCol,
    SbProblemsInputTechniques,
  },
  mixins: [problemsMixin],

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    readonly: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    swapProblemEntryPositions(currentIndex, swapIndex) {
      if (swapIndex < 0 || swapIndex >= this.value.length) return;
      const arr = this.value.slice();
      const a = arr[swapIndex];
      arr[swapIndex] = arr[currentIndex];
      arr[currentIndex] = a;
      this.$emit(
        'input',
        arr.map((entry, index) => ((entry.order = index), entry)),
      );
    },
  },
};
</script>
