<template>
  <sb-base-form-layout v-bind="$attrs" v-on="$listeners">
    <template v-slot:default>
      <template v-if="$user.role === 'MANAGER'">
        <sb-divider title="Sessies" />

        <form-item label="Ouder toegang">
          <sb-info>
            <p>
              De ouder/verzorger krijgt toegang tot het inzien van sessies met
              de begeleider.
            </p>
          </sb-info>
          <i-switch
            :value="Boolean(formData.hasAccessToSessions)"
            @input="formData.hasAccessToSessions = $event"
          >
            <span slot="open">Ja</span>
            <span slot="close">Nee</span>
          </i-switch>
        </form-item>
      </template>

      <sb-divider title="Suggesties" />
      <form-item label="Kaart suggesties tonen" prop="cardSuggestionsEnabled">
        <i-switch v-model="formData.cardSuggestionsEnabled">
          <span slot="open">Ja</span>
          <span slot="close">Nee</span>
        </i-switch>
      </form-item>

      <sb-divider title="Kaarten" />

      <form-item label="Toon illustraties">
        <i-switch
          :value="Boolean(formData.cardSettings.showIllustrations)"
          @input="formData.cardSettings.showIllustrations = $event"
        >
          <span slot="open">Ja</span>
          <span slot="close">Nee</span>
        </i-switch>
      </form-item>

      <form-item label="Toon regelnummers">
        <i-switch
          :value="Boolean(formData.cardSettings.lineNumbers)"
          @input="formData.cardSettings.lineNumbers = $event"
        >
          <span slot="open">Ja</span>
          <span slot="close">Nee</span>
        </i-switch>
      </form-item>

      <form-item label="Lettertype" class="lettertype">
        <sb-font-select v-model="formData.cardSettings.fontType" />
      </form-item>

      <form-item label="Lettergrootte">
        <sb-student-settings-quantity-select
          v-model="formData.cardSettings.fontSize"
          type="fontSize"
          placeholder="Lettergrootte"
          :min="0"
          :max="1"
          :step="CARD_SETTINGS_RULES.fontSize.step"
          :options-amount="CARD_SETTINGS_RULES.fontSize.optionsAmount"
        />
      </form-item>

      <form-item label="Letterafstand">
        <sb-student-settings-quantity-select
          v-model="formData.cardSettings.letterSpacing"
          placeholder="Letterafstand"
          :min="0"
          :step="CARD_SETTINGS_RULES.letterSpacing.step"
          :options-amount="CARD_SETTINGS_RULES.letterSpacing.optionsAmount"
        />
      </form-item>

      <form-item label="Regelafstand">
        <sb-student-settings-quantity-select
          v-model="formData.cardSettings.lineHeight"
          placeholder="Regelafstand"
          :min="1"
          :step="CARD_SETTINGS_RULES.lineHeight.step"
          :options-amount="CARD_SETTINGS_RULES.lineHeight.optionsAmount"
        />
      </form-item>

      <form-item label="Woordafstand">
        <sb-student-settings-quantity-select
          v-model="formData.cardSettings.wordSpacing"
          placeholder="Woordafstand"
          :step="CARD_SETTINGS_RULES.wordSpacing.step"
          :options-amount="CARD_SETTINGS_RULES.wordSpacing.optionsAmount"
        />
      </form-item>
    </template>
  </sb-base-form-layout>
</template>

<script>
import { fontTypeMixin } from '@/lib/font-type';
import { formDataMixin } from '@/mixins/formDataMixin';
import SbDivider from '@/components/SbDivider.vue';
import SbFontSelect from '@/components/SbFontSelect.vue';
import SbStudentSettingsQuantitySelect from './SbStudentSettingsQuantitySelect.vue';
import SbBaseFormLayout from '../SbBaseFormLayout.vue';
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';

export default {
  components: {
    SbDivider,
    SbStudentSettingsQuantitySelect,
    SbBaseFormLayout,
    SbFontSelect,
  },

  mixins: [
    cardSettingsRulesMixin,
    fontTypeMixin,
    formDataMixin({
      cardSuggestionsEnabled: undefined,
      cardSettings: {
        fontType: undefined,
        fontSize: undefined,
        letterSpacing: undefined,
        lineHeight: undefined,
        wordSpacing: undefined,
        cursorSpeed: undefined,
        focusMode: undefined,
        showIllustrations: undefined,
        lineNumbers: undefined,
      },
    }),
  ],

  watch: {
    'formData.cardSettings.lineNumbers'(value) {
      this.$store.state.doShowLineNumbers = Boolean(value);
    },
  },
};
</script>

<style lang="scss">
.lettertype {
  .ivu-select-dropdown {
    max-height: 292px !important;
  }
}
</style>
