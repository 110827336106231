const baseValidators = {
  ...standardRequiredFields(['version', 'testDate', 'testSupervisor']),
};

export const dmtValidators = {
  ...baseValidators,
  ...standardRequiredFields(['zelfredzaamheid', 'levels']),
  results: [
    {
      required: true,
      // message: 'Selecteer kaarten en vul de testresultaten in.',
      type: 'array',
      min: 1,
      trigger: 'blur',
      validator(_, value, callback) {
        if (value.length >= 1 && !!value[0].type) {
          callback();
        } else {
          callback(
            new Error(
              `Geen resultaten ingevoerd voor ${value
                .slice(0, 1)
                .map((result, index) =>
                  !result.type ? `kaart ${index + 1}` : false,
                )
                .filter(Boolean)
                .join(', ')}.`,
            ),
          );
        }
      },
    },
  ],
};

export const aviValidators = {
  ...baseValidators,
  ...standardRequiredFields(['levels']),
  'results.0.levels': [{ required: true, message: 'Verplicht veld' }],
  'results.0.timeSpent': [{ required: true, message: 'Verplicht veld' }],
  'results.0.timeNorm': [{ required: true, message: 'Verplicht veld' }],
  'results.0.mistakesMade': [{ required: true, message: 'Verplicht veld' }],
  'results.0.mistakesNorm': [{ required: true, message: 'Verplicht veld' }],
};

export const miscValidators = {
  ...baseValidators,
  ...standardRequiredFields(['type']),
};

// === utils ===

function standardRequiredFields(fields) {
  return fields.reduce(
    (acc, key) => ((acc[key] = [standardRequiredRule()]), acc),
    {},
  );
}

function standardRequiredRule() {
  return {
    required: true,
    message: 'Dit is een verplicht veld.',
    trigger: 'blur',
  };
}
