<template>
  <div>
    <!-- <pre>{{ currentAviIntakeData }}</pre> -->
    <sb-loading
      v-if="
        $apollo.queries.getTrackById.loading ||
        $apollo.queries.defaultIntakeType.loading
      "
    />

    <modal
      v-model="showReadingLevelModal"
      :closable="false"
      :mask-closable="false"
      width="800"
      footer-hide
    >
      <sb-max-width max-width="600" center>
        <sb-spacer height="40" />
        <sb-title
          text="Welk niveau wil je toewijzen aan dit traject?"
          size="regular"
        />

        <sb-spacer height="10" />

        <sb-reading-level-select
          v-if="self[activeIntake]"
          v-model="self[activeIntake].levels"
          optional
        />

        <sb-spacer height="20" />

        <div class="modal-button-container">
          <button @click="showReadingLevelModal = false">
            <sb-button button-type="light"> Terug </sb-button>
          </button>

          <button @click="updateTrackLevel">
            <sb-button> Volgende </sb-button>
          </button>
        </div>
        <sb-spacer height="40" />
      </sb-max-width>
    </modal>

    <modal
      v-model="showAddAnotherModal"
      :closable="false"
      :mask-closable="false"
      width="800"
      footer-hide
    >
      <sb-max-width max-width="600" center>
        <sb-spacer height="40" />
        <sb-title
          text="Wil je nog een testresultaat toevoegen?"
          size="regular"
        />
        <p></p>
        <sb-spacer height="20" />
        <button style="margin: 0 10px 0 0" @click="resetAll">
          <sb-button ghost> Voeg nog een toetsresultaat toe </sb-button>
        </button>
        <button @click="goToAddDiagnosis">
          <sb-button> Volgende </sb-button>
        </button>
        <sb-spacer height="40" />
      </sb-max-width>
    </modal>

    <sb-call-out v-if="$route.query.newTrack">
      <template slot="left"> ℹ️ </template>
      <template slot="right">
        Wil je nog geen toetsresultaten invullen? Je kan deze stap overslaan.
      </template>
    </sb-call-out>

    <sb-spacer height="40" />

    <i-form :label-width="160">
      <form-item id="TestTypeInput" label="Test type">
        <i-select v-model="activeIntake">
          <i-option
            v-for="intake in INTAKE_TYPES"
            :key="intake"
            :value="intake"
          >
            {{ getIntakeLabel(intake) }}
          </i-option>
        </i-select>
      </form-item>
      <sb-spacer height="20" />
    </i-form>

    <template v-for="intake in INTAKE_TYPES">
      <sb-student-intake-form
        v-show="activeIntake === intake"
        :ref="`form-${intake}`"
        :key="[activeIntake, intake, formKey].join('-')"
        v-model="self[intake]"
        :default-data="self[intake]"
        :track-id="$route.params.path"
        :intake-type="intake"
        :save-button-text="$route.query.origin ? 'Opslaan' : 'Volgende'"
        :promise="promise"
        :display-mode-on-submit="false"
        default-mode="edit"
        @cancel="handleCancel"
        @reset="resetForm(intake)"
        @submit="handleIntakeSubmit"
      >
        <template #skip const="$route.query.newTrack">
          <i-button
            id="SkipTrackButton"
            style="margin-right: 1rem"
            size="large"
            @click="
              showReadingLevelModal = true;
              didSkip = true;
            "
          >
            Overslaan
          </i-button>
        </template>
      </sb-student-intake-form>
    </template>
  </div>
</template>

<script>
import { readingLevelMixin } from '@/lib/reading-level';
import { zelfredzaamheidMixin } from '@/lib/zelfredzaamheid';
import { dmtCardTypeMixin } from '@/lib/dmt-card-type';
import SbCallOut from '@/components/SbCallOut';
import SbStudentIntakeForm from '@/components/SbStudentIntakeForm/SbStudentIntakeForm.vue';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import gql from 'graphql-tag';
import { objectOmit } from '@/lib/object-omit';
import SbReadingLevelSelect from '@/components/SbReadingLevelSelect.vue';
import SbLoading from '@/components/SbLoading.vue';
import { upperFirst } from '@/lib/upper-first';
import { TourStarter } from '@/shepherd/TourStarter';
import { mapActions } from 'vuex';
import { createTestResultInput } from '@/lib/utils/CreateTestResultInput';

const INTAKE_TYPES = ['dmt', 'avi', 'misc'];
const INITIAL_STATE = {
  dmt: undefined,
  avi: undefined,
  misc: undefined,
  activeIntake: 'dmt',
  showAddAnotherModal: false,
};

export default {
  name: 'AddTestResult',

  components: {
    SbStudentIntakeForm,
    SbReadingLevelSelect,
    SbCallOut,
    SbLoading,
    SbReadingLevelSelect,
  },

  mixins: [
    readingLevelMixin,
    zelfredzaamheidMixin,
    dmtCardTypeMixin,
    changeLoggerMixin(INTAKE_TYPES),
  ],

  props: {
    path: {
      type: String,
      default: undefined,
    },
  },

  data() {
    const hasAviResult = 'createIntakeAviTest' in this.$route.query;
    const hasDmtResult = 'createIntakeDmtTest' in this.$route.query;
    const hasMiscResult = 'createIntakeMiscTest' in this.$route.query;
    const resultCount = [hasAviResult, hasDmtResult, hasMiscResult].filter(
      Boolean,
    ).length;
    const fetchDefaultIntakeType = resultCount !== 1;
    let activeIntake = INITIAL_STATE.activeIntake;

    if (hasAviResult && !hasDmtResult && !hasMiscResult) {
      activeIntake = 'avi';
    } else if (hasDmtResult && !hasAviResult && !hasMiscResult) {
      activeIntake = 'dmt';
    } else if (hasMiscResult && !hasAviResult && !hasDmtResult) {
      activeIntake = 'misc';
    }

    return {
      ...INITIAL_STATE,
      INTAKE_TYPES,
      activeIntake,
      fetchDefaultIntakeType,
      formKey: Date.now(),
      showReadingLevelModal: false,
      showAddAnotherModal: false,
      promise: undefined,
      defaultIntakeLoading: false,
      trackByIdLoading: false,
      didSkip: false,
    };
  },

  computed: {
    self() {
      return this;
    },

    loading() {
      return [
        this.$apollo.queries.getTrackById.loading,
        this.$apollo.queries.defaultIntakeType.loading,
      ].some((e) => e === true);
    },

    trackReadingLevels() {
      return this[this.activeIntake]?.levels;
    },

    ...INTAKE_TYPES.reduce((acc, type) => {
      const name = upperFirst(type);
      acc[`current${name}IntakeData`] = function () {
        const resultId = this.$route.query[`createIntake${name}Test`];
        return resultId
          ? this.getTrackById?.[`intake${name}Tests`]?.find(
              (e) => e.id === resultId,
            )
          : undefined;
      };
      return acc;
    }, {}),
  },

  watch: {
    ...INTAKE_TYPES.reduce((acc, type) => {
      const name = upperFirst(type);
      acc[`current${name}IntakeData`] = function (state) {
        if (!this[type]) return;

        Object.entries(state).forEach(([key, value]) => {
          this[type][key] = value;
        });

        this.formKey = Date.now();
      };
      return acc;
    }, {}),
  },

  mounted() {
    const currentTourKey = TourStarter.getTourKeyForRole(this.$user.role, [
      'COACH_RESULTS',
    ]);

    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
    getLocalStorageIntakeKey(key) {
      return ['intake', key, this.$route.params.path].join('_');
    },

    getFormByIntake(type = this.activeIntake) {
      const refName = `form-${type}`;
      const form = this.$refs[refName]?.[0];
      if (!form) throw new Error(`Could not find form ${refName}.`);
      return form;
    },

    getIntakeLabel(intakeType) {
      switch (intakeType) {
        case 'dmt':
        case 'avi':
          return intakeType.toUpperCase();
        case 'misc':
          return 'Overig';
      }
    },

    setActiveTab(tab) {
      this.activeIntake = tab;
    },

    handleIntakeSubmit() {
      this.createIntake();
    },

    async createIntake() {
      const queryOrigin = this.$route.query.origin;
      const intakeType = this.activeIntake;
      const form = this.getFormByIntake(intakeType);
      const valid = await form.form.validate();

      if (!valid) {
        return this.$Modal.warning({
          title: `Incomplete invoer voor ${this.getIntakeLabel(intakeType)}`,
          content: 'De intake is niet volledig ingevuld.',
        });
      }

      /**
       * Now only valid forms remain, create intakes. Intake test results are added after.
       */

      this.$store.state.loading = true;

      try {
        const typeName = intakeType[0].toUpperCase() + intakeType.slice(1);
        const createQueryName = `createIntake${typeName}Test`;
        const createTypeName = `CreateIntake${typeName}TestInput`;

        let createdIntakeId = this.$route.query[createQueryName];

        if (!this[intakeType]?.levels) {
          // Fallback for when undefined
          this[intakeType].levels = [];
        }

        const trackId = this.path;

        const input = createTestResultInput(
          trackId,
          typeName,
          this[intakeType],
        );

        if (!createdIntakeId) {
          // create intake test result
          const {
            data: intakeData,
            errors: intakeErrors,
          } = await this.$apollo.mutate({
            mutation: gql`mutation ${createQueryName}($input: ${createTypeName}!) {
            ${createQueryName}(input: $input) { id }
          }`,
            variables: {
              input,
            },
          });

          if (intakeErrors) {
            throw new Error('Create intake failed.');
          }

          createdIntakeId = intakeData[createQueryName].id;
        }

        this.$router
          .replace({
            path: this.$route.path,
            query: { ...this.$route.query, [createQueryName]: createdIntakeId },
          })
          .catch(() => console.warn('duplicate nav'));

        // append test results
        const updateQueryName = `updateIntake${typeName}Test`;
        const updateTypeName = `UpdateIntake${typeName}TestInput`;
        let createdResultIds = this.$route.query[updateQueryName]?.split(',');

        const create = (intakeType === 'dmt'
          ? this[intakeType].results.filter((result) => !!result.type)
          : this[intakeType].results
        )?.map((result) => objectOmit(result, 'id', '__typename'));

        const remove =
          createdResultIds && createdResultIds.length
            ? createdResultIds
            : undefined;

        const {
          data: testResultData,
          errors: testResultErrors,
        } = await this.$apollo.mutate({
          mutation: gql`mutation ${updateQueryName}($input: ${updateTypeName}!) {
            ${updateQueryName}(input: $input) {
              id
              ${
                intakeType !== 'misc'
                  ? `results {
                      id
                    }`
                  : ''
              }
            }
          }`,
          variables: {
            input: {
              id: createdIntakeId,
              data: {
                results: intakeType === 'misc' ? undefined : { create, remove },
              },
            },
          },
        });

        if (testResultErrors) {
          throw new Error('Add test results failed');
        }

        createdResultIds = testResultData[updateQueryName]?.results?.map(
          ({ id }) => id,
        );

        if (createdResultIds) {
          this.$router
            .replace({
              path: this.$route.path,
              query: {
                ...this.$route.query,
                [updateQueryName]: createdResultIds.join(','),
              },
            })
            .catch(() => console.warn('duplicate nav'));
        }

        this.$store.state.loading = false;

        if (queryOrigin) {
          return this.$router.push({
            name: queryOrigin,
            query: { refetch: true },
          });
        }

        !!this.getTrackById?.readingLevels?.length
          ? (this.showAddAnotherModal = true)
          : (this.showReadingLevelModal = true);
      } catch (error) {
        console.log(error);
        this.$store.state.loading = false;
        this.$Modal.error({
          title: 'Er ging iets mis',
          content: 'Probeer het later opnieuw.',
        });
      }
    },

    resetForm(type) {
      this.getFormByIntake(type).reset?.();
      this[type] = undefined;
    },

    resetAll() {
      Object.entries(INITIAL_STATE).forEach(([key, value]) => {
        this[key] = value;
      });

      INTAKE_TYPES.forEach((type) => this.resetForm(type));
    },

    async handleCancel() {
      const queryOrigin = this.$route.query.origin;

      if (!queryOrigin) {
        this.$apollo.mutate({
          mutation: gql`
            mutation deleteTrack($id: ID!) {
              deleteTrack(id: $id)
            }
          `,
          variables: { id: this.path },
        });
      }

      this.$router.back();
    },

    updateTrackLevel() {
      if (this.trackReadingLevels) {
        this.$apollo.mutate({
          mutation: gql`
            mutation updateTrack($input: UpdateTrackInput!) {
              updateTrack(input: $input) {
                id
                readingLevels
              }
            }
          `,
          variables: {
            input: {
              id: this.path,
              data: { readingLevels: this.trackReadingLevels },
            },
          },
        });
      }

      if (this.didSkip) {
        this.goToAddDiagnosis();
      } else {
        this.showAddAnotherModal = true;
        this.showReadingLevelModal = false;
      }
    },

    goToAddDiagnosis() {
      this.showAddAnotherModal = false;
      this.showReadingLevelModal = false;
      setTimeout(
        () =>
          this.$router.push({
            name: 'ResourceManagementStudentsStudentPathAddDiagnose',
            query: { ...this.$route.query },
          }),
        300,
      );
    },
  },

  apollo: {
    getTrackById: {
      skip() {
        return !this.$route.params.path;
      },

      query() {
        const doFetchAviTests = 'createIntakeAviTest' in this.$route.query;
        const doFetchDmtTests = 'createIntakeDmtTest' in this.$route.query;
        const doFetchMiscTests = 'createIntakeMiscTest' in this.$route.query;

        const intakeAviFragment = doFetchAviTests
          ? `intakeAviTests {
              id
              testDate
              testSupervisor
              remark
              levels
              results {
                id
                number
                timeSpent
                timeNorm
                mistakesMade
                mistakesNorm
                level
              }
            }`
          : '';

        const intakeDmtFragment = doFetchDmtTests
          ? `intakeDmtTests {
              id
              testDate
              version
              testSupervisor
              remark
              zelfredzaamheid
              levels
              results {
                id
                type
                amountOfWords
                amountOfErrors
              }
            }`
          : '';

        const intakeMiscFragment = doFetchMiscTests
          ? `intakeMiscTests {
              id
              testDate
              testSupervisor
              remark
              type
            }`
          : '';

        return gql`query AddTestResult_GetTrackById {
          getTrackById(id: "${this.$route.params.path}") {
            id
            readingLevels
            ${intakeAviFragment}
            ${intakeDmtFragment}
            ${intakeMiscFragment}
          }
        }`;
      },
    },

    defaultIntakeType: {
      skip() {
        return (
          !this.fetchDefaultIntakeType ||
          !(
            this.$route.params.resourceType === 'school' &&
            !!this.$route.params.resourceId
          )
        );
      },

      query() {
        return gql`query GetDefaultIntakeType {
          getOrganisationDefaultIntakeType(id: "${this.$route.params.resourceId}")
        }`;
      },

      update(data) {
        this.fetchDefaultIntakeType = false;
        this.activeIntake =
          data.getOrganisationDefaultIntakeType?.toLowerCase() ??
          this.activeIntake;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-button-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, min-content);
}
</style>
