import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';

export const JournalEvent = Object.freeze({
  SESSION_START: 'SESSION_START',
  SESSION_END: 'SESSION_END',
  SESSION_PROBLEM_FOCUS: 'SESSION_PROBLEM_FOCUS',
  SESSION_APPLIED_TECHNIQUE: 'SESSION_APPLIED_TECHNIQUE',
  SESSION_APPLIED_TYPE: 'SESSION_APPLIED_TYPE',
  SESSION_COGNITION_MAP_ENTRY_ADDED: 'SESSION_COGNITION_MAP_ENTRY_ADDED',
  SESSION_COGNITION_MAP_ENTRY_UPDATED: 'SESSION_COGNITION_MAP_ENTRY_UPDATED',
  CARD_EXERCISE_START: 'CARD_EXERCISE_START',
  CARD_EXERCISE_END: 'CARD_EXERCISE_END',
  CARD_EXERCISE_READING_COMPREHENSION_ANSWERS:
    'CARD_EXERCISE_READING_COMPREHENSION_ANSWERS',
  CARD_EXERCISE_READING_LEVEL: 'CARD_EXERCISE_READING_LEVEL',
  CARD_EXERCISE_REMARK: 'CARD_EXERCISE_REMARK',
  TRACK_PROBLEM_FOCUS: 'TRACK_PROBLEM_FOCUS',
  TRACK_DMT_RESULT: 'TRACK_DMT_RESULT',
  TRACK_AVI_RESULT: 'TRACK_AVI_RESULT',
  TRACK_MISC_RESULT: 'TRACK_MISC_RESULT',
  TRACK_READING_LEVEL: 'TRACK_READING_LEVEL',
  TRACK_INTAKE_CREATED: 'TRACK_INTAKE_CREATED',
  TRACK_CREATED: 'TRACK_CREATED',
  TRACK_REMOVED: 'TRACK_REMOVED',
  TRACK_FINISHED: 'TRACK_FINISHED',
  COGNITION_MAP_ENTRY_ADDED: 'COGNITION_MAP_ENTRY_ADDED',
  COGNITION_MAP_ENTRY_UPDATED: 'COGNITION_MAP_ENTRY_UPDATED',
  COACH_COMMENT: 'COACH_COMMENT',
});

/**
 * Get a human readable label for the corresponding `JournalEvent` member.
 * @param {string} type
 * @returns {string} label
 */
export function getJournalEventLabel(type) {
  switch (type) {
    case JournalEvent.SESSION_START:
      return 'Sessie start';
    case JournalEvent.SESSION_END:
      return 'Sessie einde';
    case JournalEvent.SESSION_PROBLEM_FOCUS:
      return 'Sessie focus problematiek';
    case JournalEvent.SESSION_APPLIED_TECHNIQUE:
      return 'Techniek bekeken';
    case JournalEvent.SESSION_APPLIED_TYPE:
      return 'Toepassen';
    case JournalEvent.CARD_EXERCISE_START:
      return 'Oefenkaart start';
    case JournalEvent.CARD_EXERCISE_END:
      return 'Oefenkaart einde';
    case JournalEvent.CARD_EXERCISE_READING_COMPREHENSION_ANSWERS:
      return 'Begrijpend lezen';
    case JournalEvent.CARD_EXERCISE_READING_LEVEL:
      return 'Oefenkaart niveau';
    case JournalEvent.CARD_EXERCISE_REMARK:
      return 'Oefenkaart opmerking';
    case JournalEvent.TRACK_PROBLEM_FOCUS:
      return 'Traject focus problematiek';
    case JournalEvent.TRACK_DMT_RESULT:
      return 'DMT intake-resultaat';
    case JournalEvent.TRACK_AVI_RESULT:
      return 'AVI intake-resultaat';
    case JournalEvent.TRACK_MISC_RESULT:
      return 'Overig intake-resultaat';
    case JournalEvent.TRACK_READING_LEVEL:
      return 'Oefenniveau';
    case JournalEvent.TRACK_INTAKE_CREATED:
      return 'Intaketoets voltooid';
    case JournalEvent.TRACK_CREATED:
      return 'Behandelingsplan opgesteld';
    case JournalEvent.TRACK_REMOVED:
      return 'Traject afgesloten';
    case JournalEvent.TRACK_FINISHED:
      return 'Traject voltooid';
    case JournalEvent.COGNITION_MAP_ENTRY_ADDED:
    case JournalEvent.SESSION_COGNITION_MAP_ENTRY_ADDED:
      return 'Cognitieve benadering toegevoegd';
    case JournalEvent.COGNITION_MAP_ENTRY_UPDATED:
    case JournalEvent.SESSION_COGNITION_MAP_ENTRY_UPDATED:
      return 'Cognitieve benadering gewijzigd';
    case JournalEvent.COACH_COMMENT:
      return 'Opmerking coach';
    default:
      throw new Error(`Unknown type ${type}`);
  }
}

export const journalEventMixin = Object.freeze({
  mixins: [nonReactiveMembersMixin(() => ({ JournalEvent }))],
  methods: { getJournalEventLabel },
});
