<template>
  <div
    :class="{
      comment: true,
      'new-comment': isNew,
      'own-comment': isOwn,
    }"
  >
    <div class="comment-header">
      <span class="name">
        {{ get(comment, 'createdBy', 'fullName') }}
      </span>
      <span class="time">{{ getTimeStamp(comment) }}</span>
    </div>
    <div class="comment-body">
      {{ comment.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbJournalComment',
  props: {
    comment: { type: Object, required: true },
    isNew: { type: Boolean, required: false },
    isOwn: { type: Boolean, required: false },
  },

  methods: {
    getTimeStamp({ createdAt }) {
      if (!createdAt) return '';
      const date = new Date(createdAt);
      return [date.toLocaleDateString(), date.toLocaleTimeString()].join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
  margin: 10px 0;
  padding: 0.5em 1em;
  border-radius: 4px;

  &.new-comment {
    animation-duration: 4s;
    animation-name: highlight;
    @keyframes highlight {
      from {
        box-shadow: inset 0 0 0 4px $brand-primary;
      }
      to {
        box-shadow: inset 0 0 0 4px rgba($brand-primary, 0);
      }
    }
  }

  &.own-comment {
    .comment-header {
      .name {
        font-weight: bold;
      }
    }
  }

  &:nth-child(odd) {
    background: $brand-lightest-gray;
  }

  .comment-header {
    margin: 10px 0;

    .name {
      color: $brand-primary;
    }

    .time {
      margin-left: 1em;
      color: $brand-light-gray;
    }
  }

  .comment-body {
    margin: 10px 0;
    line-height: 1.4em;
    white-space: pre-line;
  }
}
</style>
