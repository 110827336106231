<template>
  <div class="journal-filters options">
    <div class="time-switch">
      <div class="div">
        <span>Tijden:</span>
        <i-switch
          :value="value.doShowTimestamps"
          @input="$emit('input', { ...value, doShowTimestamps: $event })"
        >
          <span slot="open">Ja</span>
          <span slot="close">Nee</span>
        </i-switch>
      </div>

      <div class="time-switch_display">
        <span>Vereenvoudigde weergave:</span>
        <i-switch
          :value="value.doShowSimplified"
          @input="$emit('input', { ...value, doShowSimplified: $event })"
        >
          <span slot="open">Ja</span>
          <span slot="close">Nee</span>
        </i-switch>
      </div>
    </div>

    <sb-from-to-input
      :default-period="90"
      :value="value"
      @input="$emit('input', { ...value, ...$event })"
    />

    <i-input
      search
      enter-button
      :style="{ maxWidth: '180px' }"
      @input="$emit('input', { ...value, search: $event })"
    />

    <i-button type="text" class="print-button" @click="$emit('print')">
      <sb-icon icon-id="icon_print" />
    </i-button>
  </div>
</template>

<script>
import SbFromToInput from '@/components/SbFromToInput.vue';

export default {
  name: 'SbJournalFilters',

  components: { SbFromToInput },

  props: {
    value: {
      type: Object,
      default: function () {
        return { doShowTimestamps: true, doShowSimplified: false };
      },
      validator: function (value) {
        return (
          'doShowTimestamps' in value &&
          'doShowSimplified' in value &&
          'fromDate' in value &&
          'toDate' in value &&
          'search' in value
        );
      },
    },
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  margin: 40px 0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .time-switch {
    display: flex;
    margin: 20px 0;
    flex: 1;

    span {
      margin-right: 10px;
    }

    &_display {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  .from-to-input {
    margin-right: 1rem;
  }

  .print-button {
    font-size: 1.2rem;

    svg {
      transform: translateY(2px);
    }
  }

  @media print {
    display: none !important;
  }
}
</style>
