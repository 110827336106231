<template>
  <div>
    <sb-loading v-if="loading" />

    <input
      ref="fileInput"
      :accept="ALLOWED_CSV_MIME_TYPES.join(',')"
      type="file"
      hidden
      @input="
        file = undefined;
        file = $event.target.files[0];
      "
    />

    <modal
      v-model="showModal"
      title="Inloggegevens versturen?"
      :width="600"
      :loading="loading"
      @on-ok="upload"
      @on-cancel="
        file = undefined;
        showModal = false;
      "
    >
      <checkbox v-model="sendLoginInstructions">
        Ja, verstuur logingegevens naar de leerlingen.
      </checkbox>
    </modal>

    <sb-page-header>
      <template v-slot:left>
        <h1>Leerlingen importeren uit CSV of XLSX</h1>
      </template>
    </sb-page-header>

    <template v-if="completed">
      <sb-call-out>
        <template v-slot:left> 👉 </template>
        <template v-slot:right>
          Succes! Alle informatie van de nieuwe leerlingen voldoet aan de
          richtlijnen. De gebruikers worden nu aangemaakt, als het aanmaakproces
          voltooid is ontvang je een e-mail.
        </template>
      </sb-call-out>

      <sb-spacer height="20" />
      <div class="flex">
        <router-link :to="{ name: 'ResourceManagementStudents' }">
          <sb-button button-type="primary" size="regular">
            Naar het leerlingenoverzicht
          </sb-button>
        </router-link>

        <button @click="completed = false">
          <sb-button button-type="light" size="regular">
            Meer importeren
          </sb-button>
        </button>
      </div>
    </template>

    <template v-else>
      <sb-divider title="Kies of sleep een bestand" />
      <div
        id="StudentBulkImportDropZone"
        class="drop-zone"
        @dragenter="handleDragEnter"
        @dragover="handleDragOver"
        @dragleave="handleDragLeave"
        @drop="handleDrop"
        @click="handleDropZoneClick"
      >
        <template v-if="!file">
          <sb-icon
            icon-id="icon_arrow-download"
            style="font-size: 3rem; display: block"
          />
          <div class="text">
            Klik om een bestand te kiezen, of sleep het bestand in dit vak
          </div>
        </template>
        <div v-else class="text">
          {{ file.name }}
        </div>
      </div>

      <sb-spacer height="20" />

      <sb-call-out>
        <template v-slot:left> 👉 </template>
        <template v-slot:right>
          Let op, het bestand moet aan de richtlijnen van de documentatie
          voldoen. We verwachten dat je hier een bestand uploadt dat vanuit een
          geschikt template is opgemaakt en is opgeslagen in .xlsx of .csv
          format. Dit template kan je hieronder downloaden.
          <sb-spacer height="20" />
          <div>
            <i-button
              id="StudentBulkImportDownloadButton"
              @click="downloadCsvTemplate"
            >
              Download Excel Template
            </i-button>
          </div>
        </template>
      </sb-call-out>
    </template>

    <!-- <tabs :animated="false" @on-click="handleTabChange">
      <tab-pane label="Standaard">
        <div
          class="drop-zone"
          @dragenter="handleDragEnter"
          @dragover="handleDragOver"
          @dragleave="handleDragLeave"
          @drop="handleDrop"
          @click="handleDropZoneClick"
        >
          <template v-if="!file">
            <sb-icon
              icon-id="icon_arrow-download"
              style="font-size: 3rem; display: block"
            />
            <div class="text">
              Klik om een bestand te kiezen, of sleep het bestand in dit vak
            </div>
          </template>
          <div v-else class="text">
            {{ file.name }}
          </div>
        </div>

        <sb-spacer height="20" />

        <sb-call-out>
          <template v-slot:left> 👉 </template>
          <template v-slot:right>
            Let op, het bestand moet aan de richtlijnen van de documentatie
            voldoen. We verwachten dat je hier een bestand uploadt dat vanuit
            een geschikt template is opgemaakt en is opgeslagen in .xlsx of .csv
            format. Dit template kan je hieronder downloaden.
            <sb-spacer height="20" />
            <div>
              <i-button @click="downloadCsvTemplate">
                Download Excel Template
              </i-button>
            </div>
          </template>
        </sb-call-out>
      </tab-pane>

      <tab-pane label="ParnasSys">
        <div
          class="drop-zone"
          @dragenter="handleDragEnter"
          @dragover="handleDragOver"
          @dragleave="handleDragLeave"
          @drop="handleDrop"
          @click="handleDropZoneClick"
        >
          <template v-if="!file">
            <sb-icon
              icon-id="icon_arrow-download"
              style="font-size: 3rem; display: block"
            />
            <div class="text">
              Klik om een bestand te kiezen, of sleep het bestand in dit vak
            </div>
          </template>
          <div v-else class="text">
            {{ file.name }}
          </div>
        </div>

        <sb-spacer height="20" />

        <sb-call-out>
          <template v-slot:left> 👉 </template>
          <template v-slot:right>
            Let op, het bestand moet aan de richtlijnen van ParnasSys voldoen.
            We verwachten dat je hier een bestand uploadt dat vanuit ParnasSys
            geëxporteerd is, in .xlsx format.
          </template>
        </sb-call-out>
      </tab-pane>
    </tabs> -->

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import { getResourceId } from '@/lib/user-context-manager';
import { roleMixin } from '@/lib/role';
import SbDivider from '@/components/SbDivider.vue';
import SbIcon from '@/components/global/SbIcon.vue';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import SbCallOut from '@/components/SbCallOut.vue';
import SbLoading from '@/components/SbLoading.vue';
import { mapActions } from 'vuex';

const ALLOWED_CSV_MIME_TYPES = [
  'text/plain',
  'text/x-csv',
  'application/vnd.ms-excel',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export default {
  components: {
    SbPageHeader,
    SbDivider,
    SbIcon,
    SbCallOut,
    SbLoading,
  },

  mixins: [
    roleMixin,
    nonReactiveMembersMixin(() => ({
      ALLOWED_CSV_MIME_TYPES,
      apiUrl: process.env.VUE_APP_GRAPHQL_HTTP.split('/')
        .filter(Boolean)
        .slice(0, -1) // remove graphql
        .join('/'),
    })),
  ],

  props: {
    organisationId: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    file: undefined,
    sendLoginInstructions: true,
    loading: false,
    showModal: false,
    mode: 'standard',
    completed: false,
  }),

  computed: {
    editMode() {
      return !!this.data;
    },

    parentId() {
      return this.organisationId || this.resourceId || getResourceId();
    },
  },

  watch: {
    file(value) {
      if (value) this.showModal = true;
    },
  },

  mounted() {
    const currentTourKey = 'MANAGER_IMPORT_STUDENT';
    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),

    navigateToStudents() {
      this.$router.push({
        name: '"ResourceManagementStudents"',
        params: {
          organisationId: this.organisationId,
          resourceId: this.resourceId,
        },
      });
    },

    handleTabChange(index) {
      switch (index) {
        case 0:
          return (this.mode = 'standard');
        case 1:
          return (this.mode = 'parnassys');
      }
    },

    async downloadCsvTemplate() {
      const templateUrl = [this.apiUrl, 'student-import', 'template'].join('/');

      try {
        const response = await fetch(templateUrl, {
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('apollo-token'),
          },
        });

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.onclick = function () {
          this.remove();
        };

        a.href = url;
        a.style.display = 'none';
        a.download = 'leeshelden-leerlingen-import-template.xlsx';
        document.body.append(a);
        a.click();
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },

    async upload() {
      if (!this.file) return this.$showGenericError();

      const path = 'student-import/parnas-sys';
      // const path = this.mode === 'standard'
      //   ? 'student-import'
      //   : 'student-import/parnas-sys';

      const body = new FormData();
      body.append('file', this.file);
      body.append('schoolId', this.$route.params.resourceId);
      body.append('sendLoginInstructions', this.sendLoginInstructions);

      try {
        this.loading = true;
        const result = await fetch([this.apiUrl, path].join('/'), {
          body,
          method: 'POST',
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
          },
        });

        const data = await result.json();

        if (data.error) {
          const limit = 8;
          const errors = [data.message].map((row) => {
            const properties = new Set();
            let name;
            let message;

            try {
              for (let _error of row) {
                for (let error of Array.isArray(_error) ? _error : [error]) {
                  const { target: student, property } = error;
                  name = [
                    [student?.firstName, student?.prefix, student?.lastName]
                      .filter(Boolean)
                      .join(' ') || 'Naam onbekend',
                    student?.studentNumber,
                  ]
                    .filter(Boolean)
                    .join(', ');
                  properties.add(property);
                }
              }

              message = [
                name,
                Array.from(properties)
                  .map((p) => this.translateProperty(p))
                  .join(', '),
              ].join(': ');
            } catch (error) {
              message = 'Foutieve data';
            }

            return message;
          });

          return this.$Modal.error({
            title: 'Foutieve data',
            content: [
              'Controleer de kolommen van deze leerlingen:',
              errors.length > limit
                ? [
                    errors.slice(0, limit).join(' \n'),
                    `En nog ${errors.length - limit} andere(n)...`,
                  ].join(' \n\n')
                : errors,
            ].join(' \n\n'),
            width: 600,
          });
        }

        this.$Modal.success({ title: 'Succes!', content: data.message });
        this.completed = true;
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      } finally {
        this.loading = false;
        this.file = undefined;
      }
    },

    handleDropZoneClick() {
      this.$refs.fileInput.click();
    },

    handleDragEnter(event) {
      event.currentTarget?.classList.add('hover');
    },

    handleDragOver(event) {
      event.preventDefault();
    },

    handleDragLeave(event) {
      event.currentTarget?.classList.remove('hover');
    },

    handleDrop(event) {
      event.preventDefault();
      event.currentTarget.classList.remove('hover');
      const file = event.dataTransfer.files[0];

      if (!this.ALLOWED_CSV_MIME_TYPES.includes(file.type)) {
        return this.$Modal.error({
          title: 'Incorrect bestandstype',
          content: 'Gebruik een Excel of CSV bestand.',
        });
      }

      this.file = file;
    },

    translateProperty(key) {
      return (
        {
          username: 'Gebruikersnaam',
          email: 'E-mailadres',
          firstName: 'Voornaam',
          prefix: 'Tussenvoegsel',
          lastName: 'Achternaam',
          birthDate: 'Geboortedatum',
          gender: 'Geslacht',
          studentNumber: 'Leerlingnummber',
          classYear: 'School groep',
        }[key] || key
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-zone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 220px;
  border: 1px dashed $brand-primary;
  border-radius: 4px;
  margin: 0 auto;
  transition-duration: 0.3s;
  cursor: pointer;

  & > * {
    pointer-events: none;
  }

  &.hover,
  &:hover {
    color: $brand-primary;
    transition-duration: 0s;
  }

  .text {
    margin: 1rem;
  }
}

.flex {
  gap: 10px;
}
</style>
