import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';

export const ProblemType = Object.freeze({
  NA_AFNAME: 'NA_AFNAME',
  KWALITEITSPROBLEMEN: 'KWALITEITSPROBLEMEN',
  DECODEERPROBLEMEN: 'DECODEERPROBLEMEN',
  ALGEMEEN: 'ALGEMEEN',
});

export const problemTypeMixin = {
  mixins: [nonReactiveMembersMixin(() => ({ ProblemType }))],
  methods: { getProblemTypeLabel },
};

/**
 * Get a human readable label for the corresponding `ProblemType` member.
 * @param {string} type
 * @returns {string} problem type label
 */
export function getProblemTypeLabel(type, upperFirst = false) {
  if (!type || type === 'undefined') return upperFirst ? 'Overig' : 'overig';
  if (!ProblemType[type]) throw new Error(`Unkown problem type: ${type}`);
  const label = type.split('_').join(' ').toLowerCase();
  return upperFirst ? label[0].toUpperCase() + label.slice(1) : label;
}
