import { objectOmit } from '@/lib/object-omit';

export function createTestResultInput(trackId, intakeTypeName, data) {
  const typeName = intakeTypeName?.toLowerCase();

  console.log('data', data);

  if (typeName !== 'dmt' && typeName !== 'avi' && typeName !== 'misc') {
    throw new Error(`Invalid intake type: ${typeName}`);
  }

  if (data == null || data === undefined) {
    throw new Error(`Invalid data: ${data}`);
  }

  if (typeName === 'misc') {
    // Misc doesn't need levels
    return {
      trackId,
      ...objectOmit(data, 'results', 'levels'),
    };
  }

  return {
    trackId,
    ...objectOmit(data, 'results'),
  };
}
