export function sortTechniques(techniques = []) {
  const result = techniques.slice();

  result.sort((a, b) => {
    const numA = Number(a.number.split('.')[1] ?? 0);
    const numB = Number(b.number.split('.')[1] ?? 0);
    if (numA > numB) return 1;
    if (numA < numB) return -1;
    return 0;
  });

  result.sort((a, b) => {
    const numA = Number(a.number.split('.')[0] ?? 0);
    const numB = Number(b.number.split('.')[0] ?? 0);
    if (numA > numB) return 1;
    if (numA < numB) return -1;
    return 0;
  });

  return result;
}

export const sortTechniquesMixin = {
  methods: { sortTechniques },
};
