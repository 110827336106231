<template>
  <div>
    <template v-if="active">
      <sb-spacer height="40" />

      <div class="title-container">
        <div class="icon-arrow-left" @click="active = undefined">
          <sb-icon icon-id="icon_arrow-left" />
        </div>

        <sb-title
          :text="`Toetsresultaten ${active ? '-' : ''} ${
            getTestLabelByTypename(get(active, '__typename')) || ''
          } ${get(active, 'level') || ''}`"
          size="small"
        />
      </div>

      <sb-spacer height="20" />
    </template>

    <sb-divider v-else title="Overzicht toetsresultaten" />

    <template v-if="!active">
      <sb-table-2
        :config="tableConfig"
        :data="tableData"
        @change="handleTableData($event)"
        @cell-click-test-date="setActive"
      />
      <sb-spacer height="200" />
    </template>

    <template v-else>
      <sb-student-intake-form
        ref="studentIntakeForm"
        :key="`${active.id}-${activeKey}`"
        v-model="active"
        :intake-type="getIntakeType(active.__typename)"
        :default-data="active"
        :mode="mode"
        :promise="promise"
        @reset="reset"
        @submit="handleSubmit"
      />
    </template>

    <sb-bottom-fixed-buttons v-if="!active">
      <template #right>
        <i-button
          size="large"
          type="primary"
          @click="
            $router.push({
              name: 'ResourceManagementStudentsStudentPathAddTestResult',
              query: {
                origin: 'ResourceManagementStudentsStudentPathTestResults',
              },
            })
          "
        >
          Resultaat toevoegen
        </i-button>
      </template>
    </sb-bottom-fixed-buttons>
  </div>
</template>

<script>
import { changeLoggerMixin } from '@/mixins/changeLogger';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { objectOmit } from '@/lib/object-omit';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import gql from 'graphql-tag';
import SbBottomFixedButtons from '@/components/SbBottomFixedButtons.vue';
import SbStudentIntakeForm from '@/components/SbStudentIntakeForm/SbStudentIntakeForm.vue';
import SbTable2 from '@/components/SbTable2/SbTable2.vue';

const DEFAULT_TEST_FIELDS = ['id', 'testDate', 'testSupervisor', 'remark'];

export default {
  components: { SbStudentIntakeForm, SbBottomFixedButtons, SbTable2 },

  mixins: [
    changeLoggerMixin(['getTrackById', 'active']),
    nonReactiveMembersMixin((self) => ({
      tableConfig: new TableConfig({
        columns: [
          {
            header: 'Datum afname',
            key: 'testDate',
            sortable: true,
            sort: 'descending',
            data: (row) => new Date(row.testDate).toLocaleDateString(),
            action: true,
          },
          {
            header: 'Type',
            key: '__typename',
            sortable: true,
            data: (row) => self.getTestLabelByTypename(row.__typename),
          },
          { header: 'Niveau', key: 'level', sortable: true },
          { header: 'Afgenomen door', key: 'testSupervisor', sortable: true },
          {
            header: 'Versie',
            key: 'version',
            sortable: true,
            data: (row) =>
              row.__typename === 'IntakeDmtTest' ? row.version : 'n.v.t',
          },
        ],
      }),
    })),
  ],

  data() {
    return {
      mode: 'display',
      promise: undefined,
      activeKey: Date.now(),
      active: undefined,
      activeInitialData: undefined,
      useActiveQueryParam: true,
      tableColumns: [
        {
          title: 'Datum afname',
          key: 'testDate',
          sortable: true,
          sortType: 'desc',
          render: (h, row) =>
            h('span', {}, new Date(row.row.testDate).toLocaleDateString()),
        },
        {
          title: 'Type',
          key: '__typename',
          sortable: true,
          render: (h, row) =>
            h('span', {}, this.getTestLabelByTypename(row.row.__typename)),
        },
        { title: 'Niveau', key: 'level', sortable: true },
        { title: 'Afgenomen door', key: 'testSupervisor', sortable: true },
        { title: 'Versie', key: 'version', sortable: true },
      ],
    };
  },

  computed: {
    tableData() {
      return [
        this.getTrackById?.intakeAviTests,
        this.getTrackById?.intakeDmtTests,
        this.getTrackById?.intakeMiscTests,
      ]
        .filter(Boolean)
        .flat()
        .sort((a, b) => new Date(a.testDate) - new Date(b.testDate));
    },
  },

  watch: {
    active(value, prevValue) {
      if (!!prevValue && !value) {
        this.$apollo.queries.getTrackById.refetch();
      }
    },
  },

  mounted() {
    if (this.$route.query.refetch) {
      this.$apollo.queries.getTrackById.refetch();
    }
  },

  methods: {
    handleTableData(data) {
      const activeId = this.$route.query.active;
      if (!this.useActiveQueryParam || !activeId) return;
      this.useActiveQueryParam = false;
      this.setActive(data.find((result) => result.id === activeId));
    },

    setActive(data) {
      this.activeInitialData = JSON.parse(JSON.stringify(data));
      this.active = data;
    },

    getTestLabelByTypename(typename) {
      {
        switch (typename) {
          case 'IntakeMiscTest':
            return 'Overig';
          case 'IntakeAviTest':
            return 'AVI';
          case 'IntakeDmtTest':
            return 'DMT';
        }
      }
    },

    async handleSubmit(event) {
      const typename =
        event.intakeType[0].toUpperCase() + event.intakeType.slice(1);
      const queryName = `updateIntake${typename}Test`;
      const inputTypeName = `UpdateIntake${typename}TestInput`;
      const intakeFieldName = `intake${typename}Test`;

      try {
        await (this.promise = this.$apollo.mutate({
          mutation: gql`mutation ${queryName}($input: ${inputTypeName}!) {
            ${queryName}(input: $input) { id }
          }`,
          variables: {
            input: {
              id: event.data.id,
              data: {
                ...objectOmit(event.data, 'results', 'id', '__typename'),
                ...(event.data.results
                  ? {
                      results: {
                        create: event.data.results
                          .slice(-3)
                          .map((result) =>
                            objectOmit(
                              result,
                              'id',
                              intakeFieldName,
                              '__typename',
                            ),
                          ),
                        remove: event.data.results.map((result) => result.id),
                      },
                    }
                  : undefined),
              },
            },
          },
        }));

        this.mode = 'display';
      } catch (error) {
        console.error(error);
        this.$Modal.error({
          title: 'Er ging iets mis',
          content: 'Probeer het later nog eens.',
        });
      }
    },

    getIntakeType(typename) {
      switch (typename) {
        case 'IntakeDmtTest':
          return 'dmt';
        case 'IntakeAviTest':
          return 'avi';
        case 'IntakeMiscTest':
          return 'misc';
      }
    },

    reset() {
      this.active = this.activeInitialData;
      this.activeKey = Date.now();
      this.$refs.studentIntakeForm?.reset();
    },
  },

  apollo: {
    getTrackById: {
      fetchPolicy: 'cache-and-network',

      query: gql`query TestResults_getTrackById($trackId: ID!) {
        getTrackById(id: $trackId) {
          id
          problems {
            id
            mistakeAmount
            order
            problem {
              id
              title
              types
              linkedTechniques {
                id
                title
                number
                content
              }
            }
          }
          intakeAviTests {
            ${DEFAULT_TEST_FIELDS.join(' ')}
            levels
            results {
              id
              number
              timeSpent
              timeNorm
              mistakesMade
              mistakesNorm
              level
            }
          }
          intakeDmtTests {
            ${DEFAULT_TEST_FIELDS.join(' ')}
            zelfredzaamheid
            levels
            results {
              id
              type
              amountOfWords
              amountOfErrors
              intakeDmtTest {
                id
              }
            }
          }
          intakeMiscTests {
            ${DEFAULT_TEST_FIELDS.join(' ')}
            type
          }
        }
      }`,
      variables() {
        return {
          trackId: this.$route.params.path,
          linkedTechniquesAmount: 999,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  cursor: pointer;

  &:hover {
    background: $brand-lightest-gray;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.title-container {
  display: flex;
}

.icon-arrow-left {
  padding: 0 20px;
  transform: translateY(2px);
  color: $brand-primary;
  display: inline-block;
  cursor: pointer;
}
</style>
