<template>
  <div class="container" :class="{ readonly }">
    <template v-if="!readonly">
      <div
        v-for="(header, index) in INPUT_HEADERS"
        :key="index"
        :style="`grid-area: head-${index}`"
      >
        {{ header }}
      </div>

      <div
        v-for="index in 3"
        :key="`input-${index}`"
        :style="`grid-area: card-input-${index - 1}`"
      >
        <radio-group
          v-model="getResult(index - 1).type"
          type="button"
          button-style="solid"
        >
          <radio
            v-for="level in getDmtCardTypesByLevel(index)"
            :key="level"
            :label="level"
          >
            {{ getDmtCardTypeLabel(level) }}
          </radio>
        </radio-group>
      </div>

      <div
        v-for="index in 3"
        :key="`amount-${index}`"
        :style="`grid-area: read-input-${index - 1}`"
      >
        <input-number
          type="number"
          class="number-input"
          :min="0"
          :step="1"
          :disabled="!getResult(index - 1).type"
          :value="getResult(index - 1).amountOfWords"
          @input="getResult(index - 1).amountOfWords = Number($event)"
        />
      </div>

      <div
        v-for="index in 3"
        :key="`mistakes-${index}`"
        :style="`grid-area: mistakes-input-${index - 1}`"
      >
        <input-number
          type="number"
          class="number-input"
          :step="1"
          :min="0"
          :max="getResult(index - 1).amountOfWords"
          :disabled="!getResult(index - 1).type"
          :value="getResult(index - 1).amountOfErrors"
          @input="getResult(index - 1).amountOfErrors = Number($event)"
        />
      </div>
    </template>

    <template v-else>
      <div class="grid-horizontal-border" />
      <div class="grid-horizontal-border" />

      <div
        v-for="index in 3"
        :key="`input-${index}`"
        :style="`grid-area: card-input-${index - 1}`"
        class="readonly-level"
      >
        {{
          getResult(index - 1).type
            ? getDmtCardTypeLabel(getResult(index - 1).type)
            : ''
        }}
      </div>

      <div
        v-for="index in 3"
        :key="`amount-${index}`"
        :style="`grid-area: read-input-${index - 1}`"
      >
        <span class="result-amount">{{
          getResult(index - 1).amountOfWords
        }}</span>
        <span class="result-label">{{ ' woorden' }}</span>
      </div>

      <div
        v-for="index in 3"
        :key="`mistakes-${index}`"
        :style="`grid-area: mistakes-input-${index - 1}`"
      >
        <span class="result-amount">
          {{ getResult(index - 1).amountOfErrors }}
        </span>
        <span class="result-label">{{ ' fouten' }}</span>
      </div>
    </template>

    <div class="sb_set-test-results_scores">
      <div class="sb_set-test-results_scores_rows">
        <div class="sb_set-test-results_scores_row">
          <div class="sb_set-test-results_scores_row_col">Totaal woorden:</div>
          <div class="sb_set-test-results_scores_row_col">
            {{ totalWordsAmount }}
          </div>
        </div>
        <div class="sb_set-test-results_scores_row">
          <div class="sb_set-test-results_scores_row_col">Totaal fout:</div>
          <div class="sb_set-test-results_scores_row_col">
            {{ totalMistakesAmount }}
          </div>
        </div>
      </div>
      <div class="sb_set-test-results_scores_total">
        <div class="sb_set-test-results_scores_total_col">Score:</div>
        <div class="sb_set-test-results_scores_total_col">
          {{ totalScore }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dmtCardTypeMixin } from '@/lib/dmt-card-type';

const INPUT_HEADERS = ['Kaart', 'Aantal gelezen', 'Aantal fouten'];
export const CREATE_DEFAULT_DMT_INTAKE_VALUE = () =>
  new Array(3).fill(undefined).map(() => ({
    type: undefined,
    amountOfWords: 0,
    amountOfErrors: 0,
  }));

export default {
  mixins: [dmtCardTypeMixin],

  props: {
    value: {
      type: Array,
      default: () => CREATE_DEFAULT_DMT_INTAKE_VALUE(),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { INPUT_HEADERS };
  },

  computed: {
    totalWordsAmount() {
      return this.value.reduce(
        (total, result) => ((total += result.amountOfWords), total),
        0,
      );
    },

    totalMistakesAmount() {
      return this.value.reduce(
        (total, result) => ((total += result.amountOfErrors), total),
        0,
      );
    },

    totalScore() {
      return this.totalWordsAmount - this.totalMistakesAmount;
    },
  },

  watch: {
    value: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    },
  },

  mounted() {
    if (!this.$options.propsData.value) {
      this.$emit('input', this.value);
    }
  },

  methods: {
    getResult(index) {
      if (!this.value[index]) {
        this.value[index] = {
          type: undefined,
          amountOfWords: 0,
          amountOfErrors: 0,
        };
      }

      return this.value[index];
    },

    reset() {
      this.$emit('input', CREATE_DEFAULT_DMT_INTAKE_VALUE());
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  align-items: center;
  position: relative;
  display: grid;
  grid-template-columns: max-content 140px 140px 418px;
  grid-template-rows: auto repeat(3, 50px);
  grid-gap: 10px 30px;
  grid-template-areas:
    'head-0 head-1 head-2 . '
    'card-input-0 read-input-0 mistakes-input-0 score'
    'card-input-1 read-input-1 mistakes-input-1 score'
    'card-input-2 read-input-2 mistakes-input-2 score';

  &.readonly {
    align-items: center;
  }
}

.number-input {
  width: 100%;
}

.radio-group {
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.sb_set-test-results_scores {
  background: rgba($brand-task-diagnose, 0.06);
  color: $brand-task-diagnose;
  padding: 2rem 2rem;
  border-radius: $default-border-radius;
  font-weight: $semibold;
  line-height: 1.1;
  grid-area: score;

  //
  // ROWS
  //
  &_rows {
  }

  //
  // ROW
  //
  &_row {
    display: grid;
    grid-template-columns: 1fr 100px;
    text-align: right;
    padding: 0.2rem 0;

    // ROW - COL
    &_col {
    }
  }
  //
  // TOTAL
  //
  &_total {
    display: grid;
    grid-template-columns: 1fr 100px;
    text-align: right;
    padding: 1rem 0 0 0;
    @include h2Regular;
  }
}

.readonly-level {
  width: 45px;
  height: 41px;
  font-weight: 600;
  background: $brand-primary;
  color: $brand-white;
  border-radius: $default-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.result {
  font-size: 18px;
  &-amount {
    font-weight: 600;
  }
  &-label {
    color: $brand-gray;
  }
}

.grid-horizontal-border {
  position: absolute;
  top: 65px;
  left: 0;
  width: 400px;
  height: 1px;
  background: $brand-light-gray;

  &:nth-child(2) {
    top: 125px;
  }
}
</style>
