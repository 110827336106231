import { cloneDeep } from 'lodash';

export function createDraggables(forceReset = false) {
  const self = this;
  const dragTarget = 'drag-target';
  const dropTarget = 'drop-target';
  const nonTarget = 'non-target';
  const container = self.$refs.container;
  const columns = self.$refs.columns;
  const scrollContainers = self.$refs.entryContainers;
  const newEntryButtons = container.getElementsByClassName(
    'sb_cognition_column_entries_new',
  );

  const resetTargets = [
    ...container.getElementsByClassName(dragTarget),
    ...container.getElementsByClassName(nonTarget),
  ];

  const columnDropTargets = columns.map((column) =>
    Array.from(column.getElementsByClassName(dropTarget)),
  );

  let sourceColumn;
  let sourceIndex;
  let dropColumn;
  let dropIndex;

  const dragTargetElements = Array.from(
    document.querySelectorAll(`.${dragTarget}`),
  );

  if (forceReset && this.draggables) {
    return this.draggables.forEach((draggable) => draggable.update());
  }

  this.draggables = Draggable.create(
    this.draggables
      ? dragTargetElements.filter(
          (el) =>
            !this.draggables.find((draggable) => {
              const isDraggable = draggable.target === el;
              if (isDraggable) draggable.update();
              return isDraggable;
            }),
        )
      : dragTargetElements,
    {
      // bounds: container,

      onDragStart() {
        self.$refs.container?.classList.add('dragging');
        scrollContainers.forEach((element) =>
          element.classList.add('dragging'),
        );
        columnDropTargets.forEach((targets) => {
          targets.forEach((target) => target.classList.add('dragging'));
          targets[targets.length - 1].classList.add('filler');
        });
        newEntryButtons.forEach((element) => element.classList.add('dragging'));
        this.target.classList.add('dragging');
        sourceColumn = this.target.dataset.column;
        sourceIndex = Number(this.target.dataset.index);
      },

      onDrag() {
        columns.forEach((column, index) => {
          const columnHit = this.hitTest(column, '50%');
          const dropTargets = columnDropTargets[index];

          if (!columnHit) {
            return dropTargets
              .map((sibling) => sibling.nextElementSibling)
              .filter((sibling) => !!sibling && sibling !== this.target)
              .forEach((sibling) => (sibling.style.transform = ''));
          }

          for (const [index, child] of dropTargets.entries()) {
            const childHit =
              child !== this.target.previousElementSibling &&
              child !== this.target.nextElementSibling &&
              this.hitTest(child);

            const siblings = dropTargets
              .slice(index)
              .map((sibling) => sibling.nextElementSibling)
              .filter((sibling) => sibling !== this.target);

            if (childHit && columnHit) {
              siblings.forEach((sibling) => {
                if (!sibling) return;
                sibling.style.transform = `translateY(${this.target.offsetHeight}px)`;
              });

              dropColumn = column.dataset.column;
              dropIndex = index;
              break;
            } else {
              dropColumn = undefined;
              dropIndex = undefined;
              siblings
                .filter((sibling) => !!sibling)
                .forEach((sibling) => (sibling.style.transform = ''));
            }
          }
        });

        requestAnimationFrame(() => createDraggables.call(self, true));
      },

      onDragEnd() {
        self.$refs.container?.classList.remove('dragging');

        columnDropTargets.forEach((targets) => {
          targets.forEach((target) => target.classList.remove('dragging'));
          targets[targets.length - 1].classList.remove('filler');
        });

        newEntryButtons.forEach((element) =>
          element.classList.remove('dragging'),
        );

        if ([dropColumn, dropIndex].includes(undefined)) {
          gsap.to(this.target, { x: 0, y: 0 });
        } else {
          const newEntry = JSON.parse(
            JSON.stringify(
              self.entries[self.activeStudent.id][sourceColumn][sourceIndex],
            ),
          );

          newEntry.column = dropColumn;

          if (sourceColumn === dropColumn) {
            const a =
              self.entries[self.activeStudent.id][sourceColumn][sourceIndex];
            const b =
              self.entries[self.activeStudent.id][sourceColumn][dropIndex];

            if (a) a.order = dropIndex;
            if (b) b.order = sourceIndex;

            self.entries[self.activeStudent.id][sourceColumn].sort(
              (a, b) => a.order - b.order,
            );
          } else {
            self.entries[self.activeStudent.id][dropColumn].splice(
              dropIndex,
              0,
              newEntry,
            );

            self.entries[self.activeStudent.id][sourceColumn].splice(
              sourceIndex,
              1,
            );
          }

          self.entries[self.activeStudent.id][dropColumn].forEach(
            (entry, index) => {
              entry.order = index;
              self.updateEntry(dropColumn, index, false, true);
            },
          );

          gsap.set(this.target, { x: 0, y: 0 });

          resetTargets.forEach((el) => {
            el.style.transform = '';
          });
        }

        scrollContainers.forEach((element) =>
          element.classList.remove('dragging'),
        );

        this.target.classList.remove('dragging');

        requestAnimationFrame(() => createDraggables.call(self, true));
      },
    },
  );
}
