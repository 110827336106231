import { journalEventMixin } from '@/lib/journal-event';
import { ChainDate } from '@/lib/date-chain';
import gql from 'graphql-tag';

export const trackJournalMixin = {
  mixins: [journalEventMixin],

  data() {
    const today = new Date();
    return {
      journalTrackIds: [],
      journalFilters: {
        doShowTimestamps: true,
        fromDate: new ChainDate(today).setDate((date) => date.getDate() - 7)
          .native,
        toDate: today,
        search: '',
      },
      tracks: [],
    };
  },

  computed: {
    journal() {
      if (this.$apollo.queries.trackJournalLookup.loading) return {};

      return this.createLogFragments(this.journalTrackIds, (trackId) =>
        this.trackJournalLookup?.[trackId]?.slice(),
      );
    },

    journalByTrackId() {
      return (trackId) => this.journal[trackId];
    },
  },

  methods: {
    getTotalTrackTime(trackId) {
      const track = this.journal[trackId];
      if (!track) return undefined;

      const totalTrackTime = Object.keys(track).reduce((acc, date) => {
        const day = track[date];

        const totalDayTime = day.reduce((acc, categoryGroup) => {
          return acc + categoryGroup.categoryGroupTime;
        }, 0);

        return acc + totalDayTime;
      }, 0);

      return totalTrackTime;
    },
    getCategoryGroupWithCalculatedTime(categoryGroup) {
      const entries = categoryGroup.entries;

      const sortedEntries = entries.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() > dateA.getTime() ? -1 : 1;
      });

      const latest = sortedEntries[0];
      const earliest = sortedEntries[sortedEntries.length - 1];
      const diffInMinutes =
        Math.abs(
          Math.floor(
            (new Date(latest.createdAt) - new Date(earliest.createdAt)) /
              1000 /
              60,
          ),
        ) ?? 0;

      return diffInMinutes;
    },

    getJournalByTrackIds(...trackIds) {
      this.journalTrackIds = [];
      this.journalTrackIds.push(...trackIds.filter(Boolean));
    },

    createLogFragments(trackIds = [], logsFn = () => []) {
      const skipEvents = [
        this.JournalEvent.TRACK_INTAKE_CREATED,
        this.JournalEvent.CARD_EXERCISE_END,
      ];

      const journal = trackIds.reduce((acc, trackId) => {
        const logs = logsFn(trackId) ?? [];

        // sort from old to new, so we can correctly create journal log sequences (e.g. sessions from start to end)
        logs.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() > dateA.getTime() ? -1 : 1;
        });

        let currentCategory;

        while (logs.length > 0) {
          const log = logs.pop();
          const logCategory = log.event
            .split('_')[0]
            .replace('CARD', 'SESSION');
          const date = log.createdAt.split('T')[0];

          if (skipEvents.includes(log.event)) continue;

          const track = (acc[trackId] = acc[trackId] || {});
          const day = (track[date] = track[date] || []);

          if (
            day.length === 0 ||
            logCategory !== currentCategory ||
            log.event === this.JournalEvent.SESSION_END
          ) {
            day.push({ category: undefined, entries: [] });
          }

          const group = day[day.length - 1];

          try {
            switch (logCategory) {
              case 'SESSION':
                currentCategory = 'SESSION';
                group.category = 'SESSION';
                group.entries.push(log);
                break;

              case 'COGNITION':
                currentCategory = 'COGNITION';
                group.category = 'COGNITION';
                group.entries.push(log);
                break;

              case 'TRACK':
                currentCategory = 'TRACK';
                group.category = 'TRACK';
                group.entries.push(log);
                break;
            }

            group.entries.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB.getTime() > dateA.getTime() ? -1 : 1;
            });
          } catch (error) {
            console.error(
              error,
              JSON.parse(
                JSON.stringify({
                  log,
                  logCategory,
                  currentCategory,
                  date,
                  group,
                  day,
                  track,
                }),
              ),
            );
          }
        }

        return acc;
      }, {});

      const journalWithContainerTime = Object.keys(journal).reduce(
        (acc, trackId) => {
          const track = journal[trackId];

          Object.keys(track).forEach((date) => {
            const day = track[date];

            day.forEach((categoryGroup) => {
              categoryGroup.categoryGroupTime = this.getCategoryGroupWithCalculatedTime(
                categoryGroup,
              );
            });
          });

          acc[trackId] = track;
          return acc;
        },
        {},
      );

      return journalWithContainerTime;
    },
  },

  apollo: {
    trackJournalLookup: {
      fetchPolicy: 'cache-and-network',
      debounce: 250,

      skip() {
        return (
          (this.$user.role === 'MANAGER' && !this.journalStudentId) ||
          !this.journalTrackIds.length
        );
      },

      variables() {
        const today = new Date();
        const from = this.journalFilters.fromDate
          ? new ChainDate(this.journalFilters.fromDate)
              .setHours(0)
              .setMinutes(0)
              .native.toISOString()
          : new ChainDate(today)
              .setDate((date) => date.getDate() - 7)
              .native.toISOString();

        const to = this.journalFilters.toDate
          ? new ChainDate(this.journalFilters.toDate)
              .setHours(23)
              .setMinutes(59)
              .native.toISOString()
          : new ChainDate(today)
              .setHours(23)
              .setMinutes(59)
              .native.toISOString();

        return {
          limit: 100,
          filter: {
            trackId: { in: this.journalTrackIds },
            createdAt: { gt: from, lt: to },
            search: this.journalFilters.search,
          },
        };
      },

      query() {
        return gql`
          query trackJournalMixin_trackJournalLookup(
            $filter: JournalEntriesFilter
          ) {
            journalEntries(filter: $filter) {
              edges {
                node {
                  id
                  createdAt
                  event
                  changes
                  remark
                  createdById
                  createdBy {
                    id
                    fullName
                  }
                  trackId
                  targetResourceId
                  comments(first: 1) {
                    totalCount

                    edges {
                      node {
                        text
                        createdBy {
                          fullName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `;
      },

      update(data) {
        return data.journalEntries.edges?.reduce((acc, { node: entry }) => {
          const comments =
            entry.comments?.edges?.reduce(
              (acc, { node }) => [...acc, node],
              [],
            ) || [];

          entry.comments = comments;

          const container = acc[entry.trackId] || (acc[entry.trackId] = []);
          container.push(entry);
          return acc;
        }, {});
      },
    },
  },
};
