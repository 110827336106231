<template>
  <div>
    <sb-loading v-if="$apollo.queries.trackJournalLookup.loading" />

    <sb-divider v-if="!printMode" title="Trajectjournaal" />

    <p class="from-to">
      Trajectjournaal <b>{{ get(student(), 'fullName') }}</b
      >, van {{ journalFilters.fromDate.toLocaleDateString() }} tot
      {{ journalFilters.toDate.toLocaleDateString() }}
    </p>

    <sb-journal-filters v-model="journalFilters" @print="handlePrint" />

    <sb-journal-accumulator
      :data="journalByTrackId($route.params.path)"
      :show-timestamps="journalFilters.doShowTimestamps"
      @on-delete-item="refresh"
    />

    <sb-spacer height="60" />

    <div v-if="printMode" class="print-overlay">
      <i-button
        type="primary"
        size="large"
        :disabled="loadingPrintContent"
        @click="print"
      >
        {{ loadingPrintContent ? 'Voorbereiden...' : 'Journaal afdrukken' }}
      </i-button>
    </div>
  </div>
</template>

<script>
import { trackJournalMixin } from '@/mixins/trackJournalMixin';
import SbJournalAccumulator from '@/components/SbJournalAccumulator.vue';
import SbLoading from '@/components/SbLoading.vue';
import SbJournalFilters from '@/components/SbJournalFilters.vue';
import { ChainDate } from '@/lib/date-chain';

export default {
  name: 'Journal',
  components: { SbJournalAccumulator, SbLoading, SbJournalFilters },
  mixins: [trackJournalMixin],
  inject: ['student'],

  data() {
    return {
      loadingPrintContent: 'print' in this.$route.query,
      journalFilters:
        'print' in this.$route.query
          ? {
              fromDate: new Date(this.$route.query.fromDate),
              toDate: new Date(this.$route.query.toDate),
              doShowTimestamps:
                this.$route.query.doShowTimestamps === 'true' ? true : false,
              search: this.$route.query.search,
            }
          : {
              toDate: new Date(),
              fromDate: new ChainDate(new Date()).setMonth(
                (date) => date.getMonth() - 3,
              ).native,
              doShowTimestamps: true,
              search: '',
            },
    };
  },

  computed: {
    printMode() {
      return 'print' in this.$route.query;
    },
  },

  mounted() {
    this.getJournalByTrackIds(this.$route.params.path);
    if (this.printMode) {
      this.autoPrint();
    }
  },

  methods: {
    handlePrint() {
      window.open(
        this.$router.resolve({
          name: this.$route.name,
          query: {
            print: true,
            fromDate: this.journalFilters.fromDate.toISOString(),
            toDate: this.journalFilters.toDate.toISOString(),
            doShowTimestamps: String(this.journalFilters.doShowTimestamps),
            search: this.journalFilters.search,
          },
        }).href,
        '_blank',
      );
    },

    autoPrint() {
      const toHide = [
        document.querySelector('.sb_environment-border'),
        document.querySelector('.sb_menu-main'),
        document.querySelector('.sb_app_side-menu'),
        document.querySelector('.sb_page-header'),
        document.querySelector('.sb_menu-tabs'),
      ].filter(Boolean);

      this.$watch('trackJournalLookup', (trackJournalLookup) => {
        this.loadingPrintContent = false;
        this.toggledTrackIds = Object.keys(trackJournalLookup);
        this.$nextTick(() => this.print());
      });

      toHide.forEach((el) => el.classList.add('hide-when-printing'));
    },

    print() {
      window.print();
    },

    refresh() {
      this.$apollo.queries.trackJournalLookup.refresh();
    },
  },
};
</script>

<style lang="scss">
.print-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    display: none;
  }
}

.hide-when-printing {
  display: none !important;
}

.from-to {
  display: none;
  @media print {
    display: block;
  }
}
</style>
