<template>
  <div>
    <sb-loading v-if="$store.state.loading" />

    <sb-title text="Diagnose" size="large" />

    <sb-track-diagnose-form
      id="TrackDiagnoseForm"
      v-model="formData"
      default-mode="edit"
      :display-mode-on-cancel="false"
      :display-mode-on-submit="false"
      @cancel="handleCancel"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading.vue';
import SbTrackDiagnoseForm from '@/components/SbTrackDiagnoseForm.vue';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import { TourStarter } from '@/shepherd/TourStarter';
import gql from 'graphql-tag';
import { mapActions } from 'vuex';

export default {
  name: 'AddDiagnose',

  components: {
    SbLoading,
    SbTrackDiagnoseForm,
  },

  mixins: [changeLoggerMixin(['formData'])],

  data() {
    return {
      showSuccessModal: false,
      formData: undefined,
    };
  },

  mounted() {
    const currentTourKey = TourStarter.getTourKeyForRole(this.$user.role, [
      'COACH_PROBLEM_DIAGNOSE',
    ]);
    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
    handleCancel(mode) {
      if (mode === 'edit-types') this.$router.back();
    },

    handleSubmit() {
      this.addProblems();
    },

    async addProblems() {
      this.$store.state.loading = true;

      try {
        if (this.formData?.problems) {
          const { data, errors } = await this.$apollo.mutate({
            mutation: gql`
              mutation updateTrack($input: UpdateTrackInput!) {
                updateTrack(input: $input) {
                  id
                }
              }
            `,
            variables: {
              input: {
                id: this.$route.params.path,
                data: {
                  problems: {
                    create: this.formData.problems,
                  },
                },
              },
            },
          });

          if (errors) {
            throw new Error(errors.map((e) => e.message).join('\n'));
          }
        }

        this.$router.push({
          name: 'ResourceManagementStudentsStudentPathOverview',
          params: { path: this.$route.params.path },
          query: { newTrack: this.$route.query.newTrack },
        });
      } catch (error) {
        console.error(error);
        this.$Modal.error({
          title: 'Er ging iets mis...',
          content: 'Sorry, het is niet gelukt. Probeer het later nog eens.',
        });
      }

      this.$store.state.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-arrow-disabled {
  pointer-events: none;
  opacity: 0.2;
}
</style>
>
