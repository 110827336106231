<template>
  <div ref="container" class="input-container">
    <i-input
      v-model="minutes"
      class="input-min"
      placeholder="mm"
      @keydown.native="handleKeyDown"
      @focus.native="log('focus')"
    >
    </i-input>

    <i-input
      v-model="seconds"
      class="input-sec"
      maxlength="2"
      placeholder="ss"
      @keydown.native="handleKeyDown"
    >
    </i-input>
    <div class="line">:</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  data() {
    const hasValue = this.value != undefined;
    const minutes = hasValue ? Math.floor(this.value / 60) : undefined;
    const seconds = hasValue ? this.value - minutes * 60 : undefined;
    return { minutes, seconds };
  },

  mounted() {
    this.$watch('minutes', this.emitSeconds);
    this.$watch('seconds', this.emitSeconds);
    Array.from(
      this.$refs.container.getElementsByTagName('input'),
    ).forEach((input) => input.addEventListener('click', () => input.select()));
  },

  methods: {
    emitSeconds() {
      const minutes = parseInt(this.minutes || 0);
      const seconds = parseInt(this.seconds || 0);
      const totalSeconds = minutes * 60 + seconds;
      this.$emit('input', totalSeconds);
    },

    handleKeyDown(event) {
      const isMeta = event.metaKey;
      const isDigit = event.code.startsWith('Digit');
      const isAllowedKey = ['Backspace', 'Delete', 'Tab'].includes(event.code);
      if (isMeta || isDigit || isAllowedKey) return;
      event.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
}

.line {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<style lang="scss">
.input-container {
  position: relative;
  .input-min,
  .input-sec {
    input {
      text-align: center;
    }
  }

  .input-min input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    text-align: right;
    padding-right: 0.3em !important;
  }
  .input-sec input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    text-align: left;
    padding-left: 0.3em !important;
  }
}
</style>
