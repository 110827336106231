<template>
  <div>
    <sb-loading v-if="loading" />

    <template v-if="get(getTrackById, 'active') && $route.query.newTrack">
      <sb-call-out>
        <template slot="left"> ✅ </template>
        <template slot="right">
          <p>
            Je hebt een nieuw traject aangemaakt. Je kan de tabbladen gebruiken
            om informatie over dit traject te bekijken.
          </p>
          <p>Wil je direct een sessie starten?</p>
          <sb-start-session-button :track-id="$route.params.path" />
        </template>
      </sb-call-out>
      <sb-spacer v-if="get(getTrackById, 'active') === false" height="20" />
    </template>

    <sb-call-out v-if="get(getTrackById, 'active') === false">
      <template slot="left"> ℹ️ </template>
      <template slot="right">
        Het huidige traject is niet actief. Je kan alleen cognitie invullen voor
        een actief traject.
        <span class="activate-track" @click="handleActivateTrack">
          Activeer dit traject.
        </span>
      </template>
    </sb-call-out>

    <sb-divider title="Cognitie" />

    <sb-cognition-board
      ref="board"
      :student-ids="[$route.params.studentId]"
      :track-ids="[$route.params.path]"
      :readonly="!get(getTrackById, 'active')"
    />
  </div>
</template>

<script>
import SbCognitionBoard from '@/components/SbCognitionBoard/SbCognitionBoard.vue';
import SbDivider from '@/components/SbDivider.vue';
import SbLoading from '@/components/SbLoading.vue';
import SbCallOut from '@/components/SbCallOut.vue';
import SbStartSessionButton from '@/components/SbStartSessionButton.vue';
import gql from 'graphql-tag';
import { vmConfirm } from '@/lib/vm-confirm';
import { trackManagerMixin } from '@/mixins/trackManagerMixin';

export default {
  components: {
    SbCognitionBoard,
    SbDivider,
    SbLoading,
    SbCallOut,
    SbStartSessionButton,
  },

  mixins: [trackManagerMixin()],

  computed: {
    loading() {
      return this.$apollo.queries.getTrackById.loading;
    },

    studentId() {
      return this.$route.params.studentId;
    },
  },

  created() {
    this.checkIfInactive = true;
  },

  methods: {
    async handleActivateTrack() {
      await this.activateTrack(this.$route.params.path, this.studentId);
      this.$apollo.queries.getTrackById.refresh();
      this.$refs.board.$apollo.queries.getUserById.refresh();
    },

    async handleActivateNewTrack() {
      let doContinue = await vmConfirm.call(
        this,
        'Nieuw traject activeren',
        'Je hebt een nieuw traject aangemaakt. Dit traject is nog niet actief. Om de cognitieve benadering in te vullen, moet het traject geactiveerd worden. Wil je het nieuwe traject activeren?',
      );
      if (!doContinue) return;
      await this.activateTrack(this.$route.params.path, this.studentId);
      this.$apollo.queries.getTrackById.refresh();
      this.$refs.board.$apollo.queries.getUserById.refresh();
    },

    updateTrack(id, data) {
      return this.$apollo.mutate({
        mutation: gql`
          mutation Cognition_updateTrack($input: UpdateTrackInput!) {
            updateTrack(input: $input) {
              id
              active
            }
          }
        `,
        variables: {
          input: { id, data },
        },
      });
    },
  },

  apollo: {
    getTrackById: {
      fetchPolicy: 'network-only',

      skip() {
        return !this.$route.params.path;
      },

      query() {
        return gql`query Cognition_getTrackById {
          getTrackById(id: "${this.$route.params.path}") {
            id
            active
          }
        }`;
      },

      update(data) {
        const newTrackId = this.$route.query.newTrack;

        if (
          newTrackId &&
          this.checkIfInactive &&
          data.getTrackById.active === false
        ) {
          this.checkIfInactive = false;
          this.handleActivateNewTrack();
        }

        return data.getTrackById;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.activate-track {
  color: $brand-primary;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;

  &:hover {
    color: $brand-primary-darkest;
  }
}
</style>
