<template>
  <div class="student-paths">
    <sb-loading
      v-if="
        $apollo.queries.trackManagerData.loading ||
        $apollo.queries.getUserById.loading
      "
      position="absolute"
      width="100%"
      height="100%"
    />

    <modal
      v-if="confirmCreateTrack"
      v-model="showCreateTrackModal"
      title="Nieuw traject"
      @on-ok="confirmCreateTrack"
    >
      <sb-create-track-form v-model="createTrackForm" />
    </modal>

    <!-- <div
      v-if="
        $apollo.queries.trackManagerData.loading ||
        (!$apollo.queries.trackManagerData.loading && !!activeTrack)
      "
      class="active-track"
    >
      <template v-if="activeTrack">
        <sb-divider
          height="30"
          :title="`Actief traject ${
            activeTrack.readingLevel ? '- ' + activeTrack.readingLevel : ''
          }`"
        />
        <button
          style="margin-right: 16px"
          @click.prevent="goToTrack(activeTrack.id)"
        >
          <sb-button button-type="light" icon-animation-direction="right">
            Naar traject
            <sb-icon icon-id="icon_caret-right" />
          </sb-button>
        </button>

        <sb-start-session-button :track-id="get(activeTrack, 'id')" />
      </template>
    </div> -->

    <template v-if="!get(tracks, 'length') && !loading">
      <sb-divider height="30" title="Nog geen traject" />

      <sb-content-indent>
        <p>Je hebt nog geen lopend traject. Wil je er een aanmaken?</p>
        <button @click.prevent="handleCreateTrack">
          <sb-button id="NewTrackButton" icon-animation-direction="right">
            Traject aanmaken
            <sb-icon icon-id="icon_arrow-right" />
          </sb-button>
        </button>
      </sb-content-indent>

      <sb-spacer height="100" />
    </template>

    <sb-divider
      v-if="get(tracks, 'length')"
      :title="`Alle trajecten van ${queryResponse.fullName}`"
    />
    <!--
    <sb-divider
      v-if="get(tracks, 'length')"
      :title="`Alle trajecten van ${getUserById} ${get(
        getUserById,
        'fullName',
      )}`"
    /> -->
    <sb-gql-table
      v-show="get(tracks, 'length')"
      ref="table"
      :config="tracksTableConfig"
      :query-options="getTrackTableQueryOptions(studentId)"
      data-path="tracks"
      @row-action-delete="handleDeleteTrack($event.id)"
      @row-action-activate="handleActivateTrack($event.id, studentId)"
      @row-action-deactivate="handleDeactivateTrack($event.id)"
      @row-action-finish="handleFinishTrack($event.id)"
      @change="handleTableDataChange"
      @loading="loading = $event"
    >
      <template slot="header-area">
        <button @click.prevent="handleCreateTrack">
          <sb-button
            id="NewTrackButton"
            button-type="light"
            icon-animation-direction="right"
          >
            Nieuw traject
            <sb-icon icon-id="icon_plus" />
          </sb-button>
        </button>
      </template>
      <template slot="column-reading-level" slot-scope="{ row, content }">
        <div
          class="sb_table-cell sb_student-path_action-cell clickable"
          @click="goToTrack(row.id)"
        >
          {{ content }}
        </div>
      </template>

      <template slot="column-active" slot-scope="{ row }">
        <span
          class="sb-track-status-table-label"
          :class="{
            s_active: row.active,
            s_inactive: !row.active,
            s_closed: !row.active && row.finishedAt,
          }"
        >
          <span v-if="!row.finishedAt">
            {{ row.active ? 'Actief' : 'Inactief' }}
          </span>
          <span v-else> Voltooid </span>
        </span>
      </template>

      <template slot="column-users" slot-scope="{ row, content }">
        <b>
          {{ content }}
        </b>
      </template>
    </sb-gql-table>

    <sb-spacer height="30" />
  </div>
</template>

<script>
import SbCreateTrackForm from '@/components/SbCreateTrackForm.vue';
import SbDivider from '@/components/SbDivider.vue';
import SbLoading from '@/components/SbLoading.vue';
import SbGqlTable from '@/components/SbTable2/SbGqlTable.vue';
import SbSpacer from '@/components/global/SbSpacer.vue';
import { trackManagerMixin } from '@/mixins/trackManagerMixin';
import gql from 'graphql-tag';

export default {
  components: {
    SbSpacer,
    SbGqlTable,
    SbDivider,
    SbLoading,
    SbCreateTrackForm,
  },

  mixins: [
    trackManagerMixin((self) => ({
      onTrackManagerDataRefresh() {
        self.$refs.table.refresh();
      },
    })),
  ],

  props: {
    studentId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      createTrackForm: {
        startDate: new Date(),
        endDate: undefined,
        isReadingComprehension: undefined,
      },
      confirmCreateTrack: undefined,
      showCreateTrackModal: false,
      showingPathModal: false,
      tracks: undefined,
      loading: true,
      activeTrack: undefined,
      queryResponse: {
        fullName: '',
      },
    };
  },

  watch: {
    '$route.query.currentPath': {
      handler(value) {
        if (value) {
          this.goToCurrentTrack();
        }
      },
    },
    '$route.query.createTrack': {
      handler(value) {
        if (value) {
          this.handleCreateTrack();
        }
      },
    },
    activeTrack: {
      handler(value) {
        if (!value) return;
        this.goToCurrentTrack();
      },
      immediate: true,
    },
    tracks(value) {
      if (value) this.loading = false;
    },
  },

  mounted() {
    if (this.$route.query.createTrack) {
      this.handleCreateTrack();
    }
  },

  methods: {
    goToCurrentTrack() {
      if (this.$route.query.currentPath && this.activeTrack?.id) {
        this.$route.query.currentPath = null;
        this.goToTrack(this.activeTrack.id);
      } else {
        console.log('Not redirecting to current track. Staying on overview.');
      }
    },
    handleTableDataChange(data) {
      const newTrackId = this.$route.query.newTrack;
      this.tracks = data;

      if (newTrackId && newTrackId !== this.activeTrack?.id) {
        this.$Modal.confirm({
          title: 'Nieuw traject activeren',
          content:
            'Je hebt een nieuw traject aangemaakt. Wil je het nieuwe traject instellen als het actieve traject?',
          okText: 'Ja',
          cancelText: 'Nee',
          onOk: () => {
            this.$router
              .replace({
                path: this.$route.path,
                query: {},
              })
              .catch(() => {});
            this.activateTrack(newTrackId, this.studentId, true);
          },
        });
      }
    },

    showPathModal() {
      this.showingPathModal = true;
    },

    hidePathModal() {
      this.showingPathModal = false;
    },

    async handleCreateTrack() {
      const self = this;
      await new Promise((resolve) => {
        self.showCreateTrackModal = true;
        // this.confirmCreateTrack = this.confirmCreateTrack;
        resolve();
      });

      if (!this.createTrackForm.startDate) {
        return setTimeout(
          () =>
            this.$Modal.warning({
              title: 'Foutieve invoer',
              content: 'Voer een startdatum in.',
            }),
          300,
        );
      }

      const data = await this.createTrack(this.studentId, this.createTrackForm);

      if (data) {
        this.$router.push({
          name: 'ResourceManagementStudentsStudentPathAddTestResult',
          params: {
            path: data.createTrack.id,
          },
          query: {
            newTrack: data.createTrack.id,
          },
        });
      }
    },

    async handleActivateTrack(trackId, studentId) {
      await this.activateTrack(trackId, studentId);
      this.$apollo.queries.getUserById.refetch();
    },

    async handleDeactivateTrack(trackId) {
      await this.deactivateTrack(trackId);
      this.$apollo.queries.getUserById.refetch();
    },

    async handleDeleteTrack(trackId) {
      const self = this;
      const refetch = () => self.$apollo.queries.getUserById.refetch();
      await this.deleteTrack(trackId, refetch);
    },

    async handleFinishTrack(trackId) {
      await this.deactivateTrack(trackId || this.activeTrack.id, false);
      await this.finishTrack(trackId || this.activeTrack.id, true);
    },

    goToTrack(id) {
      this.$router.push({
        name: 'ResourceManagementStudentsStudentPathOverview',
        params: { path: id },
      });
    },
  },

  apollo: {
    getUserById: {
      variables() {
        const studentId = this.$route.params.studentId;
        return {
          tracksFilter: {
            active: true,
            users: [{ userId: { equals: studentId }, role: 'TRACK_STUDENT' }],
          },
        };
      },

      query() {
        return gql`query GetUserById($tracksFilter: TracksFilter) {
          getUserById(id: "${this.studentId}") {
            id
            fullName
            tracks(filter: $tracksFilter) {
              edges {
                node {
                  id
                  active
                  readingLevels
                }
              }
            }
          }
        }`;
      },

      update(data) {
        console.log('query run');
        this.activeTrack = data.getUserById.tracks.edges[0]?.node;
        this.queryResponse = data.getUserById;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.active-track {
  position: relative;
  height: 161px;
  overflow: auto;
}

.student-paths {
  position: relative;
}

.sb_student-path_action-cell {
  padding: 0px 20px;
  background: unset;
  height: 100%;
  margin: 0px -20px;
}

.button-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.sb-track-status-table-label {
  padding: 4px 10px 2px;
  border-radius: 15px;
  display: inline-block;

  &.s_active {
    color: $brand-green;
    background: rgba($brand-green, 0.1);
  }

  &.s_inactive {
    color: $brand-primary;
    background: rgba($brand-primary, 0.1);
  }

  &.s_closed {
    color: $brand-red;
    background: rgba($brand-red, 0.1);
  }
}
</style>
