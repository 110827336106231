<template>
  <div class="sb_task-color">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_task-color {
  padding: 2rem;
  border-radius: 8px;

  //
  // UL
  //
  ul {
    margin: 0 0 0 1rem;
    list-style: none;
  }
  > ul {
    margin: 0 0 1rem 1rem;
  }
  li {
    &::before {
      content: '\2022';
      color: $brand-black;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  //
  // TASK COLORS
  //
  &[task='session'] {
    color: $brand-primary;
    li {
      &::before {
        color: $brand-black;
      }
    }
  }
  &[task='diagnose'] {
    color: $brand-task-diagnose;

    li {
      &::before {
        color: $brand-task-diagnose;
        position: absolute;
        width: 100px;
        height: 20px;
      }
    }
  }
  &[task='cognition'] {
    color: $brand-task-cognition;
    li {
      &::before {
        color: $brand-task-cognition;
      }
    }
  }
  &[task='practise'] {
    color: $brand-task-practise;
    li {
      &::before {
        color: $brand-task-practise;
      }
    }
  }
  &[task='apply'] {
    color: $brand-task-apply;
    li {
      &::before {
        color: $brand-task-apply;
      }
    }
  }
  &[task='record'] {
    color: $brand-task-record;
    li {
      &::before {
        color: $brand-task-record;
      }
    }
  }

  //
  //  TASK COLORS
  //
  &[task='session'] {
    color: $brand-dark-gray;
    background: $brand-lightest-gray;
    button.sb_journal_session_header {
      color: $brand-dark-gray;
      background: $brand-lightest-gray;
      &:hover {
        background: darken($brand-lightest-gray, 5%);
      }
    }
  }
  &[task='diagnose'] {
    color: $brand-task-diagnose;
    background: rgba($brand-task-diagnose, 0.06);

    button.sb_journal_session_header {
      color: $brand-task-diagnose;
      &:hover {
        background: rgba($brand-task-diagnose, 0.08);
      }
    }
  }
  &[task='cognition'] {
    color: $brand-task-cognition;
    background: rgba($brand-task-cognition, 0.06);
    button.sb_journal_session_header {
      color: $brand-task-cognition;
      &:hover {
        background: rgba($brand-task-cognition, 0.08);
      }
    }
  }
  &[task='practise'] {
    color: $brand-task-practise;
    background: rgba($brand-task-practise, 0.06);
    button.sb_journal_session_header {
      color: $brand-task-practise;
      &:hover {
        background: rgba($brand-task-practise, 0.08);
      }
    }
  }
  &[task='apply'] {
    color: $brand-task-apply;
    background: rgba($brand-task-apply, 0.06);
    button.sb_journal_session_header {
      color: $brand-task-apply;
      &:hover {
        background: rgba($brand-task-apply, 0.08);
      }
    }
  }
  &[task='record'] {
    color: $brand-task-record;
    background: rgba($brand-task-record, 0.06);
    button.sb_journal_session_header {
      color: $brand-task-record;
      &:hover {
        background: rgba($brand-task-record, 0.08);
      }
    }
  }
}
</style>
