<template>
  <sb-base-form-layout
    :model="formData"
    :promise="promise"
    @submit="handleSubmit"
  >
    <form-item label="Oefenniveau" :label-width="160">
      <sb-reading-level-select v-model="formData.readingLevels" />
    </form-item>

    <form-item
      v-if="get(platformSettings, 'readingComprehension')"
      label="Begrijpend lezen traject"
      :label-width="160"
    >
      <sb-info>
        Bij begrijpend lezen trajecten worden alleen kaarten getoond met opgaven
        voor begrijpend lezen.
      </sb-info>
      <i-switch v-model="formData.isReadingComprehension">
        <span slot="open">Ja</span>
        <span slot="close">Nee</span>
      </i-switch>
    </form-item>
  </sb-base-form-layout>
</template>

<script>
import SbBaseFormLayout from '@/components/SbBaseFormLayout.vue';
import SbReadingLevelSelect from '@/components/SbReadingLevelSelect.vue';
import { formDataMixin } from '@/mixins/formDataMixin';
import { platformSettingsMixin } from '@/mixins/platformSettingsMixin';
import gql from 'graphql-tag';
import SbInfo from './SbInfo.vue';

export default {
  components: {
    SbBaseFormLayout,
    SbReadingLevelSelect,
    SbInfo,
  },

  mixins: [
    formDataMixin({
      readingLevel: undefined,
      isReadingComprehension: undefined,
    }),
    platformSettingsMixin,
  ],

  data() {
    return { promise: undefined };
  },

  created() {
    console.log('current', this.formData.readingLevels);
  },

  methods: {
    async handleSubmit() {
      try {
        // Allow the option null to be send to the backend to have no readingLevel set.
        const newData = {
          ...this.formData,
          readingLevels: this.formData.readingLevels ?? [],
        };

        const { errors } = await (this.promise = this.$apollo.mutate({
          variables: {
            input: {
              id: this.$route.params.path,
              data: newData,
            },
          },
          mutation: gql`
            mutation TrackForm_UpdateTrack($input: UpdateTrackInput!) {
              updateTrack(input: $input) {
                id
              }
            }
          `,
        }));

        if (errors) {
          throw new Error(errors.map((e) => e.message).join('\n'));
        }

        this.$apollo.queries.track.refresh();
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },
  },

  apollo: {
    track: {
      fetchPolicy: 'no-cache',
      update(data) {
        const { readingLevels, isReadingComprehension } = data.getTrackById;
        this.formData = {
          ...this.formData,
          readingLevels: readingLevels ?? [],
          isReadingComprehension: isReadingComprehension ?? false,
        };
      },

      query() {
        return gql`query PathSettings_GetTrackById {
          getTrackById(id: "${this.$route.params.path}") {
            id
            readingLevels
            isReadingComprehension
          }
        }`;
      },
    },
  },
};
</script>
