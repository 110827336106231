export const AppliedType = Object.freeze([
  'Boek lezen',
  'Krant lezen',
  'Strip lezen',
  'Tijdschrift lezen',
  'Jeugdblad lezen',
  'Folders lezen',
  'Webartikel lezen',
]);

export const appliedTypeMixin = {
  created() {
    this.AppliedType = AppliedType;
  },
};
