<template>
  <div>
    <sb-divider title="Overzicht focuspunten" />

    <sb-track-diagnose-form
      v-model="diagnoseFormData"
      :default-data="diagnoseFormData"
      :default-mode="$route.query.editMode ? 'edit' : undefined"
      :promise="promise"
      @submit="handleSubmit"
    />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SbTrackDiagnoseForm from '@/components/SbTrackDiagnoseForm.vue';
import { changeLoggerMixin } from '@/mixins/changeLogger';

export default {
  components: {
    SbTrackDiagnoseForm,
  },

  mixins: [changeLoggerMixin(['diagnoseFormData'])],

  data() {
    return {
      mode: 'display-types',
      diagnoseFormData: undefined,
      promise: undefined,
    };
  },

  computed: {
    problems() {
      return (
        this.getTrackById?.problems
          .filter((e) => !!e.problem)
          .sort((a, b) => a.order - b.order) || []
      );
    },
  },

  watch: {
    problems(value) {
      this.diagnoseFormData = {
        problems: value
          .map((entry) => {
            if (!entry.problem) return;
            return {
              problemId: entry.problem.id,
              id: entry.id,
              order: entry.order,
              mistakeAmount: entry.mistakeAmount,
            };
          })
          .filter(Boolean),
      };
    },
  },

  methods: {
    async handleSubmit(formData) {
      try {
        await (this.promise = this.$apollo.mutate({
          mutation: gql`
            mutation updateTrack($input: UpdateTrackInput!) {
              updateTrack(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              id: this.$route.params.path,
              data: {
                problems: {
                  deleteMany: this.getTrackById?.problems
                    .slice()
                    .map((problem) => problem.id),
                  create: formData.problems.map((entry) => ({
                    problemId: entry.problemId,
                    mistakeAmount: entry.mistakeAmount,
                    order: entry.order,
                  })),
                },
              },
            },
          },
        }));

        this.mode = 'display-types';
        this.$apollo.queries.getTrackById.refetch();
      } catch (error) {
        console.error(error);
        this.$Modal.error({
          title: 'Er ging iets mis',
          content: 'Probeer het later nog eens.',
        });
      }
    },
  },

  apollo: {
    getTrackById: {
      query: gql`
        query PathProblemTypes_getTrackById($trackId: ID!) {
          getTrackById(id: $trackId) {
            id
            problems {
              id
              order
              mistakeAmount
              problem {
                id
                title
                linkedTechniques {
                  id
                  title
                  content
                  number
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          trackId: this.$route.params.path,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: flex-end;
}
</style>
