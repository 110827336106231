<template>
  <div>
    <sb-divider title="Sessie" />
    <sb-content-indent>
      <sb-call-out
        v-if="!$route.query.newTrack && get(getTrackById, 'active') === false"
      >
        <template slot="left"> ℹ️ </template>
        <template slot="right">
          Dit traject is inactief. Je kan daarom nu nog geen sessie starten.

          <span class="activate-track" @click="handleActivateTrack">{{
            'Activeer dit traject'
          }}</span>
          <span>&nbsp;om een sessie te starten.</span>
        </template>
      </sb-call-out>

      <p v-else>Start een sessie om direct samen met de leerling te oefenen.</p>

      <template v-if="get(getTrackById, 'active') !== false">
        <sb-spacer height="10" />
        <sb-start-session-button
          id="SessionStartButton"
          :track-id="$route.params.path"
        />
      </template>
    </sb-content-indent>

    <sb-divider title="Problematiek-focus" />
    <sb-content-indent v-if="problems">
      <sb-call-out v-if="!problems.length">
        <template slot="left"> ℹ️ </template>
        <template slot="right">
          Dit traject heeft nog geen problematiek.

          <router-link
            :to="{
              name: 'ResourceManagementStudentsStudentPathProblemTypes',
              query: { editMode: true },
            }"
            style="text-decoration: underline"
          >
            Voeg een problematiek toe.
          </router-link>
        </template>
      </sb-call-out>

      <sb-task-color v-if="problems && problems.length" task="diagnose">
        <sb-max-width max-width="600">
          <ul>
            <li v-for="problem in problems" :key="problem.id">
              {{ get(problem, 'problem', 'title') }}
              <!-- <pre>{{ problem }}</pre> -->
            </li>
          </ul>
        </sb-max-width>
      </sb-task-color>
    </sb-content-indent>

    <sb-divider title="Overzicht sessies" />
    <sb-content-indent>
      <sb-sessions-table />
    </sb-content-indent>

    <sb-spacer height="400" />
  </div>
</template>

<script>
import SbTaskColor from '@/components/SbTaskColor';
import SbStartSessionButton from '@/components/SbStartSessionButton';
import gql from 'graphql-tag';
import { dmtCardTypeMixin } from '@/lib/dmt-card-type';
import { readingLevelMixin } from '@/lib/reading-level';
import SbCallOut from '@/components/SbCallOut.vue';
import SbSessionsTable from '@/components/SbSessionsTable.vue';
import { trackManagerMixin } from '@/mixins/trackManagerMixin';

export default {
  components: {
    SbTaskColor,
    SbStartSessionButton,
    SbCallOut,
    SbSessionsTable,
  },

  mixins: [dmtCardTypeMixin, readingLevelMixin, trackManagerMixin()],

  data() {
    return {
      showGraph: true,
    };
  },

  computed: {
    problems() {
      return this.getTrackById?.problems;
    },
  },

  created() {
    const newTrackId = this.$route.query.newTrack;
    if (!newTrackId) return;
    this.activateTrack(
      newTrackId,
      this.$route.params.studentId,
      true,
    ).then(() => this.$router.replace({ name: this.$route.name, query: {} }));
  },

  mounted() {
    if (this.$route.query.newTrack) {
      this.$Message.success('Traject aangemaakt');
    }
  },

  methods: {
    async handleActivateTrack() {
      await this.activateTrack(
        this.$route.params.path,
        this.$route.params.studentId,
      );
      this.$apollo.queries.getTrackById.refresh();
    },
  },

  apollo: {
    getTrackById: {
      fetchPolicy: 'cache-and-network',
      query: gql`
        query PathOverview_getTrackById($trackId: ID!) {
          getTrackById(id: $trackId) {
            id
            active
            problems {
              id
              order
              mistakeAmount
              problemId
              problem {
                id
                title
                types
              }
            }
          }
        }
      `,
      variables() {
        return {
          trackId: this.$route.params.path,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.activate-track {
  color: $brand-primary;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;

  &:hover {
    color: $brand-primary-darkest;
  }
}
</style>
