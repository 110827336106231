<template>
  <div class="container" :class="{ readonly }">
    <div class="input-grid">
      <div
        v-for="(header, index) in INPUT_LABELS"
        :key="header"
        class="input-label"
      >
        {{ header }}<span v-if="index === 0" class="required">&nbsp;*</span>
      </div>

      <!-- <div key="icon-col-placeholder" /> -->

      <template v-for="(result, index) in value.slice(0, 1)">
        <form-item
          ref="form-item"
          :key="`${index}-level`"
          :label-width="0"
          :prop="validate ? ['results', index, 'level'].join('.') : undefined"
        >
          <i-select
            :key="`${index}-level`"
            v-model="result.level"
            width="300px"
          >
            <i-option v-for="level in AviCardLevel" :key="level" :value="level">
              {{ getAviCardLevelLabel(level) }}
            </i-option>
          </i-select>
        </form-item>

        <form-item
          :key="`${index}-time-spent`"
          :label-width="0"
          :prop="
            validate ? ['results', index, 'timeSpent'].join('.') : undefined
          "
        >
          <sb-time-input v-model="result.timeSpent" placeholder="mm:ss" />
        </form-item>

        <form-item
          :key="`${index}-time-norm`"
          :label-width="0"
          :prop="
            validate ? ['results', index, 'timeNorm'].join('.') : undefined
          "
        >
          <sb-time-input v-model="result.timeNorm" placeholder="mm:ss" />
        </form-item>

        <form-item
          :key="`${index}-mistakes-made`"
          :label-width="0"
          :prop="
            validate ? ['results', index, 'mistakesMade'].join('.') : undefined
          "
        >
          <input-number
            :value="result.mistakesMade"
            type="number"
            @input="result.mistakesMade = Number($event)"
          />
        </form-item>

        <form-item
          :key="`${index}-mistakes-norm`"
          :label-width="0"
          :prop="
            validate ? ['results', index, 'mistakesNorm'].join('.') : undefined
          "
        >
          <input-number
            :value="result.mistakesNorm"
            type="number"
            @input="result.mistakesNorm = Number($event)"
          />
        </form-item>

        <form-item
          :key="`${index}-number`"
          :label-width="0"
          :prop="validate ? ['results', index, 'number'].join('.') : undefined"
        >
          <i-input
            :value="result.number"
            placeholder="Bijv. 1.2"
            @input="result.number = $event"
          />
        </form-item>

        <button
          v-if="!readonly"
          :key="`${index}-remove-row`"
          type="button"
          @click="removeAviTestByIndex(index)"
        >
          <sb-button naked collapsed-left>
            <sb-icon icon-id="icon_cross" />
          </sb-button>
        </button>
      </template>

      <button v-if="!readonly" type="button" @click="addAviTest">
        <sb-button naked collapsed-left>
          Nog een kaart toevoegen
          <sb-icon icon-id="icon_plus" />
        </sb-button>
      </button>
    </div>
  </div>
</template>

<script>
import { aviCardLevelMixin } from '@/lib/avi-card-level';
import SbTimeInput from './SbStudentIntakeForm/SbTimeInput.vue';

const INPUT_LABELS = [
  'Niveau',
  'Tijd',
  'Norm tijd',
  'Aantal fout',
  'Norm fout',
  'Variant',
];

const TEST_RESULT_BASE = {
  level: undefined,
  mistakesMade: 0,
  mistakesNorm: 0,
  number: undefined,
  timeNorm: 0,
  timeSpent: 0,
};

export const CREATE_DEFAULT_AVI_TEST_RESULT = () => {
  return new Array(1).fill(undefined).map(() => ({ ...TEST_RESULT_BASE }));
};

export default {
  components: { SbTimeInput },
  mixins: [aviCardLevelMixin],

  props: {
    wrapInFormItems: { type: Boolean, default: true },
    value: {
      type: Array,
      default: function () {
        this.noInitialValue = true;
        return CREATE_DEFAULT_AVI_TEST_RESULT();
      },
    },
    readonly: { type: Boolean, default: true },
    validate: { type: Boolean, default: false },
  },

  data() {
    return { INPUT_LABELS };
  },

  watch: {
    value: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    },
  },

  mounted() {
    if (this.noInitialValue) {
      this.$emit('input', this.value);
    }
  },

  methods: {
    addAviTest() {
      this.value.push(Object.assign({}, TEST_RESULT_BASE));
    },

    removeAviTestByIndex(index) {
      this.value.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: $brand-primary-lightest;
  padding: 20px 30px;

  &.readonly {
    background: none;
  }
}

.input-label {
  color: #6b6b6b;
}

.required {
  color: $brand-red;
}

.input-grid {
  display: grid;
  grid-template-columns: 130px repeat(2, 110px) repeat(2, 90px) 100px;
  grid-auto-rows: auto;
  grid-gap: 10px 30px;

  .ivu-form-item {
    padding: 0 !important;
  }
}
</style>
