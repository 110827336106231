<template>
  <modal
    :value="value"
    cancel-text="Sluiten"
    footer-hide
    :width="getWidth()"
    @input="$emit('input', $event)"
  >
    <div class="container">
      <div ref="commentsContainer" class="comments-container">
        <sb-journal-comment
          v-if="optimisticComment"
          :comment="optimisticComment"
          is-new
          is-own
        />

        <sb-journal-comment
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
          :is-new="comment.id === newCommentId"
          :is-own="get(comment, 'createdBy', 'id') === $user.id"
        />

        <div
          v-if="$apollo.queries.journalEntryComments.loading"
          class="center spinner-container"
        >
          <sb-loading-spinner />
        </div>

        <div v-if="moreCommentsAvailable" class="center">
          <i-button @click="loadMore">Meer opmerkingen</i-button>
        </div>
      </div>
      <div>
        <i-input
          v-model="commentInput"
          :disabled="creatingComment"
          :rows="4"
          type="textarea"
          placeholder="Plaats een opmerking"
        />
        <div class="submit-button-container">
          <i-button type="primary" @click="submitComment">
            Plaats opmerking
          </i-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import gql from 'graphql-tag';
import SbLoadingSpinner from '@/components/SbLoadingSpinner';
import SbJournalComment from '@/components/SbJournalComment';

export default {
  name: 'SbJournalCommentsModal',

  components: { SbLoadingSpinner, SbJournalComment },

  props: {
    journalEntryId: { type: String, default: undefined },
    value: { type: Boolean, default: false },
  },

  data: () => ({
    commentInput: '',
    comments: [],
    creatingComment: false,
    optimisticComment: undefined,
    newCommentId: '',
    pagination: {
      first: 10,
      after: undefined,
    },
  }),

  computed: {
    moreCommentsAvailable() {
      if (!this.journalEntryComments) return false;
      const { totalCount } = this.journalEntryComments;
      const loading = this.$apollo.queries.journalEntryComments.loading;
      if (loading) return false;
      return this.comments.length < totalCount;
    },
  },

  watch: {
    journalEntryId() {
      this.comments = [];
    },
  },

  methods: {
    loadMore() {
      this.pagination.after = this.comments[this.comments.length - 1].id;
    },

    async submitComment() {
      if (!this.commentInput) return;
      const input = {
        journalEntryId: this.journalEntryId,
        text: this.commentInput,
      };

      const optimisticResult = {
        ...input,
        id: 'OPTIMISTIC',
        createdAt: new Date().toISOString(),
        createdBy: {
          ...this.me,
        },
      };

      this.creatingComment = true;
      this.optimisticComment = optimisticResult;

      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation JournalCommentsModal_CreateJournalEntryComment(
              $input: CreateJournalEntryCommentInput!
            ) {
              createJournalEntryComment(input: $input) {
                id
                text
                createdAt
                createdBy {
                  id
                  fullName
                }
              }
            }
          `,
          variables: { input },
        });

        if (result.errors) {
          this.optimisticComment = undefined;
          return this.$showGenericError(
            undefined,
            result.errors.map((error) => error.message).join(', '),
          );
        }

        this.newCommentId = this.optimisticComment.id =
          result.data.createJournalEntryComment.id;
        this.commentInput = '';
        this.$refs.commentsContainer?.scrollTo({ top: 0, behaviour: 'smooth' });

        this.$apollo.mutate({
          mutation: gql`mutation {
            incrementTotalJournalEntryCommentsCount(journalEntryId: "${this.journalEntryId}") @client
          }`,
        });

        this.$emit('comment-created', result.data.createJournalEntryComment);
      } catch (error) {
        console.error(error);
        this.optimisticComment = undefined;
        this.$showGenericError();
      } finally {
        this.creatingComment = false;
        this.$apollo.queries.journalEntryComments?.refetch();
      }
    },

    getWidth() {
      return window.innerWidth * 0.75;
    },
  },

  apollo: {
    me: {
      query() {
        return gql`
          query JournalCommentsModal_Me {
            me {
              id
              fullName
            }
          }
        `;
      },

      update(data) {
        return data.me;
      },
    },

    journalEntryComments: {
      fetchPolicy: 'no-cache',

      skip() {
        return !this.value || !this.journalEntryId;
      },

      variables() {
        return {
          id: this.journalEntryId,
          first: this.pagination.first,
          after: this.pagination.after,
          orderBy: { createdAt: 'DESC' },
        };
      },

      update({ getJournalEntryById }) {
        if (this.optimisticComment) {
          this.comments.unshift(this.optimisticComment);
          this.optimisticComment = undefined;
        }

        this.comments = this.comments
          .concat(
            getJournalEntryById.comments.edges
              .map(({ node }) => node)
              .filter((node) => !this.comments.find((c) => c.id === node.id)),
          )
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
        return getJournalEntryById.comments;
      },

      query() {
        return gql`
          query JournalCommentsModal_GetJournalEntryById(
            $id: ID!
            $orderBy: JournalEntryCommentsOrderBy
            $first: Int
            $after: String
          ) {
            getJournalEntryById(id: $id) {
              id
              comments(orderBy: $orderBy, first: $first, after: $after) {
                totalCount

                edges {
                  node {
                    text
                    createdBy {
                      fullName
                    }
                  }
                }

                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
                edges {
                  cursor
                  node {
                    id
                    text
                    createdAt
                    createdBy {
                      id
                      fullName
                    }
                  }
                }
              }
            }
          }
        `;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 60vh;
  min-height: 500px;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}

.comments-container {
  overflow: auto;
  margin: 0 -40px;
  padding: 0 40px;
}

.submit-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.center {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.spinner-container {
  color: #009efe;
}
</style>
