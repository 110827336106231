<template>
  <div>
    <!-- <Breadcrumb>
      <BreadcrumbItem>Leerlingen</BreadcrumbItem>
    </Breadcrumb> -->

    <sb-page-header>
      <template v-slot:left>
        <h1>Leerlingen</h1>
      </template>
      <template v-slot:right>
        <dropdown id="StudentsTableActions" placement="bottom-end">
          <i-button type="primary" ghost>
            Acties
            <icon type="md-more" />
          </i-button>

          <dropdown-menu slot="list">
            <template>
              <router-link
                v-if="['MANAGER', 'COACH'].includes($user.role)"
                :to="{ name: 'ResourceManagementStudentAdd' }"
              >
                <sb-dropdown-button> Leerling toevoegen </sb-dropdown-button>
              </router-link>
              <router-link
                v-if="
                  ['MANAGER'].includes($user.role) ||
                  ($user.role === 'ADMIN' &&
                    $user.context.resourceType === 'school')
                "
                :to="{ name: 'ResourceManagementStudentsBulkImport' }"
              >
                <sb-dropdown-button>
                  Leerlingen importeren uit CSV of XLSX bestand
                </sb-dropdown-button>
              </router-link>
              <sb-dropdown-button
                v-if="['MANAGER', 'ADMIN'].includes($user.role)"
                @click.native="handleExport"
              >
                Exporteren
              </sb-dropdown-button>
            </template>
          </dropdown-menu>
        </dropdown>
      </template>
    </sb-page-header>

    <sb-students-table
      :organisation-id="
        $user.context.isAdmin ? null : organisationId || resourceId
      "
    />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbStudentsTable from '@/components/SbStudentsTable/SbStudentsTable';
import gql from 'graphql-tag';

export default {
  name: 'OnlineStudents',

  components: {
    SbPageHeader,
    SbStudentsTable,
  },
  props: {
    organisationId: {
      type: String,
      default: null,
    },
    courseGroupId: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
  },

  methods: {
    async handleExport() {
      try {
        const { data, error } = await this.$apollo.query({
          fetchPolicy: 'network-only',
          variables:
            this.$context === 'SCHOOL'
              ? {
                  id: this.$route.params.resourceId,
                }
              : undefined,
          query: gql`
            query ExportStudents {
              exportStudents
            }
          `,
        });

        if (error) throw error;

        const file = await fetch(
          `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.exportStudents}`,
        );
        const blob = await file.blob();
        const url = URL.createObjectURL(blob);

        console.log({ file, blob, url });

        const a = document.createElement('a');

        a.onclick = function () {
          this.remove();
        };

        a.href = url;
        a.style.display = 'none';
        a.download = 'leeshelden-leerlingen-export.xlsx';
        document.body.append(a);
        a.click();
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style lang="scss"></style>
