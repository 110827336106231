<template>
  <div>
    <table>
      <thead>
        <th>Datum</th>
        <th>Tijd</th>
        <th></th>
      </thead>

      <tbody>
        <tr v-for="entry in journalEntries">
          <td>{{ entry.date }}</td>
          <td>{{ entry.time }}</td>
          <td>
            <b>{{ getJournalEventLabel(JournalEvent[entry.event]) }}</b>
            <ul v-for="item in getItemContent(entry)">
              <li>
                {{ item.text }}
              </li>
              <ul v-if="commentText(item)">
                <li>
                  {{ commentText(item) }}
                </li>
              </ul>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { JournalEvent, getJournalEventLabel } from '@/lib/journal-event';
import { journalEventMixin } from '@/lib/journal-event';
import { zelfredzaamheidMixin } from '@/lib/zelfredzaamheid';
import { aviCardLevelMixin } from '@/lib/avi-card-level';
import { dmtCardTypeMixin } from '@/lib/dmt-card-type';
import { cognitiveMapColumnMixin } from '@/lib/cognitive-map-column';
import { getContent } from '@/lib/journal-content';

function compareTime(entry1, entry2, reverse = false) {
  const time1 = entry1.time;
  const time2 = entry2.time;

  // Extract hours and minutes from time strings
  const [hours1, minutes1] = time1.split(':');
  const [hours2, minutes2] = time2.split(':');

  // Compare hours first
  if (hours1 !== hours2) {
    return (Number(hours1) - Number(hours2)) * (reverse ? -1 : 1);
  }

  // If hours are the same, compare minutes
  return (Number(minutes1) - Number(minutes2)) * (reverse ? -1 : 1);
}

export default {
  name: 'JournalTable',

  mixins: [
    journalEventMixin,
    zelfredzaamheidMixin,
    aviCardLevelMixin,
    dmtCardTypeMixin,
    cognitiveMapColumnMixin,
  ],

  props: {
    items: { type: Object, required: false, default: () => ({}) },
  },

  data() {
    return {
      JournalEvent,
    };
  },

  computed: {
    journalEntries() {
      if (!this.items) return [];

      return Object.entries(this.items).reduce((acc, cur) => {
        const [date, entryGroup] = cur;

        const entries = entryGroup.reduce((acc, cur) => {
          return [...acc, ...cur.entries];
        }, []);

        const groupDate = new Date(date).toLocaleDateString();
        const mappedEntries = entries.map((_entry) => {
          const { entries, ...rest } = _entry;
          const entry = { ...rest };
          return {
            ...entry,
            date: groupDate,
            time: entry.createdAt,
          };
        });

        const sortedEntries = mappedEntries.sort((a, b) => {
          const timeA = new Date(a.time).getTime();
          const timeB = new Date(b.time).getTime();
          return timeB - timeA;
        });

        const convertedMappedEntries = sortedEntries.map((entry) => {
          return {
            ...entry,
            time: this.getTime(entry.time),
          };
        });

        return [...acc, ...convertedMappedEntries];
      }, []);
    },
  },

  methods: {
    commentText(item) {
      const val = `${item?.items?.[0]?.text}` ?? '';
      if (val === 'undefined') return '';
      return val;
    },
    getTime(date) {
      return new Date(date)
        .toLocaleTimeString()
        .split(':')
        .slice(0, 2)
        .join(':');
    },

    blockTime(createdAt) {
      return createdAt ? this.getTime(createdAt) : '';
    },

    getItemContent(item) {
      const { event, changes } = item;
      return getContent(event, changes);
    },

    getJournalEventLabel,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
$border: 1px solid $brand-light-gray;

table {
  border-collapse: separate;
  border-spacing: 0; /* No spacing between cells */
}

th,
td {
  padding: 10px;
  border-left: $border; /* Set left border for headers and cells */
  border-top: $border; /* Set top border for headers and cells */
}

th:last-child,
td:last-child {
  border-right: $border; /* Set right border for last header and cell in each row */
}

tr:last-child td {
  border-bottom: $border; /* Set bottom border for last row cells */
}

th,
tr {
  min-width: 120px;
  text-align: left;
  vertical-align: top;
  margin-bottom: 15px;
}

td {
  text-align: start;
  padding: 15px;
}

ul {
  margin-left: 15px;
}
</style>
