<template>
  <div>
    <sb-divider title="Trajectinstellingen" />
    <sb-track-form />
  </div>
</template>

<script>
import SbTrackForm from '@/components/SbTrackForm.vue';
export default {
  components: { SbTrackForm },
};
</script>
