<template>
  <div class="sb_journal">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_journal {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 2rem;

  @media print {
    display: table;
    width: 100%;
  }

  //
  //  FAUX LINE
  //
  &::before {
    content: '';
    position: absolute;
    width: 2px;
    background: linear-gradient(
      180deg,
      $brand-white 0%,
      $brand-light-gray 40px,
      $brand-light-gray calc(100% - 40px),
      $brand-white 100%
    );
    height: 100%;
    left: 0;
  }
}
</style>
