<template>
  <button
    v-if="doShow"
    type="button"
    :disabled="disabled"
    @click="startSession"
  >
    <sb-button
      id="btn_sessie_starten"
      :button-type="buttonType"
      icon-animation-direction="right"
      :disabled="disabled"
    >
      Sessie starten
      <sb-icon icon-id="icon_plus" />
    </sb-button>
  </button>
</template>

<script>
import gql from 'graphql-tag';
export default {
  name: 'SbStartSessionButton',

  props: {
    trackId: { type: String, required: false, default: undefined },
    buttonType: { type: String, default: 'primary' },
  },

  computed: {
    doShow() {
      const doShow = !!this.$router && !!this.$route?.params?.studentId;

      if (!doShow) {
        console.warn(
          'Button disabled because could not determine `studentId` from router context.',
        );
      }

      return doShow;
    },

    disabled() {
      return !this.getTrackById?.active;
    },
  },

  methods: {
    startSession() {
      this.$router
        ?.push({
          name: 'Session',
          query: {
            studentIds: this.$route?.params?.studentId,
            trackIds: this.trackId,
          },
        })
        .catch((error) => {
          console.dir(error);
        });
    },
  },

  apollo: {
    getTrackById: {
      fetchPolicy: 'cache-and-network',
      query: gql`
        query StartSessionButton_getTrackById($trackId: ID!) {
          getTrackById(id: $trackId) {
            id
            active
          }
        }
      `,

      skip() {
        return !this.trackId;
      },

      variables() {
        return {
          trackId: this.trackId,
        };
      },
    },
  },
};
</script>

<style scoped lang="scss">
.start-session-button {
  svg {
    height: 100%;
  }
}

.button-content {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  gap: 1rem;
}
</style>
