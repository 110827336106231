export function groupProblemsByType(problems = []) {
  const result = problems.reduce(
    (map, problem) => {
      const {
        types: [type],
      } = problem;
      map[type] = map[type] || [];
      map[type].push(problem);
      return map;
    },
    {
      NA_AFNAME: [],
      KWALITEITSPROBLEMEN: [],
      DECODEERPROBLEMEN: [],
      ALGEMEEN: [],
    },
  );

  if (result) {
    Object.entries(result).forEach(([key, value]) => {
      if (!value.length) delete result[key];
    });
  }

  return result;
}
