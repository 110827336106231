<template>
  <sb-journal v-if="data">
    <template v-for="(entries, date) in data">
      <template v-for="(group, groupIndex) in entries">
        <sb-journal-block
          v-if="group.entries.length && group.category === 'SESSION'"
          :key="[date, groupIndex, get(group.entries, 0, 'id')].join('.')"
          :group="group"
          :timestamp="new Date(date).toLocaleDateString()"
          :show-timestamps="showTimestamps"
          type="session"
          @on-delete-item="emitOnDeleteItem"
          @on-comment-created="emitOnCommentCreated"
        />

        <sb-journal-block
          v-if="group.entries.length && group.category === 'TRACK'"
          :key="[date, groupIndex, get(group.entries, 0, 'id')].join('.')"
          :group="group"
          :timestamp="new Date(date).toLocaleDateString()"
          :show-timestamps="showTimestamps"
          type="diagnosis"
          @on-delete-item="emitOnDeleteItem"
          @on-comment-created="emitOnCommentCreated"
        />

        <sb-journal-block
          v-if="group.entries.length && group.category === 'COGNITION'"
          :key="[date, groupIndex, get(group.entries, 0, 'id')].join('.')"
          :group="group"
          :timestamp="new Date(date).toLocaleDateString()"
          :show-timestamps="showTimestamps"
          type="cognition"
          @on-delete-item="emitOnDeleteItem"
          @on-comment-created="emitOnCommentCreated"
        />
      </template>
    </template>
  </sb-journal>
</template>

<script>
import SbJournal from './SbJournal.vue';
import SbJournalBlock from './SbJournalBlock.vue';
export default {
  name: 'SbJournalAccumulator',
  components: { SbJournal, SbJournalBlock },
  props: {
    data: { type: Object, default: undefined },
    showTimestamps: { type: Boolean, default: true },
  },
  methods: {
    emitOnDeleteItem(item) {
      this.$emit('on-delete-item', item);
    },
    emitOnCommentCreated(comment) {
      this.$emit('on-comment-created', comment);
    },
  },
};
</script>
