<template>
  <div>
    <sb-divider title="Sessie" />
    <sb-session-form :default-data="getSessionById" />
  </div>
</template>

<script>
import SbSessionForm from '@/components/SbSessionForm.vue';
import gql from 'graphql-tag';
export default {
  name: 'SessionData',
  components: { SbSessionForm },

  data() {
    return {};
  },

  apollo: {
    getSessionById: {
      fetchPolicy: 'cache-and-network',

      query() {
        return gql`query SessionData {
          getSessionById(id: "${this.$route.params.sessionId}") {
            id
            createdAt
            appliedType
            howDidItGo
            whatWentWell
            whatWentWrong
            whatNeedsAttention
            cards {
              id
              remark
              card {
                id
                title
              }
            }
          }
        }`;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
