<template>
  <div class="container">
    <sb-spacer height="40" />

    <div>
      <sb-call-out style="width: 840px; margin-bottom: 20px">
        <template #left>💡</template>
        <template #right>
          Selecteer hieronder de kaarten die je wil toewijzen aan de leerling.
          Na selectie worden deze direct toegewezen. De toegewezen kaarten zijn
          dan zichtbaar in de eigen omgeving van de leerling.
        </template>
      </sb-call-out>
    </div>

    <sb-cards-list
      v-if="toggle"
      ref="cardsList"
      v-model="selectedCards"
      :locked-ids="selectedGroupCards"
      multiple
      :show-exercise-status-enabled="true"
    />
  </div>
</template>

<script>
import SbCardsList from '@/components/SbCardsList';
import SbCallOut from '@/components/SbCallOut';
import GetStudentAssignedCardsQuery from '@/graphql/queries/GetStudentAssignedCards.gql';

import gql from 'graphql-tag';

export default {
  components: { SbCardsList, SbCallOut },

  data() {
    return {
      selectedCards: [],
      selectedGroupCards: [],
      toggle: true,
    };
  },

  watch: {
    async selectedCards(value) {
      try {
        const { errors } = await this.$apollo.mutate({
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: this.$route.params.studentId,
              data: { availableCardsIds: { set: value } },
            },
          },
          mutation: gql`
            mutation StudentAssignedCards_AssignCards(
              $input: UpdateUserInput!
            ) {
              updateUser(input: $input) {
                id
              }
            }
          `,
        });

        if (errors) {
          throw new Error(errors.map((e) => e.message).join('\n'));
        }

        if (!this.notifyOfChanges) {
          this.notifyOfChanges = true;
          return;
        }

        this.$Message.success('Selectie opgeslagen');
      } catch (error) {
        throw new Error(error);
      }
    },
  },

  mounted() {
    this.getInitialCards();
  },

  methods: {
    async getInitialCoachCards() {},

    async getInitialCards() {
      try {
        const { data, errors } = await this.$apollo.query({
          fetchPolicy: 'network-only',
          query: GetStudentAssignedCardsQuery,
          variables: {
            id: this.$route.params.studentId,
          },
        });

        console.log(data);

        if (errors) {
          console.log(errors);
          throw new Error();
        }

        this.selectedCards = data.getUserById.availableCardsIds;
        this.selectedGroupCards = data.getUserById?.groupAvailableCardsIds;
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      } finally {
        this.$watch('toggle', (value) => {
          value ? undefined : (this.selectedCards = []);
        });
      }
    },

    selectAllCards() {
      this.selectedCards = (this.$refs.cardsList?.cards || []).map(
        (card) => card.id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 1rem;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toggle {
    align-items: center;
    display: flex;
  }
}
</style>
