<template>
  <sb-base-form-layout
    v-bind="$attrs"
    :display-mode-on-submit="
      $attrs['display-mode-on-submit'] || mode.endsWith('order')
    "
    :display-mode-on-cancel="editMode ? false : mode.endsWith('types')"
    :save-button-text="mode === 'edit-types' ? 'Volgende' : 'Opslaan'"
    :cancel-button-text="$route.query.newTrack ? 'Vorige' : undefined"
    :with-bottom-bar-indent="false"
    @submit="handleSubmit"
    @cancel="handleCancel"
    @edit="mode = 'edit-types'"
    @promise-resolve="mode = 'display-types'"
  >
    <template v-slot:default="slotScope">
      <sb-problems-input
        v-if="mode.endsWith('types')"
        v-model="formData.problems"
        :hide-disabled="mode.startsWith('display')"
        :readonly="slotScope.mode === 'readonly'"
      />

      <sb-problems-sorter
        v-if="mode.endsWith('order')"
        v-model="formData.problems"
      />
    </template>
    <template #buttons-right>
      <i-button
        v-if="$route.query.newTrack"
        id="BaseSkipButton"
        style="margin-right: 1rem"
        size="large"
        @click="$emit('submit', formData)"
      >
        Overslaan
      </i-button>
    </template>
  </sb-base-form-layout>
</template>

<script>
import SbProblemsInput from '@/components/SbProblemsInput.vue';
import { formDataMixin } from '@/mixins/formDataMixin';
import SbProblemsSorter from '@/views/Default/SbProblemsSorter.vue';
import SbBaseFormLayout from './SbBaseFormLayout.vue';

export default {
  name: 'SbTrackDiagnoseForm',

  components: {
    SbProblemsInput,
    SbProblemsSorter,
    SbBaseFormLayout,
  },

  mixins: [formDataMixin({ problems: undefined })],

  data() {
    return {
      mode:
        this.$attrs['default-mode'] === 'edit' ? 'edit-types' : 'display-types',
    };
  },

  computed: {
    editMode() {
      return this.$attrs['default-mode'] === 'edit';
    },
  },

  methods: {
    handleSubmit() {
      const self = this;
      if (this.mode === 'edit-types') {
        if (!this.formData.problems || this.formData.problems.length < 1) {
          return this.$Modal.confirm({
            title: 'Geen problemen gekozen',
            content:
              'Weet je zeker dat je verder wilt gaan zonder problemen te kiezen?',
            onCancel: () => {
              setTimeout(() => {
                self.$emit('submit', this.formData);
              }, 300);
            },
            onOk() {},
            okText: 'Nee',
            cancelText: 'Ja',
          });
        }

        return (this.mode = 'edit-order');
      }

      this.$emit('submit', this.formData);
    },

    handleCancel() {
      this.resetFormData();
      this.$emit('cancel', this.mode);

      if (this.mode === 'edit-order' || this.editMode) {
        this.mode = 'edit-types';
      } else {
        this.mode = 'display-types';
      }
    },
  },
};
</script>
